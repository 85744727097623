import { useMemo } from "react";

import certificatesSelectorService from "../selector/certificates";
import controlSelectorService from "../selector/control";
import eventSelectorService from "../selector/events";
import { useAppSelector } from "../store";

/**
 * Hook to get loading states for different stores.

 * @returns {Object} loadingStates - An object containing the loading states for each store.
 * @returns {boolean} isPending - A function to determine if a store is in the pending state.
 * @returns {boolean} isRejected - A function to determine if a store is in the rejected state.
 * @returns {boolean} isFulfilled - A function to determine if a store is in the fulfilled state.
 * @returns {boolean} isNotInitialLoaded - A function to determine if a store has not been loaded yet.
 * @returns {Function} getFlowLoading - A function to get the loading state for a specific flow.
 */
const useLoadingThunk = () => {
    const token = useAppSelector(controlSelectorService.selectTokenValidation);
    const certificates = useAppSelector(certificatesSelectorService.selectCertificates);
    const events = useAppSelector(eventSelectorService.selectEventLoadingState);

    const flowLoading = useAppSelector(controlSelectorService.selectFlowLoading);

    const loadingStates = useMemo(
        () => ({
            token: token.loadingState,
            certificates: certificates.loadingState,
            events,
        }),
        [certificates.loadingState, events, token.loadingState]
    );

    const isPending = (store: keyof typeof loadingStates) => loadingStates[store] === "pending";
    const isFulfilled = (store: keyof typeof loadingStates) => loadingStates[store] === "fulfilled";
    const isRejected = (store: keyof typeof loadingStates) =>
        loadingStates[store] === "unfetched" || loadingStates[store] === "rejected";

    const isNotInitialLoaded = (store: keyof typeof loadingStates) => loadingStates[store] === "unfetched";

    const getFlowLoading = (type: keyof typeof flowLoading) => flowLoading[type];

    return { loadingStates, isPending, isRejected, isFulfilled, getFlowLoading, isNotInitialLoaded };
};

export default useLoadingThunk;
