import { theme } from "./Theme/theme";

export const flexCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const container = {
    padding: "0 10%",
};

export const flex = {
    display: "flex",
};

export const socialMediaButtons = (showLabel?: boolean) =>
    showLabel
        ? {
              [theme.breakpoints.down("sm")]: {
                  width: "100%",
              },
          }
        : { minWidth: "fit-content", maxWidth: "fit-content" };
