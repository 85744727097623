import { socialMediaColors, socialMediaHoverColors, theme } from "utils/Theme/theme";

export const modalDescription = {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    backgroundColor: "#EEF5FF",
};

export const certificateImage = {
    maxWidth: "40%",
    margin: "0px auto",
    borderRadius: 3,
    boxShadow: "rgba(0, 0, 0, 0.25) 2px 2px 16px 4px",
    minWidth: "220px",
};

export const shareButton = {
    padding: "5px 16px",
    backgroundColor: socialMediaColors.linkedin,
    ":hover": {
        backgroundColor: socialMediaHoverColors.linkedin,
    },
};
