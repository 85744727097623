import React, { useMemo } from "react";

import { useTheme } from "@mui/material";
import MuiTypography from "@mui/material/Typography";

const Typography: React.FC<ITypographyProps> = ({
    children,
    weight,
    variant,
    className,
    gutterBottom = true,
    ...rest
}) => {
    const theme = useTheme();

    const weights = useMemo(
        () => ({
            regular: theme.typography.fontWeightRegular,
            meduim: theme.typography.fontWeightMedium,
            bold: theme.typography.fontWeightBold,
        }),
        [theme]
    );
    const weightStyle = weight ? weights[weight] : undefined;

    return (
        <MuiTypography
            sx={{
                fontWeight: weightStyle,
                ...(rest.style || {}),
            }}
            className={className}
            variant={variant}
            gutterBottom={gutterBottom}
            {...rest}
        >
            {children}
        </MuiTypography>
    );
};

export default Typography;
