import React from "react";

import HtmlList from "components/shared/HtmlList";
import Typography from "components/shared/Typography";

import * as TermsData from "./TermsConent.helpers";

const TermsContent: React.FC = () => {
    return (
        <div>
            <Typography variant="h1">Terms of Use</Typography>
            <Typography gutterBottom> The Virtualbadge.io is provided by FutureNext GmbH.</Typography>
            <Typography>
                FutureNext GmbH
                <br />
                Mafinex Technologiezentrum
                <br />
                Julius-Hatry-Str. 1<br />
                68163 Mannheim
                <br />
                Deutschland (Germany)
            </Typography>
            <Typography>
                <a href="https://www.virtualbadge.io/">www.virtualbadge.io</a>
                <br />
                <a href="https://www.futurenext.de/">www.futurenext.de</a>
            </Typography>

            <Typography variant="h2">HOW WE USE DATA</Typography>
            <HtmlList type="ul" list={TermsData.dataUsage} />
            <Typography variant="h2"> AGREEMENT TO TERMS</Typography>
            <Typography>
                These Terms of Use constitute a legally binding agreement made between you, whether personally or on
                behalf of an entity (“you”) and FutureNext GmbH (“Company”, “we”, “us”, or “our”), concerning your
                access to and use of the www.virtualbadge.io website including subdomains and the related website as
                well as www.futurenext.de as well as any other media form, media channel, mobile website or mobile
                application related, linked, or otherwise connected thereto (collectively, the “Site”), including
                (web-based) software products on other domains that were connected by our clients, i.e. the entity that
                created the badge campaign that is issuing your badge. You agree that by accessing the Site, you have
                read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF
                THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
                IMMEDIATELY.
            </Typography>
            <Typography>
                Supplemental terms and conditions or documents that may be posted on the Site from time to time are
                hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make
                changes or modifications to these Terms of Use at any time and for any reason. We will alert you about
                any changes by updating the “Last Updated” date of these Terms of Use, and you waive any right to
                receive specific notice of each such change. It is your responsibility to periodically review these
                Terms of Use to stay informed of updates. You will be subject to, and will be deemed to have been made
                aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site
                after the date such revised Terms of Use are posted.
            </Typography>
            <Typography>
                The information provided on the Site is not intended for distribution to or use by any person or entity
                in any jurisdiction or country where such distribution or use would be contrary to law or regulation or
                which would subject us to any registration requirement within such jurisdiction or country. Accordingly,
                those persons who choose to access the Site from other locations do so on their own initiative and are
                solely responsible for compliance with local laws, if and to the extent local laws are applicable.
            </Typography>
            <Typography>
                All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must
                have the permission of, and be directly supervised by, their parent or guardian to use the Site. If you
                are a minor, you must have your parent or guardian read and agree to these Terms of Use prior to you
                using the Site.
            </Typography>
            <Typography variant="h2"> INTELLECTUAL PROPERTY RIGHTS</Typography>
            <Typography>
                Unless otherwise indicated, the Site is our property and all source code, databases, functionality,
                software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the
                “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or
                controlled by us or licensed to us, and are protected by copyright and trademark laws and various other
                intellectual property rights and unfair competition laws of the United States, international copyright
                laws, and international conventions. The Content and the Marks are provided on the Site “AS IS” for your
                information and personal use only. Except as expressly provided in these Terms of Use, no part of the
                Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted,
                publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise
                exploited for any commercial purpose whatsoever, without our express prior written permission.
            </Typography>
            <Typography>
                Provided that you are eligible to use the Site, you are granted a limited license to access and use the
                Site and to download or print a copy of any portion of the Content to which you have properly gained
                access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you
                in and to the Site, the Content, and the Marks.
            </Typography>
            <Typography variant="h2"> USER REPRESENTATIONS</Typography>
            <Typography>
                By using the Site, you represent and warrant that:(1) you have the legal capacity and you agree to
                comply with these Terms of Use;(2) you are not a minor in the jurisdiction in which you reside, or if a
                minor, you have received parental permission to use the Site; (3) you will not access the Site through
                automated or non-human means, whether through a bot, script, or otherwise; (4) you will not use the Site
                for any illegal or unauthorized purpose; and (5) your use of the Site will not violate any applicable
                law or regulation.
            </Typography>
            <Typography>
                If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right
                to suspend or terminate your account and refuse any and all current or future use of the Site (or any
                portion thereof). Furthermore, we reserve the right to identify you by other means, as your input to
                this website or authentication with 3d party services.
            </Typography>
            <Typography variant="h2"> PROHIBITED ACTIVITIES</Typography>
            <Typography>
                You may not access or use the Site for any purpose other than that for which we make the Site available.
                The Site may not be used in connection with any commercial endeavors except those that are specifically
                endorsed or approved by us.
            </Typography>
            <Typography>As a user of the Site, you agree not to:</Typography>
            <HtmlList type="ol" list={TermsData.IntelectualProperty} />

            <Typography variant="h2">USER GENERATED CONTRIBUTIONS</Typography>
            <Typography>
                The Site may provide you with the opportunity to create, submit, post, display, transmit, perform,
                publish, distribute, or broadcast content and materials to us or on the Site, including but not limited
                to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information
                or other material (collectively, “Contributions”). Examples of Contributions are, but not limited to:
                Badge profile picture, your name, other custom inputs or dropdowns based on your coaching, course
                training, or other. Contributions may be viewable by other users of the Site and through third-party
                websites. As such, any Contributions you transmit may be treated as non-confidential and
                non-proprietary. When you create or make available any Contributions, you thereby represent and warrant
                that:
            </Typography>
            <HtmlList type="ol" list={TermsData.userGeneratedContributions} />
            <Typography>
                Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among
                other things, termination or suspension of your rights to use the Site.
            </Typography>
            <Typography>
                We are not liable for any damages resulting from the infringement of copyrights, patents, trademarks,
                trade secrets, or moral rights.
            </Typography>
            <Typography variant="h2">CONTRIBUTION LICENSE</Typography>
            <Typography>
                By posting your Contributions to any part of the Site, you automatically grant, and you represent and
                warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual,
                non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy,
                reproduce, disclose, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly
                display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions
                (including, without limitation, your image and voice) for any purpose, commercial, advertising, or
                otherwise, and to prepare derivative works of, or incorporate into other works, such Contributions, and
                grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media
                formats and through any media channels.
            </Typography>
            <Typography>
                This license will apply to any form, media, or technology now known or hereafter developed, and includes
                our use of your name, company name, and franchise name, as applicable, and any of the trademarks,
                service marks, trade names, logos, and personal and commercial images you provide. You waive all moral
                rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your
                Contributions.
            </Typography>
            <Typography>
                We do not assert any ownership over your Contributions. You retain full ownership of all of your
                Contributions and any intellectual property rights or other proprietary rights associated with your
                Contributions. We are not liable for any statements or representations in your Contributions provided by
                you in any area on the Site. You are solely responsible for your Contributions to the Site and you
                expressly agree to exonerate us from any and all responsibility and to refrain from any legal action
                against us regarding your Contributions.
            </Typography>
            <Typography>
                We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any
                Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the
                Site; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice.
                We have no obligation to monitor your Contributions.
            </Typography>
            <Typography variant="h2"> THIRD-PARTY WEBSITE AND CONTENT</Typography>
            <Typography>
                The Site may contain (or you may be sent via the Site) links to other websites (“Third-Party Websites“)
                as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information,
                applications, software, and other content or items belonging to or originating from third parties
                (“Third-Party Content“). Such Third-Party Websites and Third-Party Content are not investigated,
                monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible
                for any Third-Party Websites accessed through the Site or any Third-Party Content posted on, available
                through, or installed from the Site, including the content, accuracy, offensiveness, opinions,
                reliability, privacy practices, or other policies contained in the Third-Party Websites or the
                Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party
                Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide
                to leave the Site and access the Third-Party Websites or to use or install any Third-Party Content, you
                do so at your own risk, and you should be aware these Terms of Use no longer govern. You should review
                the applicable terms and policies, including privacy and data gathering practices, of any website to
                which you navigate from the Site or relating to any applications you use or install from the Site. Any
                purchases you make through Third-Party Websites will be through other websites and from other companies,
                and we take no responsibility whatsoever in relation to such purchases which are exclusively between you
                and the applicable third party. You agree and acknowledge that we do not endorse the products or
                services offered on Third-Party Websites and you shall hold us harmless from any harm caused by your
                purchase of such products or services. Additionally, you shall hold us harmless from any losses
                sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content
                or any contact with Third-Party Websites. The web application LinkedIn provided by LinkedIn Ireland
                Unlimited Company (“LinkedIn“), is used to provide the users with individualised and consensual
                services. The use is compliant with the LinkedIn API terms and conditions
                (https://legal.linkedin.com/api-terms-of-use). For the complete usage of the product a log in process to
                the LinkedIn servers is required. Therefore data will be transferred to LinkedIn Ireland Unlimited
                Company. If the user does not have an account he/she/they cannot access all functionalities of the
                product, as for example the automated creation of the badge. For further reference on how LinkedIn
                processes user data please refer to: https://www.linkedin.com/legal/privacy-policy. The web application
                Twitter provided by Twitter, Inc (“Twitter“), is also used to supply the users with our broad range of
                functionalities. Virtual Badges can be shared directly to the user’s Twitter account, from within the
                Virtualbadge.io application. The use is compliant with the Twitter API terms and conditions
                (https://developer.twitter.com/en/developer-terms/agreement-and-policy). For the use of this sharing
                functionality, the user needs a Twitter account. For further reference on how Twitter processes user
                data please refer to:https://twitter.com/en/privacy.
            </Typography>
            <Typography variant="h2"> SITE MANAGEMENT</Typography>
            <Typography>
                We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of
                Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or
                these Terms of Use, including without limitation, reporting such user to law enforcement authorities;
                (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability
                of, or disable (to the extent technologically feasible) any of your Contributions or any portion
                thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the
                Site or otherwise disable all files and content that are excessive in size or are in any way burdensome
                to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and
                property and to facilitate the proper functioning of the Site.
            </Typography>
            <Typography variant="h2">PRIVACY POLICY</Typography>
            <Typography>
                We care about data privacy and security.Please review our Privacy Policy
                https://app.virtualbadge.io/privacy. By using the Site, you agree to be bound by our Privacy Policy,
                which is incorporated into these Terms of Use. Please be advised the Site is hosted in Germany. If you
                access the Site from any other region of the world with laws or other requirements governing personal
                data collection, use, or disclosure that differ from applicable laws in Germany, then through your
                continued use of the Site, you are transferring your data to Germany, and you agree to have your data
                transferred to and processed in Germany.
            </Typography>

            <Typography variant="h2">TERM AND TERMINATION</Typography>
            <Typography>
                These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY
                OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
                NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO
                ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
                REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION
                THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. If we terminate or suspend your
                account for any reason, you are prohibited from registering and creating a new account under your name,
                a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the
                third party. In addition to terminating or suspending your account, we reserve the right to take
                appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
            </Typography>
            <Typography variant="h2">MODIFICATIONS AND INTERRUPTIONS</Typography>
            <Typography>
                We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason
                at our sole discretion without notice. However, we have no obligation to update any information on our
                Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any
                time. We will not be liable to you or any third party for any modification, price change, suspension, or
                discontinuance of the Site.
            </Typography>
            <Typography>
                We cannot guarantee the Site will be available at all times. We may experience hardware, software, or
                other problems or need to perform maintenance related to the Site, resulting in interruptions, delays,
                or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the
                Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever
                for any loss, damage, or inconvenience caused by your inability to access or use the Site during any
                downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us
                to maintain and support the Site or to supply any corrections, updates, or releases in connection
                therewith.
            </Typography>

            <Typography variant="h2">GOVERNING LAW</Typography>
            <Typography>
                These conditions are governed by and interpreted following the laws of Germany, and the use of the
                United Nations Convention of Contracts for the International Sale of Goods is expressly excluded. If
                your habitual residence is in the EU, and you are a consumer, you additionally possess the protection
                provided to you by obligatory provisions of the law of your country of residence. FutureNext GmbH and
                yourself both agree to submit to the non-exclusive jurisdiction of the courts of Baden-Württemberg,
                which means that you may make a claim to defend your consumer protection rights in regards to these
                Conditions of Use in Germany, or in the EU country in which you reside.
            </Typography>

            <Typography variant="h2">DISPUTE RESOLUTION</Typography>
            <Typography>
                The European Commission provides an online dispute resolution platform, which you can access here:
                https://ec.europa.eu/consumers/odr. If you would like to bring this subject to our attention, please
                contact us.
            </Typography>
            <Typography variant="h2">CORRECTIONS</Typography>
            <Typography>
                There may be information on the Site that contains typographical errors, inaccuracies, or omissions,
                including descriptions, pricing, availability, and various other information. We reserve the right to
                correct any errors, inaccuracies, or omissions and to change or update the information on the Site at
                any time, without prior notice.
            </Typography>

            <Typography variant="h2">DISCLAIMER</Typography>
            <Typography>
                THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR
                SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE
                NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT
                OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
                MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
                NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
                USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
                THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES,
                TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR
                (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
                AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE
                DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
                FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE
                FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
                WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR
                BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
            </Typography>

            <Typography variant="h2">LIMITATIONS OF LIABILITY</Typography>
            <Typography>
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
                PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
                BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT
                ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH
                PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
                LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY
                TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
                ADDITIONAL RIGHTS.
            </Typography>

            <Typography variant="h2">INDEMNIFICATION</Typography>
            <Typography>
                You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of
                our respective officers, agents, partners, and employees, from and against any loss, damage, liability,
                claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or
                arising out of:(1) your Contributions;(2) use of the Site; (3) breach of these Terms of Use; (4) any
                breach of your representations and warranties set forth in these Terms of Use; (5) your violation of the
                rights of a third party, including but not limited to intellectual property rights; or (6) any overt
                harmful act toward any other user of the Site with whom you connected via the Site. Notwithstanding the
                foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any
                matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our
                defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or
                proceeding which is subject to this indemnification upon becoming aware of it.
            </Typography>

            <Typography variant="h2">USER DATA</Typography>
            <Typography>
                We will maintain certain data that you transmit to the Site for the purpose of managing the performance
                of the Site, as well as data relating to your use of the Site. Although we perform regular routine
                backups of data, you are solely responsible for all data that you transmit or that relates to any
                activity you have undertaken using the Site. You agree that we shall have no liability to you for any
                loss or corruption of any such data, and you hereby waive any right of action against us arising from
                any such loss or corruption of such data.
            </Typography>

            <Typography variant="h2">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</Typography>
            <Typography>
                Visiting the Site, sending us emails, and completing online forms constitute electronic communications.
                You consent to receive electronic communications, and you agree that all agreements, notices,
                disclosures, and other communications we provide to you electronically, via email and on the Site,
                satisfy any legal requirement that such communication is in writing. YOU HEREBY AGREE TO THE USE OF
                ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
                POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any
                rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any
                jurisdiction which require an original signature or delivery or retention of non-electronic records, or
                to payments or the granting of credits by any means other than electronic means.
            </Typography>

            <Typography variant="h2">MISCELLANEOUS</Typography>
            <Typography>
                These Terms of Use and any policies or operating rules posted by us on the Site or with respect to the
                Site constitute the entire agreement and understanding between you and us. Our failure to exercise or
                enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or
                provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all
                of our rights and obligations to others at any time. We shall not be responsible or liable for any loss,
                damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or
                part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that
                provision or part of the provision is deemed severable from these Terms of Use and does not affect the
                validity and enforceability of any remaining provisions. There is no joint venture, partnership,
                employment, or agency relationship created between you and us as a result of these Terms of Use or use
                of the Site. You agree that these Terms of Use will not be construed against us by virtue of having
                drafted them. You hereby waive any and all defenses you may have based on the electronic form of these
                Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.
            </Typography>
            <Typography>
                Image from email:{" "}
                <a href="https://de.freepik.com/fotos/geschaeft">created by senivpetro - de.freepik.com</a>. Icons from
                maintenance page by{" "}
                <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
                    Freepik
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                    {" "}
                    www.flaticon.com
                </a>
            </Typography>
            <Typography>
                For all license info please see{" "}
                <a href={`${process.env.FRONTEND_URL}/pages/licenses.html`} target="_blank">
                    https://app.virtualbadge.io/pages/licenses.html
                </a>
            </Typography>

            <Typography variant="h2">CONTACT US</Typography>
            <Typography>
                In order to resolve a complaint regarding the Site or to receive further information regarding use of
                the Site, please contact us at:
            </Typography>

            <Typography>
                FutureNext GmbH
                <br />
                Julius-Hatry-Straße 1<br />
                Mannheim, Baden-Württemberg 68163
                <br />
                Germany
                <br />
                help@futurenext.de
                <br />
            </Typography>
        </div>
    );
};

export default TermsContent;
