import ReloadIcon from "../../../../components/ReloadIcon/ReloadIcon";
import StyledInfoBox from "../../../../components/shared/InfoBox/StyledInfoBox";
import useTranslation from "../../../../i18n/hooks/useTranslation";
import eventSelectorService from "../../../../redux/selector/events";
import { useAppSelector } from "../../../../redux/store";
import { EVariantTranslation } from "../../../../types/enums";
import { getTranslatedCertificateOrBadge } from "../../../../utils/misc";

const BackendPdfCertificateInCreation = () => {
    const t = useTranslation();
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);
    return (
        <StyledInfoBox variant="yellow">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "center",
                }}
            >
                <div style={{ marginRight: "8px" }}>
                    <div>
                        {t("certificate_download.certificate_details.backend_pdf_in_creation.info", {
                            certificateType: getTranslatedCertificateOrBadge(
                                eventDetails?.event_type,
                                EVariantTranslation.LOWERCASE_SINGULAR
                            ),
                        })}
                    </div>
                    <div>{t("certificate_download.certificate_details.backend_pdf_in_creation.try_again")}</div>
                </div>
                <ReloadIcon
                    title={t("certificate_download.certificate_details.backend_pdf_in_creation.click_to_reload")}
                />
            </div>
        </StyledInfoBox>
    );
};

export default BackendPdfCertificateInCreation;
