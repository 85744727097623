import styled from "@emotion/styled";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { styled as mui_styled } from "@mui/material/styles";

import Button from "../../../../../components/shared/Button";
import IconButton from "../../../../../components/shared/IconButton";
import useTranslation from "../../../../../i18n/hooks/useTranslation";
import certificatesActionService from "../../../../../redux/actions/certificates";
import sideActionsService from "../../../../../redux/actions/sideActions";
import certificatesSelectorService from "../../../../../redux/selector/certificates";
import controlSelectorService from "../../../../../redux/selector/control";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { MAIN_BLUE } from "../../../../../utils/Theme/theme";

const MultyCertificateSliderContainer = mui_styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "24px",
    [theme.breakpoints.down("lg")]: { padding: "0px 40px" },
    [theme.breakpoints.up("lg")]: { paddingRight: "80px" },
}));

const CertificateControlContainer = styled.div({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: MAIN_BLUE,
    fontWeight: 700,
});

const MultiCertificateSlider = () => {
    const t = useTranslation();
    const dispatch = useAppDispatch();
    const userFlow = useAppSelector(controlSelectorService.selectUserFlow);
    const certificates = useAppSelector(certificatesSelectorService.selectCertificates);

    const totalCertificates = certificates.data.length;

    const handleClickBackToDashboard = () => dispatch(sideActionsService.goBackToDashboard());

    const handleChangeCertificate = (goTo: number) => {
        localStorage.setItem("last_viewed_certificate", certificates.data[goTo].certificate_id);
        dispatch(certificatesActionService.selectCertificate(goTo));
    };

    return (
        <MultyCertificateSliderContainer>
            <Button onClick={handleClickBackToDashboard}>
                <KeyboardArrowLeftIcon /> {t("certificate_download.certificate_slider.back")}
            </Button>
            <CertificateControlContainer>
                <IconButton
                    id="move to previous certificate"
                    disabled={userFlow.showedCertificate === 0}
                    onClick={() => handleChangeCertificate(userFlow.showedCertificate - 1)}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>{" "}
                {(userFlow.showedCertificate + 1).toString()} / {totalCertificates.toString()}
                <IconButton
                    id="move to next certificate"
                    disabled={userFlow.showedCertificate === totalCertificates - 1}
                    onClick={() => handleChangeCertificate(userFlow.showedCertificate + 1)}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </CertificateControlContainer>
        </MultyCertificateSliderContainer>
    );
};

export default MultiCertificateSlider;
