/* eslint-disable no-case-declarations */
import { isEmpty } from "lodash";

import usePushSnack from "hooks/SnackbarManager/usePushSnack";
import useTranslation from "i18n/hooks/useTranslation";
import { getMonthYearFromStringifiedDate } from "utils/dates.helpers";

// import getDeepLink, { openDeepLink } from "utils/getDeepLink/getDeepLink";
import certificatesActionService from "../../../../redux/actions/certificates";
import { useGetCurrentSelectedCertificate } from "../../../../redux/hooks/badge";
import { useGetCertificateDetails } from "../../../../redux/hooks/queryHooks";
import eventSelectorService from "../../../../redux/selector/events";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { ESocialMediaPlatforms } from "../../../../types/enums";

export const useShareCertificate = () => {
    const t = useTranslation();
    const dispatch = useAppDispatch();
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);
    const selectedCertificate = useGetCurrentSelectedCertificate();

    const { data: certificateDetails } = useGetCertificateDetails();
    const pushSnack = usePushSnack();

    const certificatePageLink = selectedCertificate.validation_url;

    const assembleLinkedinCertificateLink = (
        event_name: string,
        badge_url: string,
        cert_id: string,
        orga_ref: string,
        issue_date: string,
        expiration_date: string | undefined,
        certificateValidationEnabled: boolean
        // forceDesktop?: boolean
    ) => {
        // Sanitize variables
        const newOrgaRef = orga_ref.replace(/[ ]/g, "");
        const issueMonthYear = getMonthYearFromStringifiedDate(issue_date);

        // Encode parameters that might have non url compliant characters. The badge url is already encoded, therefore it does not get encoded again here.
        const newCertificateName = encodeURIComponent(event_name);

        // Deep link system is currently disabled till we have a response on stackoverflow
        // #12059
        // const generatedLinks = getDeepLink("linkedin");

        // Assemble base link
        // let link = `${
        //     generatedLinks[forceDesktop ? "url" : "deepLink"]
        // }/add?startTask=CERTIFICATION_NAME&name=${newCertificateName}&issueYear=${issueMonthYear[1]}&issueMonth=${
        //     issueMonthYear[0]
        // }`;

        // Assemble base link
        let link = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${newCertificateName}&issueYear=${issueMonthYear[1]}&issueMonth=${issueMonthYear[0]}`;

        // Assemble certificate id and validation link to the link
        if (certificateValidationEnabled) {
            link = `${link}&certId=${cert_id}`;
            if (badge_url !== "") link = `${link}&certUrl=${badge_url}`;
        }
        link = `${link}${newOrgaRef}`;

        // Add expiration if present
        if (expiration_date) {
            const expirationMonthYear = getMonthYearFromStringifiedDate(expiration_date);
            link = `${link}&expirationYear=${expirationMonthYear[1]}&expirationMonth=${expirationMonthYear[0]}`;
        }

        // Remove temporary saved token from local storage
        if (localStorage.getItem("social_media_verification_token"))
            localStorage.removeItem("social_media_verification_token");

        return link;
    };

    const handleAddToLinkedIn = () => {
        if (!isEmpty(certificateDetails)) {
            const orga_ref =
                eventDetails?.event_linkedin_id !== ""
                    ? `&organizationId=${eventDetails?.event_linkedin_id}`
                    : `&organizationName=${eventDetails?.organisation_name?.replaceAll(" ", "%20")}`;

            dispatch(
                certificatesActionService.postStats({
                    certificate_id: selectedCertificate.certificate_id,
                    stats_type: "stats_linkedin_certificate",
                })
            );

            const link = assembleLinkedinCertificateLink(
                certificateDetails.event__name,
                encodeURIComponent(certificatePageLink),
                selectedCertificate.certificate_id,
                orga_ref,
                selectedCertificate.issue_date ?? certificateDetails.issue_date,
                selectedCertificate.expiration_date ?? certificateDetails.expiration_date,
                selectedCertificate.validation_page_enabled
            );

            // Deep link system is currently disabled till we have a response on stackoverflow
            // #12059
            // const fallback = assembleLinkedinCertificateLink(
            //     certificateDetails.event__name,
            //     encodeURIComponent(certificatePageLink),
            //     selectedCertificate.certificate_id,
            //     orga_ref,
            //     selectedCertificate.issue_date ?? certificateDetails.issue_date,
            //     selectedCertificate.expiration_date ?? certificateDetails.expiration_date,
            //     selectedCertificate.validation_page_enabled,
            //     true
            // );

            // openDeepLink(link, fallback);

            window.open(link);

            // Remove temporary saved token from local storage
            if (localStorage.getItem("social_media_verification_token"))
                localStorage.removeItem("social_media_verification_token");
        }
    };

    const handleShareToFacebook = async () => {
        try {
            const windowReference = window.open("?loading=facebook", "_blank", "width=550, height=600");
            if (windowReference) {
                windowReference.location = `https://www.facebook.com/sharer/sharer.php?u=${selectedCertificate.badge_image_url}`;
            }
            dispatch(
                certificatesActionService.postStats({
                    certificate_id: selectedCertificate.certificate_id,
                    stats_type: "stats_share_facebook_count",
                })
            );

            // Remove temporary saved token from local storage
            if (localStorage.getItem("social_media_verification_token"))
                localStorage.removeItem("social_media_verification_token");
        } catch (error: any) {
            pushSnack({
                title: t("common.alerts.messages.unexpected_error_title"),
                body: error?.response?.text || t("common.alerts.messages.unexpected_error_message"),
                type: "error",
            });
        }
    };

    const handleShareToWhatsapp = () => {
        const message = eventDetails.event_share_text;
        const textToSend = message
            ? `${message}\n${selectedCertificate.validation_url}`
            : (selectedCertificate.validation_url as string);

        const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(textToSend)}`;

        const windowReference = window.open("?loading=whatsapp", "_blank", "width=650, height=600");
        if (windowReference) {
            windowReference.location = whatsappLink;

            // Remove temporary saved token from local storage
            if (localStorage.getItem("social_media_verification_token"))
                localStorage.removeItem("social_media_verification_token");
        }
    };

    const handleShareToSocialMedia = async (type: ESocialMediaPlatforms) => {
        switch (type) {
            case ESocialMediaPlatforms.FACEBOOK:
                await handleShareToFacebook();
                break;
            case ESocialMediaPlatforms.WHATSAPP:
                handleShareToWhatsapp();
                break;
            default:
                break;
        }
    };

    return { handleAddToLinkedIn, handleShareToSocialMedia };
};
