import { socialMediaColors, socialMediaHoverColors } from "utils/Theme/theme";

import type { SxProps } from "@mui/material";

export const whatsappButton: SxProps = {
    backgroundColor: socialMediaColors.whatsapp,
    width: "100% !important",
    ":hover": {
        backgroundColor: socialMediaHoverColors.whatsapp,
    },
};
