import { Trans, useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import Button from "../../../../../components/shared/Button";
import Divider from "../../../../../components/shared/Divider";
import AttachSpecialCharacter from "../../../../../i18n/helper/AttachSpecialCharacter";
import i18nInstance from "../../../../../i18n/index";
import useGetTranslatedEventType from "../../../../../utils/getEventTypeI18n";

type Props = {
    walletUrl: string;
    open: boolean;
    setOpen: (state: boolean) => void;
};

const AppleWalletModal = ({ walletUrl, open, setOpen }: Props) => {
    const { t } = useTranslation("translation", { i18n: i18nInstance });
    const { translatedEventType } = useGetTranslatedEventType();

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h5">
                    <Trans
                        t={t}
                        i18nKey="wallet.apple.modal.title"
                        components={{
                            trademark: <AttachSpecialCharacter character="trademark" />,
                        }}
                    />
                </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Typography variant="body2">
                    <Trans
                        t={t}
                        i18nKey="wallet.apple.modal.upper_content"
                        values={{
                            translatedEventType: translatedEventType("lowercase_singular"),
                        }}
                        components={{
                            trademark: <AttachSpecialCharacter character="trademark" />,
                        }}
                    />
                </Typography>
                <Typography variant="body2">{t("wallet.apple.modal.lower_content")}</Typography>
                <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "15px" }}>
                    <QRCode value={walletUrl} size={256} style={{ height: "auto", maxWidth: "50%", width: "50%" }} />
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="outlined" onClick={() => setOpen(false)}>
                    {t("wallet.apple.modal.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AppleWalletModal;
