import { sanitize } from "sanitize-filename-ts";

/**
 * Sanitize combination of filename strings inclusive extension.
 *
 * Replace space with "_"
 * @example sanitzeFileName("hello world", {suffix: "?jkdfj/", fileType: "pdf"}) => "hello_world_jkdfj.pdf"
 * @argument {string} fileName (Required) Pure filename
 * @argument {string} prefix (Optional) Prefix of the filename
 * @argument {string} suffix (Optional) Suffix of the filename
 * @argument {string} fileType (Optional) File type of the filename without dot
 * @param {string} combinedFileName Combined file name
 * @returns {string} Sanitized file name
 */
const sanitizeFileName = (fileName: string, options: { prefix?: string; suffix?: string; fileType?: string }) => {
    let combinedFileName = fileName;
    if (options.prefix) combinedFileName = `${options.prefix}-${combinedFileName}`;
    if (options.suffix) combinedFileName = `${combinedFileName}-${options.suffix}`;
    combinedFileName = sanitize(combinedFileName.replaceAll(" ", "_"));
    if (options.fileType) combinedFileName = `${combinedFileName}.${options.fileType}`;
    return combinedFileName;
};

export default sanitizeFileName;
