import PrivacyContent from "components/PrivacyContent";
import LegalContainer from "layouts/LegalContainer";

export default function Privacy() {
    return (
        <LegalContainer>
            <PrivacyContent />
        </LegalContainer>
    );
}
