import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import type { ReduxAction } from "../../types/global";
import { reasons } from "../../utils/redirectionReason";

import { certificatePropsApi } from "./query/certificateProps";
import type { TNotificationMessage, TNotificationStore } from "./types/notification.types";

const initialState: TNotificationStore = {
    notification: null,
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        addNotification: (_state: TNotificationStore, action: ReduxAction<TNotificationMessage>) => ({
            notification: action.payload,
        }),
        removeNotification: () => ({ notification: null }),
    },
    extraReducers: (builder) =>
        // Matcher for query rejects
        builder.addMatcher(
            isAnyOf(
                certificatePropsApi.endpoints.getCertificateDetails.matchRejected,
                certificatePropsApi.endpoints.getCertificateProps.matchRejected
            ),
            (state, action: ReduxAction<FetchBaseQueryError | undefined>) => {
                if (action.error.message === "Rejected" && action.payload) {
                    if (
                        action.meta.arg.endpointName === "getCertificateDetails" &&
                        !Number.isNaN(Number(action.payload.status)) &&
                        (action.payload.status as number) >= 400 &&
                        (action.payload.status as number) < 500
                    ) {
                        return { notification: reasons().slug_not_found.notification };
                    }
                    if (
                        action.meta.arg.endpointName === "getCertificateProps" &&
                        !Number.isNaN(Number(action.payload.status)) &&
                        (action.payload.status as number) >= 400 &&
                        (action.payload.status as number) < 500
                    ) {
                        return { notification: reasons().slug_not_found.notification };
                    }
                }
                // If the reject error is not spezified as an api error, ignore it.
                return state;
            }
        ),
});

export const { addNotification, removeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
