import { deleteCookie } from "utils/cookies";

type TCookiesItem = {
    name: string;
    description: string;
    link?: string;
    storage_duration: string;
    persistent_session: string;
    category: string;
};

// holds constant according to the cookie type (essential, functional).
// Each constant holds
//      - cookiename,
//      - a description,
//      - an optional link,
//      - the storage duration
//      - the info wether it is a persistent or a session cookie

export const COOKIES_FUNCTIONAL: Array<TCookiesItem> = [
    {
        name: "ai_session",
        description:
            "This cookie is used to enhance website performance by monitoring user sessions for diagnostic purposes. It helps in quickly identifying and addressing any technical issues, ensuring a smooth and efficient user experience.",
        storage_duration: "Session",
        persistent_session: "Session",
        category: "Third-Party Cookie",
    },
    {
        name: "ai_user",
        description:
            "This cookie is designed to support website functionality by facilitating the collection of diagnostic data across sessions. Its primary purpose is to enable our technical team to identify and address any issues that may arise during website use.",
        storage_duration: "1 year",
        persistent_session: "Persistent",
        category: "Third-Party Cookie",
    },
];

export const COOKIES_ESSENTIAL: Array<TCookiesItem> = [
    {
        name: "cookieconsent",
        description:
            "It is very important for us to inform visitors to our website about the use of user data. We therefore offer visitors these detailed cookie guidelines for their information. We inform visitors to our website about our cookie guidelines via a centrally located and easily recognizable information window. This information window remains until the respective visitor confirms that he or she has 'accepted' the cookie guidelines. This confirmation is stored as a value in this cookie. By reading this value, a script can distinguish whether or not the respective visitor will receive the message again when he or she visits our Internet pages again.",
        storage_duration: "30 days",
        persistent_session: "Persistent",
        category: "First-Party Cookie",
    },
    {
        name: "cookieconsent-legacy",
        description: `This cookie is needed as some browsers don't support the SameSite=None attribute. The modern browsers force you to have SameSite set to something other than none. A detailed explanation can be found here: `,
        link: `https://github.com/Mastermindzh/react-cookie-consent/pull/68`,
        storage_duration: "30 days",
        persistent_session: "Persistent",
        category: "First-Party Cookie",
    },
    {
        name: "virtualbadge_token",
        description:
            "The token, a user either enters into the token textfield on the first page or that is passed with the URL. This cookie enables us to check wether a user is allowed to create his or her badge / certificate and prevents fraud by badges created by unauthorized people.",
        storage_duration: "Session",
        persistent_session: "Session",
        category: "First-Party Cookie",
    },
    {
        name: "event_created_in_version",
        description:
            "The version number of the software the event was created in. Necessary to load libraries that are compatible with the event.",
        storage_duration: "Session",
        persistent_session: "Session",
        category: "First-Party Cookie",
    },
    {
        name: "noauth_access",
        description:
            "A cookie to determine wether a user has selected no authentication via a social media platform. This cookie enables us to check wether a user is allowed to create his or her badge / certificate and prevents fraud by badges created by unauthorized people.",
        storage_duration: "Session",
        persistent_session: "Session",
        category: "First-Party Cookie",
    },
    {
        name: "twitter_oauth_token",
        description:
            "A necessary token that is needed when sharing to Twitter. Is set after a user authenticates with Twitter. More details can be found on the Twitter pages: ",
        link: `https://developer.twitter.com/en/docs/authentication/oauth-1-0a/obtaining-user-access-tokens#:~:text=Twitter%20allows%20you%20to%20obtain,having%20them%20authorize%20your%20application`,
        storage_duration: "Session",
        persistent_session: "Session",
        category: "First-Party Cookie",
    },
    {
        name: "twitter_oauth_token_secret",
        description:
            "A necessary token that is needed when sharing to Twitter. Is set after a user authenticates with Twitter. More details can be found on the Twitter pages: ",
        link: `https://developer.twitter.com/en/docs/authentication/oauth-1-0a/obtaining-user-access-tokens#:~:text=Twitter%20allows%20you%20to%20obtain,having%20them%20authorize%20your%20application`,
        storage_duration: "Session",
        persistent_session: "Session",
        category: "First-Party Cookie",
    },
    {
        name: "twitter_show_auth_message",
        description:
            "Determines whether a message shall be shown to the user or if it already has been shown after a sucessful authentication on Twitter",
        storage_duration: "Session",
        persistent_session: "Session",
        category: "First-Party Cookie",
    },
    {
        name: "path",
        description: "This cookie enables showing the correct badge after authenticating with Twitter.",
        storage_duration: "Session",
        persistent_session: "Session",
        category: "First-Party Cookie",
    },
    {
        name: "linkedin_access_token",
        description:
            "The access token used to post to LinkedIn. Is set after successful authentication with LinkedIn. More information: ",
        link: `https://docs.microsoft.com/en-us/linkedin/shared/authentication/client-credentials-flow`,
        storage_duration: "Session",
        persistent_session: "Session",
        category: "First-Party Cookie",
    },
    {
        name: "facebook_access_token",
        description:
            "The access token used to pull profile image and user name from Facebook. Is set after successful authentication with Facebook. More information: ",
        link: `https://developers.facebook.com/docs/facebook-login/access-tokens/`,
        storage_duration: "Session",
        persistent_session: "Session",
        category: "First-Party Cookie",
    },
    /*
    {
        name: "certificate_page_link",
        description:
            "The link to access the users generated certification page link at https://www.virtualbadge.io/certificate-validator. Used to persist after page reload. ",
        storage_duration: "Session",
        persistent_session: "Session",
        category: "First-Party Cookie",
    },
    */
];

export const InformationCollected = [
    "Register, create, and edit a profile/a Virtual Badge.",
    "Answer survey questions.",
    "Enter contests.",
    "Use certain features on the site.",
    "Submit information to any of our personnel.",
];

export const InformationChoosen = [
    `Name, birthday, age, username, password, email address, LinkedIn profile, contact information,
                    address, location, educational and employment history, CV, résumé, academic and career aspirations,
                    etc.`,
    `Information relating to your devices, including device model, operating system, browser type, unique
                    device identifier, IP address, mobile phone number, mobile network carrier, location, and event
                    information (such as application installations) as needed by us or our third-party service
                    providers.`,
    "Other Personal Information you choose to provide.",
];

export const PersonalInformation = [
    `Information you provide: We collect the information you provide to us over the Website and our
                    registration forms. When you register or log in to your account, when we send a communication to
                    you, or when you access, respond to, or decline to open communication from us, complete a form to
                    communicate with us, or contacts us, we collect and store certain information about you such as your
                    first and last name, title, street address, telephone number, company name, email address, or other
                    information uploaded by you (e.g. photos, texts, and other relevant background information). Your
                    Personal Information will be kept confidential and will be used as needed to fulfill or respond to
                    your inquiries, complete registration, and/or provide you with other Website services.`,
    `All users who are minors in the jurisdiction in which they reside (generally under the age of 18)
                    must have the permission of, and be directly supervised by, their parent or guardian to use the
                    Website.`,
    `Information from other sources: We may receive information about you from publicly available and
                    third-party databases, services that provide information about business people and companies, or by
                    Clients of FutureNext GmbH .`,
    `Information about the use of our services and products: When you visit our Website, we receive and
                    save certain pieces of information, such as the IP address of your device, your operating system,
                    the type of Internet browser that you use, usage information, diagnostic information, and location
                    information from or about the computers, phones, or other devices on which you access our Websites,
                    preferences, and settings: time zone, language, and character size, time spent on a page, click
                    through, clickstream data, queries made, search results selected, search history, date and time of
                    your visit, the websites you visit within our service, the website from which you came if you visit
                    our Website via a link from a third party website, etc. We do this to improve our Website and
                    services.`,
];

export const UseOfInformation = [
    "To deliver our promised service.",
    "To fulfill or respond to your inquiries.",
    "To address administrative needs and communications.",
    "To improve our products and services.",
    "To contact you for internal business purposes.",
    `To provide you with any promotions elaborated by our Client, if/when the marketing consent applies.`,
];

export const CollectionPurpose = [
    `Providing, improving, and developing our products and services: We use Personal Information to help
                    us provide, improve, and develop our products, services, and advertising when permitted. This
                    includes using Personal Information for purposes such as data analysis, research, and audits. Such
                    processing is based on our legitimate interest in offering you products and services and for
                    business continuity.`,

    `Communicating with you: Subject to your prior express consent, we may use Personal Information to
                    send you marketing communications in relation to our products and services, communicate with you
                    about your account or participation requests, and inform you about our policies and terms. If you no
                    longer wish to receive email communications for marketing purposes, please contact us at
                    badge@futurenext.de. We also may use your information to process and respond to your requests when
                    you contact us. Subject to your prior express consent, we may share your Personal Information with
                    third-party partners who may send you marketing communications in relation to their products and
                    services.`,
];

export const OtherCollectionPurpose = [
    "To keep records of contact information and correspondence.",
    "To provide you with information, products, or services that you request from us or which we feel may interest you.",
    "To facilitate social sharing functionality, such as sharing content through social media networks.",
    "To carry out our obligations arising from agreements or contracts between you and us.",
    "To create a more accurate and complete customer profile to better understand the products and services you want to use.",
    "To improve the products and services we provide you and develop new products and services.",
    "Other purposes with your consent or as permitted or required by law.",
];

export const SharingInformation = [
    `If we believe it is necessary to share information in order to investigate, prevent or take action
                    regarding illegal activities, suspected fraud, situations involving possible threats to the physical
                    safety of any person, violations of our Terms of Use, or as otherwise required by law.`,
    `If we respond to subpoenas, court orders, or legal process, or if we need to establish or exercise
                    our legal rights or defend against legal claims.`,
    `If we believe it is necessary to restrict or inhibit any user from using any of our Websites,
                    including, without limitation, by means of “hacking“ or defacing any portion thereof.`,
];

export const handleUpdateCookies = () => {
    deleteCookie("cookieconsent");
    deleteCookie("cookieconsent-legacy");
    window.location.reload();
};

export const handleDeleteAndUpdateCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
        document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });

    window.location.reload();
};
