import { OptionsObject, useSnackbar } from "notistack";

export default function usePushSnack() {
    const { enqueueSnackbar } = useSnackbar() || {};
    return ({
        title,
        body,
        type = "success",
        actionButton,
        variant,
        ...others
    }: ISnackbarManagerProps & OptionsObject) => {
        enqueueSnackbar(body, {
            variant: type || variant,
            autoHideDuration: 3000,
            // Old design stays for later redesign
            // preventDuplicate: true,
            // anchorOrigin: {
            //     vertical: "bottom",
            //     horizontal: "left",
            // },
            // content: (key, message) => (
            //     <Alert
            //         id={key.toString()}
            //         title={title}
            //         severity={type}
            //         action={
            //             actionButton || (
            //                 <IconButton id="close-button" sx={styles.iconColor} onClick={() => closeSnackbar(key)}>
            //                     <CloseIcon />
            //                 </IconButton>
            //             )
            //         }
            //         icon={<SyncIcon />}
            //     >
            //         {message}
            //     </Alert>
            // ),
            ...others,
        });
    };
}
