/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unreachable-loop */
/* eslint-disable no-console */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import { collectEnvironmentInfo } from "utils/environmentLogger";

import { CertificateDownloaderApiRoutes } from "../../features/certificateDownload/api/routes";
import { store } from "../../features/certificateDownload/components/PolotnoWorkspace/PolotnoWorkspace";
import BadgeEngine from "../../features/certificateDownload/utils/BadgeEngine/BadgeEngine";
import { deserializeBadgeProps } from "../../features/certificateDownload/utils/BadgeEngine/BadgeEngine.helpers";
import { setDynamicFields } from "../../features/certificateDownload/utils/Polotno/MappingFields";
import { updateProfilePic } from "../../features/certificateDownload/utils/Polotno/Polotno.helpers";
import { axios } from "../../lib/axios";
import { EBadgeType } from "../../types/enums";
import backendPdfDownloadHelper from "../../utils/backendPdfDownloadHelper";
import checkPropsType from "../../utils/checkPropsType";
import { isTypeOf } from "../../utils/isTypeOf";
import { findRecipientImageInPolotnoProps } from "../../utils/misc";
import { certificatePropsApi } from "../slices/query/certificateProps";
import { type ThunkAPIConfig } from "../store";

import { TBadge, TBakedBadgeRespone, TCreateBadgeImage } from "./types/badge.types";
import sideActionsService from "./sideActions";
import { getPropsFromPropsType, usedProps } from "./validation";

export const staticChunkingAmount = 4;

const bypassProfilePicture = createAction<string>("badge/bypassProfilePicture");

const chunkBadgeBaking = createAsyncThunk<void, undefined, ThunkAPIConfig>(
    "badge/chunkBadgeBaking",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const dispatch = thunkAPI.dispatch;
        const data = state.control.userFlow.newCertificatesFound;
        const slugs = state.control.slugs;
        const chunksLeft = Math.ceil(data.length / staticChunkingAmount);
        if (chunksLeft === 0) {
            dispatch(sideActionsService.setNewCertificateLoadingState(null));
            return;
        } // Fallback if all chunks finished
        dispatch(sideActionsService.setNewCertificatesInQueue(staticChunkingAmount));
        dispatch(sideActionsService.setNewCertificateLoadingLoopTime({ type: "start", ms: Date.now() }));
        const startIndex = staticChunkingAmount - staticChunkingAmount;
        let endIndex: number | undefined = staticChunkingAmount;
        if (endIndex + 1 > data.length) endIndex = undefined; // Can declared as undefined to slice till the last item of the array
        const chunkedData = data.slice(startIndex, endIndex);
        const attachPropsPayload: TCreateBadgeImage<Data> = {
            slugs,
            data: chunkedData,
            chunking: {
                chunksLeft,
            },
        };
        console.log(
            `%cCurrent chunks left ${chunksLeft - 1} in progress!`,
            "font-size: 18px; font-weight: bold; color: blue"
        );
        console.log("Current chunk data: ", chunkedData);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(attachFieldMappingToBadge(attachPropsPayload));
    }
);

const attachParsedBadgePropsToCertificate = createAction<Data>("badge/attachParsedBadgePropsToCertificate");

const setBadgesAreBakedLoadingState = createAction<boolean>("badge/setBadgesBakingLoadingState");

/** Send the base64 image strings as array to backend to bake it */
const bakeBadgeImages = createAsyncThunk<TBakedBadgeRespone, TCreateBadgeImage<TBadge>, ThunkAPIConfig>(
    "badge/bakeBadgeImages",
    async (data: TCreateBadgeImage<TBadge>, thunkAPI): Promise<TBakedBadgeRespone> => {
        const body = { bulk_data: data.data };
        thunkAPI.dispatch(sideActionsService.setNewCertificateLoadingState("bake"));
        console.log(
            `%cBaking badge!${data.chunking ? ` Chunks left: ${data.chunking.chunksLeft - 1}` : ""}`,
            "font-size: 18px; font-weight: bold; color: blue"
        );
        thunkAPI.dispatch(setBadgesAreBakedLoadingState(false));
        thunkAPI.dispatch(
            sideActionsService.removeFoundNewCertificates(data.data.map((singleData) => singleData.certificate_id))
        );
        const badgeImageUrlList: TBakedBadgeRespone | void = await axios
            .post<TBakedBadgeRespone>(CertificateDownloaderApiRoutes.uploadCertificateToBlob, body)
            .then((res) => res.data)
            .catch((err) => console.error(err))
            .finally(() => thunkAPI.dispatch(setBadgesAreBakedLoadingState(true)));
        if (!badgeImageUrlList) throw new Error("Bad request while updating badge image url");
        if (data.chunking !== undefined && data.chunking.chunksLeft > 0) {
            thunkAPI.dispatch(sideActionsService.setNewCertificatesFinished(staticChunkingAmount));
            thunkAPI.dispatch(sideActionsService.setNewCertificateLoadingLoopTime({ type: "end", ms: Date.now() }));
            thunkAPI.dispatch(chunkBadgeBaking());
        } else thunkAPI.dispatch(sideActionsService.setNewCertificateLoadingState(null));
        return badgeImageUrlList;
    }
);

/** Create a base64 image string with attached field mapping */
const createBadgeImages = createAsyncThunk<TCreateBadgeImage<TBadge>["data"], TCreateBadgeImage<Data>, ThunkAPIConfig>(
    "badge/createBadgeImages",
    async (args, { getState, dispatch }) => {
        const { event } = getState();
        dispatch(sideActionsService.setNewCertificateLoadingState("build"));

        const badgesToBake: {
            certificate_id: string;
            badge: string;
        }[] = [];

        const parseBadge = async (certificate: Data) => {
            // startLoading
            dispatch(sideActionsService.setBadgeParseLoadingState(true));
            const badgeProxy = {
                certificate_id: certificate.certificate_id,
                badge: "",
                browser_os_metadata: collectEnvironmentInfo(),
            };
            const scale = 8;

            const isBadge = event.event?.badge_format === "BADGE_1_TO_1";

            /*
             * Reduce the pixel ratio for the certificates because the bigger ratio does not support by mobile devices.
             * Badges have smaller resolution and pixel ratio can be higher.
             *
             * It is important for store.toDataURL() method.
             */
            const pixelRatio = isBadge ? 8 : 5;

            let exportedProps = "";

            if (
                certificate.badge_props_type === EBadgeType.BADGE &&
                isTypeOf<VbDesigner.IBadgeProps>(certificate.badge_props)
            ) {
                const badgeEngine = new BadgeEngine(
                    event.event?.badge_format as VbDesigner.BadgeFormat,
                    "RECIPIENT_VIEW"
                );
                const badgeProps = cloneDeep(certificate.badge_props);
                await deserializeBadgeProps(badgeProps, event.event?.badge_format as VbDesigner.BadgeFormat);

                exportedProps = (await badgeEngine.exportBadge(badgeProps, { scale })) as string;
            }
            if (
                certificate.badge_props_type === EBadgeType.POLOTNO &&
                isTypeOf<PolotnoDesigner.PolotnoBadgePropsData>(certificate.badge_props)
            ) {
                store.loadJSON(certificate.badge_props);
                await store.waitLoading();
                exportedProps = (await store.toDataURL({ pixelRatio })) as string;
                store.clear({ keepHistory: false });
            }
            // stopLoading
            dispatch(sideActionsService.setBadgeParseLoadingState(false));

            await dispatch(attachParsedBadgePropsToCertificate({ ...certificate, badge_base_string: exportedProps }));

            badgeProxy.badge = cloneDeep(exportedProps).replace("data:image/png;base64,", "");

            badgesToBake.push(badgeProxy);
        };

        // eslint-disable-next-line no-restricted-syntax
        for await (const cert of args.data) {
            await parseBadge(cert);
        }

        dispatch(bakeBadgeImages({ data: badgesToBake, chunking: args.chunking }));
        return badgesToBake;
    }
);

/** Attche the uploaded profile image to the badge props */
const attachUploadedImageToBadge = createAsyncThunk<
    void,
    { certificate_id: string; image_blob_url: string },
    ThunkAPIConfig
>("badge/attachUploadedImageToBadge", async (args, thunkAPI): Promise<void> => {
    const { certificate_id, image_blob_url } = args;
    const { certificates: storedCertificates, control, event } = thunkAPI.getState();
    const certificates = cloneDeep(storedCertificates);
    const dispatch = thunkAPI.dispatch;
    let certificate = certificates.data.find((cert) => cert.certificate_id === certificate_id);
    const { data } = certificatePropsApi.endpoints.getCertificateProps.select(control.slugs)(thunkAPI.getState());
    // eslint-disable-next-line no-useless-return
    if (!certificate) return;

    if (!certificate.badge_props && data) {
        certificate = {
            ...certificate,
            badge_is_baked: certificate.badge_is_baked || certificate.stats_download_count > 0,
            badge_props_type: certificate.badge_props_type || usedProps(data),
            badge_props: certificate.badge_props || getPropsFromPropsType(data) || undefined,
            profile_picture_attached: true,
        };
    }

    if (checkPropsType(certificate.badge_props, certificate.badge_props_type, "badge")) {
        const badgeEngine = new BadgeEngine(event.event?.badge_format as VbDesigner.BadgeFormat, "RECIPIENT_VIEW");
        const badgeProps = cloneDeep(certificate.badge_props as VbDesigner.IBadgeProps);

        await badgeEngine.setCustomFieldsAndNameValues(
            badgeProps,
            certificate.field_mapping,
            certificate.validation_url,
            certificate.certificate_id
        );

        const profilePic = new Image();
        profilePic.src = image_blob_url;
        profilePic.onload = () => {
            // Get profile pic badge object
            const profilePicBadgeObject = badgeProps.userInformationObjects.find((el) => el.id === "user-profile-pic");
            if (!profilePicBadgeObject) return;

            // Get scaling factor to adjust the new width and height
            const scalingFactor =
                Math.max(profilePicBadgeObject.width, profilePicBadgeObject.height) /
                Math.max(profilePic.width, profilePic.height);

            // Set new dimensions
            const newWidth = profilePic.width * scalingFactor;
            const newHeight = profilePic.height * scalingFactor;

            // Draw a resized image on an intermediate canvas to achieve "scale to fit" in the profile picture are on the badge
            const canvas = document.createElement("canvas");
            const imageScalingFactor = 10; // Used to increase quality
            canvas.width = profilePicBadgeObject.width * imageScalingFactor;
            canvas.height = profilePicBadgeObject.height * imageScalingFactor;
            const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
            ctx.fillStyle = "transparent";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(
                profilePic,
                canvas.width / 2 - (newWidth * imageScalingFactor) / 2,
                canvas.height / 2 - (newHeight * imageScalingFactor) / 2,
                newWidth * imageScalingFactor,
                newHeight * imageScalingFactor
            );

            const newProfilePicture = new Image();
            newProfilePicture.src = canvas.toDataURL();

            newProfilePicture.onload = () => {
                profilePicBadgeObject.img = newProfilePicture;
                profilePicBadgeObject.imgExport = newProfilePicture;
                profilePicBadgeObject.imgBlobUrl = newProfilePicture.src;
                dispatch(createBadgeImages({ data: [{ ...(certificate as Data), badge_props: badgeProps }] }));
            };
        };
    }
    if (checkPropsType(certificate.badge_props, certificate.badge_props_type, "polotno")) {
        // Get profile pic object
        let polotnoPropsClone = cloneDeep(certificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData);
        polotnoPropsClone = await setDynamicFields(
            polotnoPropsClone,
            certificate.field_mapping,
            certificate.validation_url,
            certificate.certificate_id,
            certificate.issue_date as string,
            certificate.expiration_date || undefined
        );
        const profilePicture = findRecipientImageInPolotnoProps(polotnoPropsClone);

        if (profilePicture) await updateProfilePic(polotnoPropsClone, profilePicture, image_blob_url);

        dispatch(createBadgeImages({ data: [{ ...(certificate as Data), badge_props: polotnoPropsClone }] }));
    }
});

const attachFieldMapOnSingleCertificate = async (
    certificate: Data & {
        certificate_id: string;
    },
    thunkApi: ThunkAPIConfig,
    forceBaking = false
) => {
    const state = thunkApi.state;
    const dispatch = thunkApi.dispatch;
    let certificatePropsProxy = certificate.badge_props;
    const isValidationPageEnabled = certificate.validation_page_enabled;
    const validationUrl = isValidationPageEnabled ? certificate.validation_url : undefined;
    const certificateId = isValidationPageEnabled ? certificate.certificate_id : undefined;
    switch (certificate.badge_props_type) {
        case "badge":
            if (state.event.event.badge_format && certificate.badge_props) {
                const badgeEngine = new BadgeEngine(state.event.event.badge_format, "RECIPIENT_VIEW");
                const clonedBadgeProps = cloneDeep(certificate.badge_props as VbDesigner.IBadgeProps);
                certificatePropsProxy = await badgeEngine.setCustomFieldsAndNameValues(
                    clonedBadgeProps,
                    certificate.field_mapping,
                    validationUrl,
                    certificateId
                );
            }
            break;
        case "polotno":
            dispatch(sideActionsService.setBadgeParseLoadingState(true));
            certificatePropsProxy = await setDynamicFields(
                certificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData,
                certificate.field_mapping,
                validationUrl || "",
                certificateId || "",
                certificate.issue_date as string,
                certificate.expiration_date || undefined
            );
            dispatch(sideActionsService.setBadgeParseLoadingState(false));
            break;
        default:
            return null;
    }
    const certificateProxy = { ...certificate, badge_props: certificatePropsProxy };
    dispatch(attachParsedBadgePropsToCertificate(certificateProxy));
    if (certificate.badge_image_url === null || forceBaking) return certificateProxy;
    return null;
};

/** Attache the field mapping to the badge props */
const attachFieldMappingToBadge = createAsyncThunk<void, TCreateBadgeImage<Data>, ThunkAPIConfig>(
    "badge/attachFieldMappingToBadge",
    async (args: TCreateBadgeImage<Data>, thunkAPI): Promise<void> => {
        const state = thunkAPI.getState();
        const { slugs, data, chunking, forceBaking } = args;
        console.log(
            `%cAttach field mapping loop!${chunking ? ` Chunks left: ${chunking.chunksLeft - 1}` : ""}`,
            "font-size: 18px; font-weight: bold; color: blue"
        );
        const dispatch = thunkAPI.dispatch;
        dispatch(sideActionsService.setNewCertificateLoadingState("build"));
        const certificatesToParseToBaseString: Data[] = [];
        for await (const certificate of data) {
            const certificatesToParse = backendPdfDownloadHelper.checkSingleRecipient(
                state.event.event.event_pdf_type,
                certificate
            ).isBackendPdf
                ? null
                : await attachFieldMapOnSingleCertificate(
                      certificate,
                      {
                          dispatch,
                          state: thunkAPI.getState(),
                      },
                      forceBaking
                  );
            if (certificatesToParse !== null) await certificatesToParseToBaseString.push(certificatesToParse);
        }
        if (certificatesToParseToBaseString.length > 0)
            dispatch(createBadgeImages({ data: certificatesToParseToBaseString, slugs, chunking }));
        else dispatch(sideActionsService.setNewCertificateLoadingState(null));
    }
);

const badgeActionService = {
    attachFieldMappingToBadge,
    attachParsedBadgePropsToCertificate,
    bakeBadgeImages,
    createBadgeImages,
    attachUploadedImageToBadge,
    setBadgesAreBakedLoadingState,
    chunkBadgeBaking,
    bypassProfilePicture,
};

export default badgeActionService;
