import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import type { AxiosError, AxiosResponse } from "axios";
import { cloneDeep } from "lodash";

import type {
    IBadgesResponse,
    ICertificatePropsResult,
    IFacebookResponse,
} from "../../features/certificateDownload/api/queries.app";
import { CertificateDownloaderApiRoutes } from "../../features/certificateDownload/api/routes";
import BadgeEngine from "../../features/certificateDownload/utils/BadgeEngine/BadgeEngine";
import { deserializeBadgeProps } from "../../features/certificateDownload/utils/BadgeEngine/BadgeEngine.helpers";
import { setProfilePictureInBadgeProps } from "../../features/certificateDownload/utils/pdfGeneration/pdf.helpers";
import { setDynamicFields } from "../../features/certificateDownload/utils/Polotno/MappingFields";
import { setProfilePictureInPolotnoProps } from "../../features/certificateDownload/utils/Polotno/Polotno.helpers";
import type { ILinkedinProfile } from "../../features/certificateValidator/types";
import { isTokenValid } from "../../features/certificateValidator/utils/tokenValidation";
import usePushSnack from "../../hooks/SnackbarManager";
import useGetOrgaCertificate from "../../hooks/useGetOrgaCertificate";
import useTranslation from "../../i18n/hooks/useTranslation";
import { axios } from "../../lib/axios";
import { EAuthButton } from "../../types/enums";
import cleanupLocalStorageFromUnusedAccessTokens from "../../utils/cleanupLocalStorageFromUnusedAccessToken";
import { getHash } from "../../utils/fingerprint";
import { isNotValidValue } from "../../utils/isNotValidValue";
import isOnBadgeRoute from "../../utils/isOnBadgeRoute";
import { isTypeOf } from "../../utils/isTypeOf";
import { findRecipientImageInBadgeProps, findRecipientImageInPolotnoProps } from "../../utils/misc";
import badgeActionService from "../actions/badge";
import sideActionsService from "../actions/sideActions";
import validationActionService, { getPropsFromPropsType, usedProps } from "../actions/validation";
import certificatesSelectorService from "../selector/certificates";
import controlSelectorService from "../selector/control";
import { useGetCertificateDetailsQuery, useGetCertificatePropsQuery } from "../slices/query/certificateProps";
import { IGetCertificateArgs } from "../slices/types/certificateProps.query.types";
import { useAppDispatch, useAppSelector } from "../store";

import useLoadingThunk from "./loading";

/**
 * Hook to get the organization and certificate slugs.

 * @param {Function} useGetOrgaCertificate - A hook to get the organization and certificate slugs.
 * @param {Function} useDispatch - A hook to dispatch actions to the store.
 * @param {Object} controlSelectorService - A selector to get the state from the store.
 * @returns {{ orga_slug: string | undefined; certificate_slug: string | undefined }} An object containing the organization and certificate slugs.
 */
export const useGetOrgaSlugs = (): IGetCertificateArgs => {
    const slugs = useGetOrgaCertificate();
    const dispatch = useDispatch();
    const storedSlugs = useAppSelector(controlSelectorService.selectSlugs);

    const returnedSlugs = storedSlugs.orga_slug && storedSlugs.certificate_slug ? storedSlugs : slugs;

    useEffect(() => {
        if (!storedSlugs.orga_slug && !storedSlugs.certificate_slug)
            dispatch(validationActionService.setOrgaSlugs(slugs));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return returnedSlugs;
};

/**
 * Hook to validate the token.

 * @param {Function} usePushSnack - A hook to push snackbars.
 * @param {Function} useTranslation - A hook to get the translation.
 * @param {Function} useNavigate - A hook to navigate.
 * @param {Function} useAppDispatch - A hook to dispatch actions to the store.
 * @param {{ orga_slug: string; certificate_slug: string }} useGetOrgaSlugs - A hook to get the organization and certificate slugs.
 * @returns {{ inputToken: string; setInputToken: Dispatch<SetStateAction<string>>; verifyToken: Function; isVerificationAllowed: false | RegExpExecArray }} An object containing the input token, a function to set the input token, a function to verify the token, and a boolean indicating whether the verification is allowed.
 */
export const useValidateToken = (): {
    inputToken: string;
    setInputToken: Dispatch<SetStateAction<string>>;
    verifyToken: (options?: { useInput?: boolean; removeAllAccessToken?: boolean }) => Promise<void>;
    isVerificationAllowed: false | RegExpExecArray;
} => {
    const pushSnack = usePushSnack();
    const t = useTranslation();
    const navigate = useNavigate();

    const slugs = useGetOrgaSlugs();

    const { isFulfilled } = useLoadingThunk();

    const tokenValidated = isFulfilled("token");

    const dispatch = useAppDispatch();

    const onBadgeRoute = isOnBadgeRoute();
    const [searchParams] = useSearchParams();
    const paramToken = searchParams.get("token") || "";
    const storeToken = useAppSelector(controlSelectorService.selectToken);
    const tokenInformations = useAppSelector(controlSelectorService.selectTokenValidation);
    const [inputToken, setInputToken] = useState("");

    const usedToken = useMemo(() => {
        if (!isNotValidValue(paramToken)) return paramToken;
        if (!isNotValidValue(storeToken)) return storeToken;
        if (!isNotValidValue(inputToken)) return inputToken;
        return "";
    }, [inputToken, storeToken, paramToken]);

    const isVerificationAllowed = useMemo(() => isTokenValid(inputToken), [inputToken]);

    const verifyToken = async (options?: { useInput?: boolean; removeAllAccessToken?: boolean }) => {
        const sendingToken = options?.useInput ? inputToken : usedToken;

        if (isNotValidValue(sendingToken) && onBadgeRoute) navigate(`/${slugs.orga_slug}/${slugs.certificate_slug}`);

        if (
            sendingToken &&
            typeof sendingToken === "string" &&
            (!tokenValidated || tokenInformations.severity === "error")
        ) {
            if (isTokenValid(sendingToken)) {
                if (options?.removeAllAccessToken) cleanupLocalStorageFromUnusedAccessTokens("all");
                await dispatch(validationActionService.validateToken({ token: sendingToken }));
                return;
            }

            pushSnack({
                title: t("common.alerts.messages.token_invalid_title"),
                body: t("common.alerts.messages.token_invalid_message"),
                type: "error",
            });
        }
    };

    useEffect(() => {
        if (
            !onBadgeRoute &&
            (isNotValidValue(paramToken) || (isNotValidValue(inputToken) && !isNotValidValue(storeToken)))
        )
            localStorage.removeItem("token");
        if (paramToken !== "") verifyToken({ removeAllAccessToken: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { inputToken, setInputToken, verifyToken, isVerificationAllowed };
};

/**
 * Hook to get the check if the auto verification parameter is present in the URL.

 * @returns {boolean} A boolean indicating whether the auto verification parameter is present in the URL.
 */
export const useGetAutoVerificationParam = (): boolean => {
    const [param] = useSearchParams();
    const isAutoVerification = param.get("auto_verification") !== undefined;
    return isAutoVerification;
};

/**
 * Hook to hit the automated verification.

 * @param {Function} navigate - A hook to navigate.
 * @param {{ orga_slug: string; certificate_slug: string }} useGetOrgaSlugs - A hook to get the organization and certificate slugs.
 * @param {Record<"linkedin" | "facebook" | "email", boolean>} haveAccessToken - A boolean indicating whether the user has an access token.
 * @param {Function} redirectToVerification - A function to redirect to the verification page.
 * @returns {{ haveAccessToken: Record<"linkedin" | "facebook" | "email", boolean>; redirectToVerification: Function }} An object containing a boolean indicating whether the user has an access token and a function to redirect to the download page where the verification happens.
 */
export const useHitAccess = (): {
    haveAccessToken: Record<"linkedin" | "facebook" | "email", boolean>;
    redirectToVerification: (token: string, type: "linkedin" | "facebook" | "email") => void;
} => {
    const navigate = useNavigate();
    const { orga_slug, certificate_slug } = useGetOrgaSlugs();

    const isSingleCertificate = useAppSelector(certificatesSelectorService.isSingleCertificate);

    const linkedin_access_token = localStorage.getItem("linkedin_access_token");
    const facebook_access_token = localStorage.getItem("facebook_access_token");
    const email_access_token = localStorage.getItem("email_access_token");

    const haveAccessToken = useMemo(
        () => ({
            linkedin: isSingleCertificate && linkedin_access_token !== null,
            facebook: isSingleCertificate && facebook_access_token !== null,
            email: email_access_token !== null,
        }),
        [isSingleCertificate, linkedin_access_token, facebook_access_token, email_access_token]
    );

    const redirectToVerification = useCallback(
        (token: string, type: "linkedin" | "facebook" | "email") => {
            cleanupLocalStorageFromUnusedAccessTokens(type);
            navigate(
                `/${orga_slug}/${certificate_slug}/badge?token=${token}${
                    haveAccessToken[type] ? "&auto_verification=true" : ""
                }`
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [certificate_slug, navigate, orga_slug]
    );

    return { haveAccessToken, redirectToVerification };
};

/**
 * Check if the user came from a shared redirection.c

 * @returns {{ selectedCertificate: string; comesFromSharedRedirection: boolean }} An object containing the selected certificate and a boolean indicating whether the user came from a shared redirection.
 */
const comeFromSharedRedirect = (): { selectedCertificate: string; comesFromSharedRedirection: boolean } => {
    const selectedCertificate = cloneDeep(localStorage.getItem("last_viewed_certificate")) || "";
    const comesFromSharedRedirection = localStorage.getItem("last_viewed_certificate") !== null;
    return { selectedCertificate, comesFromSharedRedirection };
};

/**
 * Hook to handle the post-validation process.

 * @param {Function} navigate - A hook to navigate.
 * @param {Function} pushSnack - A hook to push snackbars.
 * @param {Function} dispatch - A hook to dispatch actions to the store.
 * @param {{ orga_slug: string; certificate_slug: string }} useGetOrgaSlugs - A hook to get the organization and certificate slugs.
 * @param {{ inputToken: string; setInputToken: Function; verifyToken: Function; isVerificationAllowed: boolean }} useValidateToken - A hook to validate the token.
 * @param {boolean} isFulfilledToken - A boolean indicating whether the token is fulfilled.
 * @param {boolean} certificatesFetched - A boolean indicating whether the certificates are fetched.
 * @param {boolean} isSuccess - A boolean indicating whether the certificate is successful.
 * @param {string} userEmail - The user's email.
 * @param {string} token - The user's token.
 * @param {boolean} isSingleCertificate - A boolean indicating whether there is only one certificate.
 * @param {Object} certificateProps - The certificate props.
 * @param {Object} certificates - The certificates.
 * @param {string} verificationMethod - The verification method.
 * @param {Object} linkedinProfile - The user's LinkedIn profile.
 * @param {Object} facebookProfile - The user's Facebook profile.
 * @param {string} linkedin_access_token - The user's LinkedIn access token.
 * @param {string} facebook_access_token - The user's Facebook access token.
 * @param {string} email_access_token - The user's email access token.
 * @param {string} severity - The token validation response severity.
 * @param {boolean} isSuccessToken - A boolean indicating whether the token is successful.
 * @param {boolean} isVerified - A boolean indicating whether the user is verified.
 * @param {string} validationRoute - The validation route.
 * @param {Function} getSocialMediaType - A function to get the social media type.
 * @param {Function} getSocialMediaProfile - A function to get the social media profile.
 * @param {Function} getSocialMediaAccessToken - A function to get the social media access token.
 * @param {Function} socialMediaProfile - A function to get the social media profile.
 * @param {boolean} hasSocialMediaAccessToken - A boolean indicating whether the user has a social media access token.
 * @param {boolean} hasEmailAccessToken - A boolean indicating whether the user has an email access token.
 * @param {boolean} validateWithEmail - A boolean indicating whether the email should be validated.
 * @param {boolean} validateWithSocialMedia - A boolean indicating whether the social media should be validated.
 * @param {boolean} redirectToVerification - A boolean indicating whether the user should be redirected to the verification page.
 * @param {Function} assignRecipientName - A function to assign the recipient name.
 * @param {Function} validateEmail - A function to validate the email.
 * @param {Function} validateSocialMedia - A function to validate the social media.
 * @returns {{ validate: boolean; setValidate: Dispatch<SetStateAction<boolean>> }} A function to set the validate state.
 */
export const usePostValidation = (): { validate: boolean; setValidate: Dispatch<SetStateAction<boolean>> } => {
    const loading = useLoadingThunk();
    const t = useTranslation();
    const pushSnack = usePushSnack();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const onBadgeRoute = isOnBadgeRoute();
    const [searchParams] = useSearchParams();
    const { orga_slug, certificate_slug } = useGetOrgaSlugs();
    const { verifyToken: validateToken } = useValidateToken();

    const [validate, setValidate] = useState(false);

    const validationTokenResponse = useAppSelector(controlSelectorService.selectTokenValidation);
    const isSingleCertificate = useAppSelector(certificatesSelectorService.isSingleCertificate);
    const { linkedin: linkedinProfile, facebook: facebookProfile } = useAppSelector(
        controlSelectorService.selectSocialMediaProfiles
    );
    const certificates = useAppSelector(certificatesSelectorService.selectCertificates);
    const verificationMethod = useAppSelector(controlSelectorService.selectVerificationMethod);
    const token = useAppSelector(controlSelectorService.selectToken);
    const userEmail = useAppSelector(controlSelectorService.selectUserEmail);
    const newFoundCertificates = useAppSelector(controlSelectorService.selectNewFoundCertificates);
    const isFulfilledToken = loading.isFulfilled("token");
    const certificatesFetched = loading.isFulfilled("certificates");

    const paramComesFromSocialMedia = useMemo(
        () => searchParams.get("comes_from_social_media") !== undefined,
        [searchParams]
    );
    // Custom hook for certificate props should not get used here because of dependency cycling
    const {
        refetch,
        isSuccess,
        isLoading,
        isFetching,
        data: certificateProps,
    } = useGetCertificatePropsQuery({ orga_slug, certificate_slug }, { skip: !orga_slug && !certificate_slug });
    const {
        refetch: refetchEventDetails,
        isSuccess: isSuccessEventDetails,
        data: eventData,
    } = useGetCertificateDetailsQuery({ token }, { skip: !token });

    const linkedin_access_token = localStorage.getItem("linkedin_access_token");
    const facebook_access_token = localStorage.getItem("facebook_access_token");
    const email_access_token = localStorage.getItem("email_access_token");

    const { severity } = validationTokenResponse;
    const isSuccessToken = useMemo(() => severity === "success", [severity]);

    const isEmailVerified = useMemo(() => verificationMethod === EAuthButton.EMAIL, [verificationMethod]);
    const isSocialMediaVerified = useMemo(() => verificationMethod === EAuthButton.SOCIAL_MEDIA, [verificationMethod]);
    const isVerified = useMemo(() => verificationMethod !== null, [verificationMethod]);

    const validationRoute = useMemo(() => `/${orga_slug}/${certificate_slug}`, [certificate_slug, orga_slug]);

    const getSocialMediaType = useMemo(() => {
        if (linkedin_access_token) return "linkedin";
        if (facebook_access_token) return "facebook";
        return null;
    }, [facebook_access_token, linkedin_access_token]);

    const getSocialMediaProfile = useCallback(
        (type: typeof getSocialMediaType, profile?: ILinkedinProfile | IFacebookResponse) => {
            switch (type) {
                case "linkedin":
                    return { linkedin: profile || linkedinProfile };
                case "facebook":
                    return { facebook: profile || facebookProfile };
                default:
                    return null;
            }
        },
        [facebookProfile, linkedinProfile]
    );

    const getSocialMediaAccessToken = useCallback(
        (type: typeof getSocialMediaType) => {
            switch (type) {
                case "linkedin":
                    return linkedin_access_token;
                case "facebook":
                    return facebook_access_token;
                default:
                    return null;
            }
        },
        [facebook_access_token, linkedin_access_token]
    );

    const socialMediaProfile = useCallback(
        (profile?: ILinkedinProfile | IFacebookResponse) => ({
            socialMedia: getSocialMediaType,
            token: getSocialMediaAccessToken(getSocialMediaType),
            profile: getSocialMediaProfile(getSocialMediaType, profile),
        }),
        [getSocialMediaAccessToken, getSocialMediaProfile, getSocialMediaType]
    );

    const forTypeOfProfile = socialMediaProfile();

    const hasSocialMediaAccessToken = useMemo(() => getSocialMediaType !== null, [getSocialMediaType]);

    const hasEmailAccessToken = useMemo(() => email_access_token !== null, [email_access_token]);

    const validateWithEmail = useMemo(
        () => isEmailVerified && isFulfilledToken && isSuccessToken && certificatesFetched && hasEmailAccessToken,
        [certificatesFetched, hasEmailAccessToken, isFulfilledToken, isSuccessToken, isEmailVerified]
    );

    const validateWithSocialMedia = useMemo(
        () =>
            isSocialMediaVerified &&
            isFulfilledToken &&
            isSuccessToken &&
            certificatesFetched &&
            hasSocialMediaAccessToken &&
            isSingleCertificate,
        [
            isSocialMediaVerified,
            isFulfilledToken,
            isSuccessToken,
            certificatesFetched,
            hasSocialMediaAccessToken,
            isSingleCertificate,
        ]
    );

    const redirectToVerification = useMemo(
        () => !isVerified && !validateWithSocialMedia && !validateWithEmail && onBadgeRoute,
        [isVerified, onBadgeRoute, validateWithEmail, validateWithSocialMedia]
    );

    const startProcessForNewCertificates = () => {
        if (newFoundCertificates.length > 0) {
            dispatch(badgeActionService.chunkBadgeBaking());
        } else {
            dispatch(sideActionsService.setLoadingState({ type: "propsMapped", state: true }));
            dispatch(sideActionsService.setLoadingState({ type: "badgesBaked", state: true }));
        }
    };

    const validateEmail = async () => {
        if (certificatesFetched && isSuccess && userEmail) {
            const hashedEmail = email_access_token;
            const checkValue = await getHash(userEmail);
            if (hashedEmail === checkValue) {
                dispatch(sideActionsService.setVerifyMethod(EAuthButton.EMAIL));
                startProcessForNewCertificates();
                return;
            }
            localStorage.removeItem("email_access_token");
            navigate(validationRoute);
        }
    };

    const attachSocialMedia = async (
        badge_props_type: EBadgeType,
        badgeProps: VbDesigner.IBadgeProps | PolotnoDesigner.PolotnoBadgePropsData | undefined,
        certificate: Data,
        data: IBadgesResponse,
        profile: typeof forTypeOfProfile
    ) => {
        let badge_props = cloneDeep(badgeProps);
        if (badge_props_type === "badge" && isTypeOf<VbDesigner.IBadgeProps>(badge_props)) {
            if (!badge_props) {
                pushSnack({
                    title: t("common.alerts.messages.error_deserializing_badgeprops_title"),
                    body: t("common.alerts.messages.error_deserializing_badgeprops_body"),
                    type: "error",
                });
                navigate(validationRoute);
                return;
            }
            const badgeEngine = new BadgeEngine(data.format, "RECIPIENT_VIEW");
            badge_props = (await deserializeBadgeProps(badge_props, data.format)) as VbDesigner.IBadgeProps;
            badge_props = await badgeEngine.setCustomFieldsAndNameValues(
                certificate.badge_props as VbDesigner.IBadgeProps,
                certificate.field_mapping,
                certificate.validation_url,
                certificate.certificate_id
            );
            const profilePic = findRecipientImageInBadgeProps(certificate.badge_props as VbDesigner.IBadgeProps);
            if (profilePic?.disabled === false)
                badge_props = await setProfilePictureInBadgeProps({
                    ...profile,
                    ...data,
                    badgeprops: badge_props,
                });
        }
        if (
            badge_props_type === "polotno" &&
            isTypeOf<PolotnoDesigner.PolotnoBadgePropsData>(badge_props) &&
            profile.profile
        ) {
            badge_props = await setDynamicFields(
                certificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData,
                certificate.field_mapping,
                certificate.validation_url,
                certificate.certificate_id,
                certificate.issue_date || eventData?.issue_date || "",
                certificate.expiration_date || eventData?.expiration_date || ""
            );
            const profilePic = findRecipientImageInPolotnoProps(
                certificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData
            );
            if (profilePic?.visible === true)
                badge_props = await setProfilePictureInPolotnoProps(
                    profile.profile as ICertificatePropsResult,
                    badge_props
                );
        }

        const socialMediaAttachedBadgeProps: Data = {
            ...certificate,
            badge_props,
            badge_props_type,
        };
        const slugs = { orga_slug, certificate_slug };

        dispatch(sideActionsService.setVerifyMethod(EAuthButton.SOCIAL_MEDIA));
        dispatch(
            badgeActionService.attachFieldMappingToBadge({
                slugs,
                data: [socialMediaAttachedBadgeProps],
                forceBaking: true,
            })
        );
    };

    const validateSocialMedia = async () => {
        if (certificatesFetched && isSuccess) {
            const data: IBadgesResponse = cloneDeep(certificateProps);
            const certificate = cloneDeep(certificates.data[0]);

            // Because of some strage behaver, we need to assign the certificate props additional to the token validation thunk.
            const badge_props_type = usedProps(data);
            const badge_props = getPropsFromPropsType(data) || undefined;

            let profile = socialMediaProfile();

            // If authenticated with linkedin or facebook, load profile data into badge
            if (!(profile.profile?.linkedin || profile.profile?.facebook) && profile.token !== null) {
                if (profile.socialMedia === "linkedin") {
                    await axios
                        .get(CertificateDownloaderApiRoutes.getLinkedInAccessToken(profile.token as string))
                        .then(async (result) => {
                            if (result.data.status === 401) {
                                localStorage.removeItem("linkedin_access_token");
                                navigate(validationRoute);
                                throw Error("Invalid verification!");
                            } else profile = await socialMediaProfile(result.data);
                            attachSocialMedia(badge_props_type, badge_props, certificate, data, profile);
                        })
                        .catch((e: AxiosError) => {
                            localStorage.removeItem("linkedin_access_token");
                            navigate(validationRoute);
                            pushSnack({
                                title: t("common.alerts.messages.unexpected_error_title"),
                                body: e.message,
                                type: "error",
                            });
                        });
                } else if (profile.socialMedia === "facebook") {
                    // Get profile picture
                    await axios
                        .get(CertificateDownloaderApiRoutes.getFacebookAccessToken(profile.token as string))
                        .then(async (facebookResponse: AxiosResponse<IFacebookResponse>) => {
                            axios
                                .get<{
                                    data: {
                                        height: number;
                                        is_silhouette: boolean;
                                        url: string;
                                        width: number;
                                    };
                                }>(CertificateDownloaderApiRoutes.getFacebookProfilePicture(profile.token as string))
                                .then(async (picResponse) => {
                                    // @ts-ignore
                                    profile = await socialMediaProfile({
                                        name: facebookResponse.data.name,
                                        picture: picResponse.data.data.url,
                                    });
                                    attachSocialMedia(badge_props_type, badge_props, certificate, data, profile);
                                });
                        })
                        .catch((e: AxiosError) => {
                            localStorage.removeItem("facebook_access_token");
                            navigate(validationRoute);
                            pushSnack({
                                title: t("common.alerts.messages.unexpected_error_title"),
                                body: e.message,
                                type: "error",
                            });
                        });
                }
            }
        }
    };

    /** This useEffect hook is used to handle the post-validation process and ensure that the user's verification settings are properly enforced. */
    useEffect(() => {
        // It is important that this console.table staies for debugging.
        // eslint-disable-next-line no-console
        console.table({
            comesFromSharedRedirection: comeFromSharedRedirect().comesFromSharedRedirection,
            validate,
            refetchPropsNotSuccessful: !isSuccess,
            refetchPropsNotFetchingOrInitialLoaded: !isLoading || !isFetching,
            validateToken: isSuccess && !(isFulfilledToken && isSuccessToken && certificatesFetched),
            setCertificateToLastViewed: comeFromSharedRedirect().comesFromSharedRedirection,
            refetchEventDetails: isFulfilledToken && !isSuccessEventDetails,
            validateWithSocialMedia,
            validateWithEmail,
            redirectToVerification,
        });
        if (paramComesFromSocialMedia) {
            if (hasEmailAccessToken) dispatch(sideActionsService.setVerifyMethod(EAuthButton.EMAIL));
            else dispatch(sideActionsService.setVerifyMethod(EAuthButton.SOCIAL_MEDIA));
        }
        if (validate) {
            if (!isSuccess) {
                if (!isLoading || !isFetching) refetch();
            } else if (isSuccess && !(isFulfilledToken && isSuccessToken && certificatesFetched)) validateToken();
            else if (isFulfilledToken && isSuccessToken && certificatesFetched && !isSuccessEventDetails)
                refetchEventDetails();
            else if (comeFromSharedRedirect().comesFromSharedRedirection)
                dispatch(sideActionsService.fromSharedRedirection(comeFromSharedRedirect().selectedCertificate));
            else if (validateWithSocialMedia) validateSocialMedia();
            else if (validateWithEmail) validateEmail();
            else if (redirectToVerification) navigate(validationRoute);
            else setValidate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        validate,
        isSuccess,
        certificatesFetched,
        onBadgeRoute,
        isFulfilledToken,
        isSuccessToken,
        redirectToVerification,
        validateWithEmail,
        validateWithSocialMedia,
    ]);

    return { validate, setValidate };
};
