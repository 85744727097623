import walletButtons from "./walletButton.constants";
import type { TLanguage, TWalletButtonType } from "./walletButton.types";

const getWalletButton = (type: TWalletButtonType, language: TLanguage): string => {
    const buttonSVG = walletButtons[type][language];

    return buttonSVG;
};

export default getWalletButton;
