import { useEffect } from "react";

import useTranslation from "i18n/hooks/useTranslation";

import { Stack } from "@mui/material";

import AddTwitterButton from "components/AddTwitterButton";
import FormDialog from "components/shared/Modal/Modal";
import TextField from "components/shared/TextField";
import Typography from "components/shared/Typography";

import { useGetCurrentSelectedCertificate } from "../../../../redux/hooks/badge";

import { useShareTwitterModal } from "./ShareTwitterModal.hooks";
import * as styles from "./ShareTwitterModal.styles";

const ShareTwitterModal: React.FC<IShareTwitterModalProps> = ({ dialogOpen, handleDialogClose }) => {
    const t = useTranslation();
    const certificate = useGetCurrentSelectedCertificate();

    const { initialValues, validationSchema, handleDialogSubmit, loadingState, handleCancel } = useShareTwitterModal();

    useEffect(() => {
        if (loadingState().successfull) {
            handleDialogClose();
            handleCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingState]);

    return (
        <FormDialog
            dialogOpen={dialogOpen}
            dialogTitleText={t("certificate_download.share_certificate.linkedin_modal.title")}
            dialogOkText={t("Save")}
            CustomSaveButton={
                <AddTwitterButton
                    disabled={loadingState().pending}
                    sx={styles.shareButton}
                    label={t("certificate_download.share_certificate.twitter_modal.share_to_twitter")}
                />
            }
            initialValues={initialValues}
            validationSchema={validationSchema}
            onClose={handleDialogClose}
            onSubmit={handleDialogSubmit}
        >
            <Stack gap={4}>
                {certificate.badge_image_url && (
                    <img src={certificate.badge_image_url} style={styles.certificateImage} alt="certificate preview" />
                )}
                <div>
                    <TextField
                        fullWidth
                        label={t("certificate_download.share_certificate.linkedin_modal.post_description_label")}
                        multiline
                        rows={3}
                        variant="outlined"
                        name="twitterPostDescription"
                    />
                    {initialValues.postTags && (
                        <Typography gutterBottom={false} weight="meduim" mt={2}>
                            {initialValues.postTags}
                        </Typography>
                    )}
                </div>
            </Stack>
        </FormDialog>
    );
};

export default ShareTwitterModal;
