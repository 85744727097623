import type { FC } from "react";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box } from "@mui/material";

import Button from "components/shared/Button";

import * as styles from "./AddWhatsappButton.styles";

const AddWhatsappButton: FC<IButtonProps> = ({ ...props }) => {
    return (
        <Box>
            <Button
                variant="contained"
                startIcon={<WhatsAppIcon sx={{ height: 24, width: 24 }} />}
                sx={{
                    ...styles.whatsappButton,
                    "& .MuiButton-startIcon": {
                        marginRight: 0,
                    },
                    minWidth: "fit-content",
                    height: "100%",
                }}
                {...props}
            />
        </Box>
    );
};

export default AddWhatsappButton;
