import { useCallback, useEffect, useMemo } from "react";
import { enqueueSnackbar } from "notistack";

import notificationSelectorService from "../../../redux/selector/notification";
import { removeNotification } from "../../../redux/slices/notification";
import { useAppDispatch, useAppSelector } from "../../../redux/store";

/**
 * Hook to queue notistack notification from redux store
 */
const useNotification = () => {
    const dispatch = useAppDispatch();

    const currentNotification = useAppSelector(notificationSelectorService.selectCurrentNotification);

    const shownNotification = useMemo(() => currentNotification, [currentNotification]);

    const clearNotification = useCallback(() => {
        dispatch(removeNotification());
    }, [dispatch]);

    useEffect(() => {
        const showNotification = () => {
            if (shownNotification) {
                const { message, variant, options = {} } = shownNotification;
                enqueueSnackbar(message, {
                    variant,
                    ...options,
                });
            }
        };

        showNotification();
    }, [shownNotification, clearNotification]);
};

export default useNotification;
