import React from "react";

import useTranslation from "i18n/hooks/useTranslation";
import { socialMediaButtons } from "utils/commonStyles";
import { theme } from "utils/Theme/theme";

import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import { Box } from "@mui/material";

import Button from "components/shared/Button";

import * as styles from "./AddFacebookButton.styles";

const AddFacebookButton: React.FC<IAddFacebookButtonProps> = ({ showLabel = true, label, ...props }) => {
    const t = useTranslation();
    const buttonContainerStyle = React.useMemo(() => socialMediaButtons(showLabel), [showLabel]);

    return (
        <Box sx={buttonContainerStyle}>
            <Button
                variant="contained"
                startIcon={<FacebookRoundedIcon sx={{ height: 24, width: 24 }} />}
                sx={{
                    ...styles.facebookButton,
                    "& .MuiButton-startIcon": {
                        marginRight: showLabel ? theme.spacing(1) : 0,
                    },
                    minWidth: showLabel ? 64 : "fit-content",
                    height: "100%",
                }}
                {...props}
            >
                {showLabel && (label || t("certificate.authForm.form.button_facebook.text"))}
            </Button>
        </Box>
    );
};

export default AddFacebookButton;
