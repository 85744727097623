import type { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from "react";
import styled from "@emotion/styled";

import { theme } from "../../../utils/Theme/theme";

type TStyledInfoBox = {
    variant?: "yellow" | "green";
};

type TInfoBoxStyled = {
    variant: string;
    borderLeftColor: string;
    fontColor: string;
};

const InfoBoxStyled = styled.div<TInfoBoxStyled>(({ variant, borderLeftColor, fontColor, style }) => ({
    backgroundColor: variant,
    borderWidth: "0px",
    borderLeft: `4px solid ${borderLeftColor}`,
    padding: "8px 12px",
    color: fontColor,
    fontSize: 14,
    width: "fit-content",
    ...style,
}));

/**
 * InfoBox component for displaying important information.
 *
 * @argument {"yellow" | "green"} variant - The color variant of the InfoBox.
 * @argument {ReactNode} children - The content of the InfoBox.
 * @argument {HTMLDivElement} props - Any additional props to be passed to the InfoBox element from a div.
 */
const StyledInfoBox: FC<
    PropsWithChildren<TStyledInfoBox> & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ variant, children, ...props }) => {
    const getVariant = (): TInfoBoxStyled => {
        switch (variant) {
            case "yellow":
                return {
                    variant: "#FFF8D6",
                    borderLeftColor: "#FFD200",
                    fontColor: theme.palette.grey[700],
                };
            case "green":
                return {
                    variant: "#D7EFD8",
                    borderLeftColor: "#388E3C",
                    fontColor: theme.palette.grey[700],
                };
            default:
                return {
                    variant: "#D6EEFF",
                    borderLeftColor: "#1B305A",
                    fontColor: theme.palette.grey[700],
                };
        }
    };

    return (
        <InfoBoxStyled {...props} {...getVariant()}>
            {children}
        </InfoBoxStyled>
    );
};

export default StyledInfoBox;
