import { EEventPdfType } from "../types/enums";

/**
 * Helper to check backend-pdf for multiple certificates/recipients
 * @param eventPdfType Type of the selected pdf-creating-type
 * @param recipients Array of recipients
 * @returns Object with callable boolean values
 */
const checkAllRecipients = (eventPdfType: EEventPdfType | undefined, recipients: Data[]) => ({
    isBrowser: eventPdfType === EEventPdfType.BROWSER,
    isBackend: eventPdfType === EEventPdfType.BACKEND,
    areGenerated: recipients.every((recipient) => recipient.badge_image_url !== null),
    notGenerated: recipients.every((recipient) => recipient.badge_image_url === null),
    allRecipientHasPdfUrl: recipients.every((recipient) => recipient.pdf_blob_url !== null),
    noRecipientHasPdfUrl: recipients.every((recipient) => recipient.pdf_blob_url === null),
    minimumOneRecipientDoNotHasPdfUrl: recipients.some((recipient) => recipient.pdf_blob_url === null),
});

/**
 * Helper to check backend-pdf for single certificate/recipient
 * @param eventPdfType Type of the selected pdf-creating-type
 * @param recipients Object of single recipient
 * @returns Object with callable boolean values
 */
const checkSingleRecipient = (eventPdfType: EEventPdfType | undefined, recipient: Data) => ({
    isBrowserPdf: eventPdfType === EEventPdfType.BROWSER,
    isBackendPdf: eventPdfType === EEventPdfType.BACKEND,
    isGenerated: recipient?.badge_image_url !== null,
    recipientHasPdfUrl: recipient?.pdf_blob_url !== null,
});

/**
 * Helper functions to check downloadable backend PDFs.
 */
const backendPdfDownloadHelper = {
    checkAllRecipients,
    checkSingleRecipient,
};

export default backendPdfDownloadHelper;
