import React from "react";
import { Outlet } from "react-router-dom";

import { Box, Container, Grid } from "@mui/material";

import NavBar from "components/NavBar";

import * as styles from "./AppLayout.styles";

const AppLayout: React.FC = () => {
    return (
        <Box sx={styles.root}>
            <Box sx={styles.nav}>
                <NavBar />
            </Box>
            <Container sx={styles.container} fixed>
                <Grid container sx={styles.wrapper}>
                    <Outlet />
                </Grid>
            </Container>
        </Box>
    );
};

export default AppLayout;
