import useDeviceBreakpoint from "utils/useDeviceBreakpoint/useDeviceBreakpoint";

import { Stack } from "@mui/material";

import Spinner from "components/Spinner";

import { useGetCertificateProps } from "../../../../redux/hooks/queryHooks";
import eventSelectorService from "../../../../redux/selector/events";
import { useAppSelector } from "../../../../redux/store";
import AuthForm from "../AuthForm";
import CertificateValidatorPreview from "../CertificateValidatorPreview";

const CertificateValidatorContainer = () => {
    const { isLoading } = useGetCertificateProps();
    const eventIsFetched = useAppSelector(eventSelectorService.eventIsFetched);

    const { isTablet } = useDeviceBreakpoint();

    return (
        <>
            {isLoading || !eventIsFetched ? (
                <Spinner />
            ) : (
                <Stack
                    direction={{
                        xs: "column",
                        md: "row",
                    }}
                    flex="1"
                    justifyContent="center"
                >
                    {isTablet && <CertificateValidatorPreview />}
                    <Stack minWidth={{ xs: "100%", lg: 706, md: 500 }} justifyContent="center">
                        <AuthForm />
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default CertificateValidatorContainer;
