import type { FC } from "react";

import type { TAttachSpecialCharactersValue } from "./attachSpecialCharacter.types";

interface Props {
    character: TAttachSpecialCharactersValue;
}

const AttachSpecialCharacter: FC<Props> = ({ character }) => {
    switch (character) {
        case "trademark":
            return <>&#8482;</>;
        default:
            console.info(`Character ${character} is not defined`);
            return <div />;
    }
};

export default AttachSpecialCharacter;
