import certificatesSelectorService from "../selector/certificates";
import { useAppSelector } from "../store";

/**
 * Helper hook to get the current selected certificate.
 * @returns The current selected certificate
 */
export const useGetCurrentSelectedCertificate = () => {
    const certificates = useAppSelector(certificatesSelectorService.selectedCertificate);
    return certificates;
};
