import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import Typography from "../../../components/shared/Typography";
import { LIGHT_GREY, theme } from "../../../utils/Theme/theme";

export const StyledNotFoundContainer = styled(Box)(() => ({
    border: `1px solid ${LIGHT_GREY}`,
    borderTop: `16px solid ${theme.palette.warning.main}`,
    padding: "100px 80px 80px 80px",
    width: "60%",
    margin: "auto",
    overflow: "hidden",
    [theme.breakpoints.down("lg")]: {
        width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxHeight: "100%",
        padding: "50px",
    },
}));

export const StyledTopicText = styled(Typography)(() => ({
    fontSize: "32px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "45px",
    [theme.breakpoints.down("lg")]: {
        fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
    },
}));
export const StyledMessageText = styled(Typography)(() => ({
    fontSize: "26px",
    textAlign: "center",
    marginBottom: "100px",
    [theme.breakpoints.down("lg")]: {
        fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
    },
}));
export const StyledBottomMessageText = styled(Typography)(() => ({
    fontSize: "18px",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
        fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
    },
}));
