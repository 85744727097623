const A4LandscapePreview: React.FC<{ img: string; disabled: boolean }> = ({ img, disabled }) => (
    <svg width="100%" height="100%" viewBox="0 0 647 467" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9857 450.744V466.807L0 466.807L16.9857 450.744ZM16.986 466.808V450.744L630.015 450.744L647 466.807H630.014L630.015 466.808L16.986 466.808Z"
            fill="url(#paint0_linear_4074_1669)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.986 450.744H0.000310926L0.000293255 466.808L16.986 450.744ZM0 450.744H16.9857L16.9857 16.0638H16.986L16.9857 16.063H16.9851L0.000293255 0.000518799L0.000293255 16.063H0L0 450.744Z"
            fill="url(#paint1_linear_4074_1669)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M630.014 16.0637V0.000521543L647 0.000504831L630.014 16.0637ZM630.014 0.000488281V16.0637L16.9855 16.0637L0 0.000504831L16.9855 0.000488281L630.014 0.000488281Z"
            fill="url(#paint2_linear_4074_1669)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M630.014 16.0637L647 16.0637L647 0.000488281L630.014 16.0637ZM647 16.0637L630.014 16.0637L630.014 450.744H630.014L630.014 450.745L647 466.808V450.745H647L647 16.0637Z"
            fill="url(#paint3_linear_4074_1669)"
        />
        <image
            xlinkHref={img}
            x="0"
            y="15"
            width="100%"
            height="calc(100% - 30px)"
            style={{ filter: disabled ? "grayscale(1) contrast(0.2) brightness(1.9)" : undefined }}
        />
        <defs>
            <linearGradient
                id="paint0_linear_4074_1669"
                x1="134.341"
                y1="466.808"
                x2="134.341"
                y2="450.744"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_4074_1669"
                x1="0"
                y1="339.762"
                x2="16.9861"
                y2="339.762"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_4074_1669"
                x1="512.659"
                y1="0.000488281"
                x2="512.659"
                y2="16.0637"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_4074_1669"
                x1="647"
                y1="127.046"
                x2="630.014"
                y2="127.046"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
        </defs>
    </svg>
);
export default A4LandscapePreview;
