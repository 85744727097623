/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/lines-between-class-members */
// QR Code
// Image sharpening
import { cloneDeep, isEmpty } from "lodash";
import pica from "pica";
import QRCode from "qrcode-svg";

// eslint-disable-next-line import/no-cycle
import { getHorizontalTextAlignmentOffset } from "./BadgeEngine.helpers";

type UsageForm = "ADMIN_PANEL" | "RECIPIENT_VIEW";
type CanvasFormat = { width: number; height: number };

// * Note: This file is copy pasted from the frontend-user since it will be removed when we move the logic to the BE
// Todo: remove this file since we will create the certificate from the backend

export default class BadgeEngine {
    // Format constants
    private readonly US_LETTER_ASPECT_RATIO = 22 / 17;
    private readonly A4_ASPECT_RATIO = Math.sqrt(2);
    private readonly A4_SHORT_SIDE = 357;
    private readonly A4_LONG_SIDE = this.A4_SHORT_SIDE * this.A4_ASPECT_RATIO;
    private readonly US_LETTER_SHORT_SIDE = this.A4_SHORT_SIDE;
    private readonly US_LETTER_LONG_SIDE = this.US_LETTER_SHORT_SIDE * this.US_LETTER_ASPECT_RATIO;
    private readonly US_LETTER_PORTRAIT = { width: this.US_LETTER_SHORT_SIDE, height: this.US_LETTER_LONG_SIDE };
    private readonly US_LETTER_LANDSCAPE = { width: this.US_LETTER_LONG_SIDE, height: this.US_LETTER_SHORT_SIDE };
    private readonly CUSTOM_VB_FORMAT = { width: 357, height: 476 }; // Legacy format
    private readonly A4_FORMAT_LANDSCAPE = { width: this.A4_LONG_SIDE, height: this.A4_SHORT_SIDE };
    private readonly A4_FORMAT_PORTRAIT = { width: this.A4_SHORT_SIDE, height: this.A4_LONG_SIDE };
    private readonly BADGE_1_TO_1 = { width: this.A4_SHORT_SIDE, height: this.A4_SHORT_SIDE };

    // Layout constants
    private readonly ICON_SIZE = 20;
    private readonly ICON_SPACING = 5;
    private readonly SELECTION_OUTLINE_MARGIN = 20;

    // Icons
    private readonly resizeIcon = new Image();
    private readonly deleteIcon = new Image();
    private readonly duplicateIcon = new Image();
    private readonly canvasGrid = new Image();

    CANVAS_FORMAT: CanvasFormat;
    CANVAS_WIDTH: number;
    CANVAS_HEIGHT: number;
    MEASURE_CANVAS: HTMLCanvasElement;
    MEASURE_CTX: CanvasRenderingContext2D;
    USER_PANEL: boolean;
    ADMIN_PANEL: boolean;

    constructor(format: VbDesigner.BadgeFormat, usageForm: UsageForm = "ADMIN_PANEL") {
        let setCanvasGrid = false;
        switch (usageForm) {
            case "RECIPIENT_VIEW":
                this.ADMIN_PANEL = false;
                this.USER_PANEL = true;
                break;
            case "ADMIN_PANEL":
            default:
                this.ADMIN_PANEL = true;
                this.USER_PANEL = false;
                this.resizeIcon.src = "/assets/img/badge-creator/resize.png";
                this.deleteIcon.src = "/assets/img/badge-creator/delete.png";
                this.duplicateIcon.src = "/assets/img/badge-creator/duplicate.png";
                setCanvasGrid = true;
                break;
        }

        switch (format) {
            case "LEGACY_BADGE_3_TO_4":
                this.CANVAS_FORMAT = this.CUSTOM_VB_FORMAT;
                if (setCanvasGrid) this.canvasGrid.src = "/assets/img/badge-creator/canvas-grid-legacy.svg";
                break;
            case "BADGE_1_TO_1":
                this.CANVAS_FORMAT = this.BADGE_1_TO_1;
                if (setCanvasGrid) this.canvasGrid.src = "/assets/img/badge-creator/canvas-grid-square.svg";
                break;
            case "US_LETTER_LANDSCAPE":
                this.CANVAS_FORMAT = this.US_LETTER_LANDSCAPE;
                if (setCanvasGrid) this.canvasGrid.src = "/assets/img/badge-creator/canvas-grid-letter-landscape.svg";
                break;
            case "US_LETTER_PORTRAIT":
                this.CANVAS_FORMAT = this.US_LETTER_PORTRAIT;
                if (setCanvasGrid) this.canvasGrid.src = "/assets/img/badge-creator/canvas-grid-letter-portrait.svg";
                break;
            case "A4_LANDSCAPE":
                this.CANVAS_FORMAT = this.A4_FORMAT_LANDSCAPE;
                if (setCanvasGrid) this.canvasGrid.src = "/assets/img/badge-creator/canvas-grid-a4-landscape.svg";
                break;
            case "A4_PORTRAIT":
            default:
                this.CANVAS_FORMAT = this.A4_FORMAT_PORTRAIT;
                if (setCanvasGrid) this.canvasGrid.src = "/assets/img/badge-creator/canvas-grid-a4-portrait.svg";
                break;
        }

        this.CANVAS_WIDTH = this.CANVAS_FORMAT.width;
        this.CANVAS_HEIGHT = this.CANVAS_FORMAT.height;

        this.MEASURE_CANVAS = document.createElement("canvas");
        this.MEASURE_CTX = this.MEASURE_CANVAS.getContext("2d") as CanvasRenderingContext2D;
    }

    public async drawObject(
        ctx: CanvasRenderingContext2D,
        obj: VbDesigner.BadgeObject,
        state: VbDesigner.CanvasState,
        scale = 1,
        picaResize = true
    ) {
        try {
            if (obj.disabled) return;
            if (this.ADMIN_PANEL) picaResize = false;

            const isDragging = state.isDragging;
            const selectedElementId = state.selectedElement;

            if (["textinput", "dropdown", "text", "custommappingfield"].includes(obj.type))
                this.updateTextDimensions(obj);

            // Get position for object
            let posX = obj.posX * scale;
            let posY = obj.posY * scale;

            // Checks for toggled alignments (lock axis and draw indication line)
            if (obj.alignment.includes("horizontal")) {
                // Center object horizontally
                posX = (this.CANVAS_WIDTH / 2 - obj.width / 2) * scale;

                if (isDragging && obj.id === selectedElementId) {
                    // Draw indicator of locked x-axis
                    ctx.setLineDash([5, 5]);
                    ctx.strokeStyle = "orange";
                    ctx.lineWidth = 1;
                    ctx.beginPath();
                    ctx.moveTo((this.CANVAS_WIDTH * scale) / 2, 0);
                    ctx.lineTo((this.CANVAS_WIDTH * scale) / 2, this.CANVAS_HEIGHT * scale);
                    ctx.stroke();
                }
            }
            if (obj.alignment.includes("vertical")) {
                // Center object vertically
                posY = (this.CANVAS_HEIGHT / 2 - obj.height / 2) * scale;

                if (isDragging && obj.id === selectedElementId) {
                    // Draw indicator of locked y-axis
                    ctx.setLineDash([5, 5]);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = "orange";
                    ctx.beginPath();
                    ctx.moveTo(0, (this.CANVAS_HEIGHT * scale) / 2);
                    ctx.lineTo(this.CANVAS_WIDTH * scale, (this.CANVAS_HEIGHT * scale) / 2);
                    ctx.stroke();
                }
            }

            // Check for a shadow
            if (obj.shadow) {
                ctx.shadowColor = obj.shadowColor || "grey";
                ctx.shadowBlur = obj.shadowBlur || 15 * scale;
            } else {
                ctx.shadowColor = "transparent";
                ctx.shadowBlur = 0;
            }

            if (["textinput", "dropdown", "text", "custommappingfield"].includes(obj.type)) {
                if (
                    obj.textSize === undefined ||
                    obj.textColor === undefined ||
                    obj.text === undefined ||
                    obj.textBaseLineDescentOffset === undefined
                )
                    throw new Error("Could not render text object due to missing object property");

                // Update text dimensions
                this.updateTextDimensions(obj);

                // Draw text or label
                const textFormatString = Array.isArray(obj.textFormat) ? obj.textFormat.join(" ") : obj.textFormat;
                ctx.font = `${textFormatString} ${(obj.textSize * scale).toString()}px ${obj.textFont}`;
                ctx.fillStyle = obj.textColor;

                // Draw text (check if there are multiple lines)
                const textLines = obj.text.split("\n").length;
                const isMultiLineText = textLines > 1 && obj.id !== "user-name";
                const horizontalTextAlignOffset = getHorizontalTextAlignmentOffset(obj, scale);
                if (isMultiLineText) this.drawMultilineText(obj, ctx, scale, posX, posY);
                else {
                    ctx.textAlign = obj.textAlignment ?? "left";
                    ctx.fillText(
                        obj.text || obj.label || obj.name,
                        posX,
                        posY + (obj.height - obj.textBaseLineDescentOffset) * scale
                    );
                    // Reset alignment to default
                    ctx.textAlign = "start";
                }

                // Draw selection indicator
                ctx.shadowColor = "transparent"; // Disable shadow
                ctx.shadowBlur = 0;
                if (obj.id === selectedElementId && this.ADMIN_PANEL) {
                    isMultiLineText
                        ? this.drawMultilineSelectionOutline(ctx, obj, scale, posX, posY)
                        : this.drawSelectionOutline(ctx, obj, scale, posX, posY);

                    // Draw delete icon (not for user name)
                    if (obj.id !== "user-name") {
                        ctx.drawImage(
                            this.deleteIcon,
                            posX - horizontalTextAlignOffset + obj.width * scale,
                            posY - (this.SELECTION_OUTLINE_MARGIN / 2 + this.ICON_SIZE / 2),
                            this.ICON_SIZE,
                            this.ICON_SIZE
                        );
                        ctx.drawImage(
                            this.duplicateIcon,
                            posX -
                                horizontalTextAlignOffset +
                                obj.width * scale -
                                (this.SELECTION_OUTLINE_MARGIN + this.ICON_SPACING),
                            posY - (this.SELECTION_OUTLINE_MARGIN / 2 + this.ICON_SIZE / 2),
                            this.ICON_SIZE,
                            this.ICON_SIZE
                        );
                    }
                }
            } else if (["image", "background"].includes(obj.type)) {
                // Check necessarry object properties
                if (!obj.img) throw new Error("Could not render image object due to missing object property");

                // Return if image object is empty
                if (Object.keys(obj.img).length === 0 && obj.img.constructor === Object) return;

                // if ("outerHTML" in obj.img) if (obj.img.outerHTML.includes("undefined")) return;
                // If the image is the profile image clip it to a circle
                if (obj.id == "user-profile-pic" && this.USER_PANEL) {
                    // Save state of canvas before adding the profile picture
                    ctx.save();

                    // Draw circle with shadow. (Shadow properties have been set above)
                    this.drawEllipse(
                        ctx,
                        posX,
                        posY,
                        obj.width * scale,
                        obj.height * scale,
                        false,
                        "rgba(0,0,0,0)",
                        0,
                        "rgba(0,0,0,0)"
                    );

                    // Enable clipping for the user profile picture
                    ctx.clip();
                }

                // If the badge is drawn for a preview choose the lower resolution imgPreview image object.
                // This has the target resolution and is properly resized with better antialising.
                // For exporting please use the "img" image object, as this has the highest resolution for a better quality when upscaling.
                // Also disable pica resizing when using the admin panel to ensure higher quality in the user panel
                let imageToDraw = obj.img;
                if (!picaResize) imageToDraw = obj.imgExport || obj.img;
                // if (imageToDraw === {}) throw new Error("Image object was not loaded. Object content is empty.");

                // Draw image
                ctx.drawImage(imageToDraw as HTMLImageElement, posX, posY, obj.width * scale, obj.height * scale);

                // Restore after clipping profile image was drawn
                if (obj.id === "user-profile-pic") {
                    ctx.restore();
                }

                // Draw selection indicator
                ctx.shadowColor = "transparent"; // Disable shadow
                ctx.shadowBlur = 0;
                if (obj.id === selectedElementId && this.ADMIN_PANEL && obj.id !== "background") {
                    this.drawRectangle(
                        ctx,
                        posX - 10,
                        posY - 10,
                        obj.width * scale + 20,
                        obj.height * scale + 20,
                        8 * scale,
                        true,
                        "silver",
                        1,
                        "rgba(0,0,0,0)"
                    ); // Offsets are for adding a padding and centering the rect again
                    ctx.drawImage(this.resizeIcon, posX + obj.width * scale, posY + obj.height * scale, 20, 20);
                    // Draw delete/duplicate icon (not on user profile picture)
                    if (obj.id !== "user-profile-pic") {
                        ctx.drawImage(this.deleteIcon, posX + obj.width * scale, posY - 20, 20, 20);
                        ctx.drawImage(this.duplicateIcon, posX + obj.width * scale - 25, posY - 20, 20, 20);
                    }
                }
            } else if (obj.type === "shape") {
                // Check necessarry object properties
                if (obj.borderRadius === undefined || obj.borderThickness === undefined)
                    throw new Error("Could not render shape object due to missing object property");

                if (obj.shapeType === "rectangle") {
                    this.drawRectangle(
                        ctx,
                        posX,
                        posY,
                        obj.width * scale,
                        obj.height * scale,
                        obj.borderRadius * scale,
                        obj.borderDash,
                        obj.borderColor ?? "black",
                        obj.borderThickness * scale,
                        obj.fillColor ?? "white"
                    );
                } else if (obj.shapeType === "circle") {
                    this.drawEllipse(
                        ctx,
                        posX,
                        posY,
                        obj.width * scale,
                        obj.height * scale,
                        obj.borderDash,
                        obj.borderColor ?? "black",
                        obj.borderThickness * scale,
                        obj.fillColor ?? "white"
                    );
                }

                // Draw selection outline and icons
                ctx.shadowColor = "transparent"; // Disable shadow
                ctx.shadowBlur = 0;
                if (obj.id === selectedElementId && this.ADMIN_PANEL) {
                    this.drawRectangle(
                        ctx,
                        posX - this.SELECTION_OUTLINE_MARGIN / 2,
                        posY - this.SELECTION_OUTLINE_MARGIN / 2,
                        obj.width * scale + this.SELECTION_OUTLINE_MARGIN,
                        obj.height * scale + this.SELECTION_OUTLINE_MARGIN,
                        8,
                        true,
                        "silver",
                        1,
                        "rgba(0,0,0,0)",
                        scale
                    );
                    ctx.drawImage(
                        this.deleteIcon,
                        posX + obj.width * scale + this.SELECTION_OUTLINE_MARGIN / 2 - this.ICON_SIZE / 2,
                        posY - this.SELECTION_OUTLINE_MARGIN / 2 - this.ICON_SIZE / 2,
                        this.ICON_SIZE,
                        this.ICON_SIZE
                    );
                    ctx.drawImage(
                        this.duplicateIcon,
                        posX + obj.width * scale - this.ICON_SIZE - this.ICON_SPACING,
                        posY - this.SELECTION_OUTLINE_MARGIN / 2 - this.ICON_SIZE / 2,
                        this.ICON_SIZE,
                        this.ICON_SIZE
                    );
                    ctx.drawImage(
                        this.resizeIcon,
                        posX + obj.width * scale,
                        posY + obj.height * scale,
                        this.ICON_SIZE,
                        this.ICON_SIZE
                    );
                }
            }
            obj.lastPosX = obj.posX;
            obj.lastPosY = obj.posY;
        } catch (e) {
            console.trace(e);
        }
    }

    measureFontHeight = (ctxFont: string) => {
        // Set font props
        this.MEASURE_CTX.font = ctxFont;

        const measuredText = this.MEASURE_CTX.measureText("Mj");
        return measuredText.actualBoundingBoxAscent + measuredText.actualBoundingBoxDescent;
    };

    measureTextHeight = (text: string, ctxFont: string) => {
        // Set font props
        this.MEASURE_CTX.font = ctxFont;

        const measuredText = this.MEASURE_CTX.measureText(text);
        return measuredText.actualBoundingBoxAscent + measuredText.actualBoundingBoxDescent;
    };

    measureTextWidth = (text: string, ctxFont: string) => {
        // Set font props
        this.MEASURE_CTX.font = ctxFont;

        const measuredText = this.MEASURE_CTX.measureText(text);
        return measuredText.width;
    };

    getLongestParagraphWidth = (obj: VbDesigner.BadgeObject, ctxFont: string) => {
        if (obj.type === "text" && obj.text) {
            const textArray = obj.text.split("\n");
            let width = 0;
            let maxChars = 0;

            for (let i = 0; i < textArray.length; i++) {
                // Find longes paragraph and get width
                if (textArray[i].length >= maxChars) {
                    maxChars = textArray[i].length;
                    width = this.measureTextWidth(textArray[i], ctxFont);
                }
            }
            return width;
        }
        return 0;
    };

    // Draw selection outline
    drawMultilineSelectionOutline = (
        ctx: CanvasRenderingContext2D,
        obj: VbDesigner.BadgeObject,
        scale: number,
        posX: number,
        posY: number
    ) => {
        if (obj.text === undefined || obj.textSize === undefined)
            throw new Error("Cannot execute draw multiline selection outline due to a missing object property");

        // Get text with defined font and format
        const textFormatString = Array.isArray(obj.textFormat) ? obj.textFormat.join(" ") : obj.textFormat;
        ctx.font = `${textFormatString} ${(obj.textSize * scale).toString()}px ${obj.textFont}`;

        const lines = obj.text.split("\n").length;
        const textSpacing = (obj.textSpacing ? obj.textSpacing : 1) * scale;
        const width = this.getLongestParagraphWidth(obj, ctx.font);

        // Update the dimensions of the text element (bounding box), so that the hitboxes
        // for icons (delete, duplicate, scale) can orient themselves on the corresponding corners
        obj.width = width * (1 / scale); // Normalize scale for saving in obj (this is necessary because the obj.width gets scaled again in the render function)
        obj.height = this.measureFontHeight(ctx.font) * lines + textSpacing * (lines - 1);

        this.drawRectangle(
            ctx,
            posX - this.SELECTION_OUTLINE_MARGIN / 2,
            posY - this.SELECTION_OUTLINE_MARGIN / 2,
            width + this.SELECTION_OUTLINE_MARGIN,
            obj.height + this.SELECTION_OUTLINE_MARGIN,
            8,
            true,
            "silver",
            1,
            "rgba(0,0,0,0)"
        );
    };

    drawSelectionOutline = (
        ctx: CanvasRenderingContext2D,
        obj: VbDesigner.BadgeObject,
        scale: number,
        posX: number,
        posY: number
    ) => {
        if (obj.textBaseLineDescentOffset === undefined)
            throw "Cannot draw selection outline due to a missing object property.";

        const horizontalTextAlignOffset = getHorizontalTextAlignmentOffset(obj, scale);

        const objWidthScaled = obj.width * scale;
        const objHeightScaled = obj.height * scale;
        this.drawRectangle(
            ctx,
            obj.alignment.includes(
                "horizontal"
            ) /* if the horizontal axis is locked center via dividing the canvas width with 2 */
                ? (this.CANVAS_WIDTH * scale) / 2 - objWidthScaled / 2 - this.SELECTION_OUTLINE_MARGIN / 2
                : posX - this.SELECTION_OUTLINE_MARGIN / 2 - horizontalTextAlignOffset,
            obj.alignment.includes(
                "vertical"
            ) /* if the vertical axis is locked center via dividing the canvas height by 2 */
                ? (this.CANVAS_HEIGHT * scale) / 2 - objHeightScaled / 2 - this.SELECTION_OUTLINE_MARGIN / 2
                : posY - this.SELECTION_OUTLINE_MARGIN / 2,
            objWidthScaled + this.SELECTION_OUTLINE_MARGIN /* adding a 20 px padding for the indicator box */,
            objHeightScaled + this.SELECTION_OUTLINE_MARGIN,
            8,
            true,
            "silver",
            1,
            "rgba(0,0,0,0)"
        ); // Offsets are for adding a padding and centering the rect again
    };

    // Draw a rectangle
    // eslint-disable-next-line class-methods-use-this
    drawRectangle = (
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        radius: number,
        dash: boolean | undefined,
        borderColor: string,
        borderThickness: number,
        fillColor: string,
        scale = 1
    ) => {
        ctx.save();

        if (dash) ctx.setLineDash([5 * scale, 5 * scale]);
        else ctx.setLineDash([]);
        ctx.strokeStyle = borderColor;
        ctx.lineWidth = borderThickness;
        ctx.fillStyle = fillColor;

        ctx.beginPath();
        if (radius === 0 || !radius) {
            ctx.moveTo(x, y);
            ctx.lineTo(x, y + height);
            ctx.lineTo(x + width - radius, y + height);
            ctx.lineTo(x + width, y);
            ctx.lineTo(x - borderThickness / 2, y);
        } else {
            ctx.moveTo(x, y + radius - 1);
            ctx.lineTo(x, y + height - radius);
            ctx.arcTo(x, y + height, x + radius, y + height, radius);
            ctx.lineTo(x + width - radius, y + height);
            ctx.arcTo(x + width, y + height, x + width, y + height - radius, radius);
            ctx.lineTo(x + width, y + radius);
            ctx.arcTo(x + width, y, x + width - radius, y, radius);
            ctx.lineTo(x + radius, y);
            ctx.arcTo(x, y, x, y + radius, radius);
        }
        ctx.closePath();
        if (borderThickness > 0) ctx.stroke();

        ctx.fill();
        ctx.restore();
    };

    // Draw an ellipse
    // eslint-disable-next-line class-methods-use-this
    drawEllipse = (
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        dash: boolean | undefined,
        borderColor: string,
        borderThickness: number,
        fillColor: string
    ) => {
        ctx.save();
        if (dash) ctx.setLineDash([5, 5]);
        else ctx.setLineDash([]);
        ctx.strokeStyle = borderColor;
        ctx.fillStyle = fillColor;
        ctx.lineWidth = borderThickness;

        ctx.beginPath();
        ctx.ellipse(x + width / 2, y + height / 2, height / 2, width / 2, (Math.PI * 3) / 2, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
        if (borderThickness > 0) ctx.stroke();

        ctx.restore();
    };

    /**
     * Returns the posX offset according to the alignment set for the multiline text element.
     * E.g. offset "o" = (maxWidth - textWidth) / 2
     * ->   |----Hello World---|
     *      |-o-|textWidth-|-o-|
     * ->   posX += o
     */
    getMultilineTextAlignmentOffset = (obj: VbDesigner.BadgeObject, text: string, ctxFont: string) => {
        const maxWidth = this.getLongestParagraphWidth(obj, ctxFont);
        const textWidth = this.measureTextWidth(text, ctxFont);

        switch (obj.textAlignment) {
            case "center":
                return (maxWidth - textWidth) / 2;
            case "right":
                return maxWidth - textWidth;
            case "left":
            default:
                return 0;
        }
    };

    drawMultilineText = (
        obj: VbDesigner.BadgeObject,
        ctx: CanvasRenderingContext2D,
        scale: number,
        posX: number,
        posY: number
    ) => {
        if (!obj.text || !obj.textSize || !obj.textColor)
            throw Error("Cannot execute draw multiline text due to a missing object property");

        const textArray = obj.text.split("\n");
        let offsetY = 0;
        let offsetX = 0;

        // Set font style
        const textFormatString = Array.isArray(obj.textFormat) ? obj.textFormat.join(" ") : obj.textFormat;
        ctx.font = `${textFormatString} ${obj.textSize * scale}px ${obj.textFont}`;
        ctx.fillStyle = obj.textColor;

        for (let i = 0; i < textArray.length; i++) {
            // Measure new width & height
            // let width = measureTextWidth(textArray[i], ctx.font);
            const height = this.measureFontHeight(ctx.font);

            // offsetY for spacing between lines for
            const spacing = (obj.textSpacing ? obj.textSpacing : 5) * scale;
            if (i === 0) offsetY += this.measureTextHeight(textArray[i], ctx.font);
            else offsetY += height + spacing;

            // offsetX for alignment of text (left, center, right)
            offsetX = this.getMultilineTextAlignmentOffset(obj, textArray[i], ctx.font);

            // Reset textAlign property to its default.
            // Multiline text gets aligned via the offsetX from the function getMultilineTextAlignmentOffset().
            ctx.textAlign = "left";

            // Draw text
            ctx.fillText(textArray[i], posX + offsetX, posY + offsetY);
        }
    };

    // Get text dimentions by measuring and return the updated badge object
    updateTextDimensions(obj: VbDesigner.BadgeObject, text: string | undefined = undefined): VbDesigner.BadgeObject {
        // Ignore legacy textinput and dropdown objects (will become obsolete when we migrate all badgeprops to polotnoprops)
        if (obj.type === "textinput" || obj.type === "dropdown") return obj;

        if ((!obj.text && obj.type !== "custommappingfield") || !obj.textSize)
            throw `Cannot update text dimensions due to a missing object property. ${obj}`;

        // Get text with defined font and format
        const textFormatString = Array.isArray(obj.textFormat) ? obj.textFormat.join(" ") : obj.textFormat;
        this.MEASURE_CTX.font = `${textFormatString} ${obj.textSize}px ${obj.textFont}`;
        if (!text) text = obj.text;

        // Update multiline text dimensions
        if (text?.includes("\n")) {
            const lines = text.split("\n").length;
            const textSpacing = obj.textSpacing ?? 5;
            const width = this.getLongestParagraphWidth(obj, this.MEASURE_CTX.font);

            obj.width = width;
            obj.height = this.measureFontHeight(this.MEASURE_CTX.font) * lines + textSpacing * (lines - 1);
        }
        // Update single line text dimenstion
        else {
            let measuredText = this.MEASURE_CTX.measureText(text ?? "");
            if (measuredText.width == 0 && obj.label) measuredText = this.MEASURE_CTX.measureText(obj.label);
            if (measuredText.width == 0) measuredText = this.MEASURE_CTX.measureText(obj.name);

            // Get text width with textSizeBase to measure if the original text size fits inside the badge
            this.MEASURE_CTX.font = `${textFormatString} ${obj.textSizeBase}px ${obj.textFont}`;
            const baseTextSizeWidth = this.MEASURE_CTX.measureText(text ?? "").width;

            // If measured text width exceeds canvas, recursively try smaller font size until it fits
            // Exclude multiline text (text with <br> in it)
            if (measuredText.width > this.CANVAS_WIDTH - 10 && !text?.includes("\n")) {
                obj.textSize -= 1;
                return this.updateTextDimensions(obj, text);
            }

            // Support for older versions
            if (!obj.textSizeBase) {
                obj.textSizeBase = obj.textSize;
            }

            // If text fits with original text size, increase text size again
            if (baseTextSizeWidth < this.CANVAS_WIDTH - 10 && obj.textSize < obj.textSizeBase) {
                obj.textSize += 1;
                return this.updateTextDimensions(obj, text);
            }

            // Get text dimensions (https://developer.mozilla.org/en-US/docs/Web/API/TextMetrics)
            obj.width = measuredText.width;

            // Return updated height for the selection indicator
            obj.height = measuredText.actualBoundingBoxAscent + measuredText.actualBoundingBoxDescent;

            // Get offset of font baseline to box bounding (helps to center the text when rendering)
            obj.textBaseLineDescentOffset = measuredText.actualBoundingBoxDescent;
        }

        // If the horizontal axis is locked, update the posX with the new width
        if (obj.alignment.includes("horizontal")) {
            obj.posX = this.CANVAS_WIDTH / 2 - obj.width / 2;
        }

        // If the vertical axis is locked, update the posY with the new height
        if (obj.alignment.includes("vertical")) {
            obj.posY = this.CANVAS_HEIGHT / 2 - obj.height / 2;
        }

        return obj;
    }

    /**
     * Takes an image or the badgeprops as an input and converts all image objects to base64 strings
     * @param input
     * @param inputType
     * @returns
     */
    // eslint-disable-next-line class-methods-use-this
    convertImgToBase64(input: VbDesigner.IBadgeProps | HTMLImageElement, inputType: string) {
        const canvas = document.createElement("canvas") as HTMLCanvasElement;
        const ctx = canvas.getContext("2d");

        switch (inputType) {
            case "image": {
                input = input as HTMLImageElement;
                canvas.height = input.naturalHeight;
                canvas.width = input.naturalWidth;
                ctx?.drawImage(input, 0, 0);

                return canvas.toDataURL();
            }
            case "badgeProps": {
                const badgeProps = input as VbDesigner.IBadgeProps;
                badgeProps.badgeObjects.forEach((obj) => {
                    if (obj.type == "image" || (obj.type == "background" && obj.img)) {
                        if (isEmpty(obj.img)) throw Error("Image is not loaded into obj.img");
                        canvas.height = (obj.img as HTMLImageElement).naturalHeight ?? 0;
                        canvas.width = (obj.img as HTMLImageElement).naturalWidth ?? 0;
                        ctx?.drawImage(obj.img as HTMLImageElement, 0, 0);

                        // Save base64 in new object item as asigning it to
                        // obj.img results in <img src="base64" /> wich cant be saved to the db
                        obj.imgBase64 = canvas.toDataURL();
                    }
                });
                badgeProps.userInformationObjects.forEach((obj) => {
                    if (obj.type == "image") {
                        if (isEmpty(obj.img)) throw Error("Image is not loaded into obj.img");

                        canvas.height = (obj.img as HTMLImageElement).naturalHeight ?? 0;
                        canvas.width = (obj.img as HTMLImageElement).naturalWidth ?? 0;
                        ctx?.drawImage(obj.img as HTMLImageElement, 0, 0);

                        obj.imgBase64 = canvas.toDataURL();
                    }
                });
                return badgeProps;
            }
            default: {
                return input;
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    async convertUrlToImg(
        input: VbDesigner.IBadgeProps | string,
        inputType: string
    ): Promise<HTMLImageElement | VbDesigner.IBadgeProps | string> {
        switch (inputType) {
            case "image": {
                const imageFromUrl = new Image();
                imageFromUrl.src = input as string;
                imageFromUrl.crossOrigin = "anonymous";
                // eslint-disable-next-line @typescript-eslint/return-await
                return await new Promise((resolve) => {
                    imageFromUrl.onload = () => {
                        resolve(imageFromUrl);
                    };
                });
            }
            case "badgeProps": {
                const badgeProps = input as VbDesigner.IBadgeProps;
                badgeProps.badgeObjects.forEach((obj) => {
                    if (obj.type == "image" || (obj.type == "background" && obj.img)) {
                        const imageFromUrl = new Image();
                        imageFromUrl.src = obj.imgBlobUrl ?? "";
                        obj.img = imageFromUrl;
                    }
                });
                badgeProps.userInformationObjects.forEach((obj) => {
                    if (obj.type == "image") {
                        const imageFromUrl = new Image();
                        imageFromUrl.src = obj.imgBlobUrl ?? "";
                        obj.img = imageFromUrl;
                    }
                });
                return badgeProps;
            }
            default: {
                return input;
            }
        }
    }

    /**
     * Convert base64 coming from the database back to an image object
     * @param input
     * @param inputType
     * @returns
     */
    convertBase64ToImg(input: VbDesigner.IBadgeProps | string, inputType: string) {
        switch (inputType) {
            case "image": {
                const img = new Image();
                img.src = input as string;
                return img;
            }
            case "badgeProps": {
                const badgeProps = input as VbDesigner.IBadgeProps;
                badgeProps.badgeObjects.forEach((obj) => {
                    if (obj.type == "image" || obj.type == "background") {
                        // Get a smoother resized image for preview on the user panel
                        if (this.USER_PANEL) {
                            const imgPreview = new Image();
                            imgPreview.onload = async () => {
                                const resizedImage = await this.resizeImage(imgPreview, obj.width, obj.height);
                                obj.img = resizedImage;
                            };
                            imgPreview.src = obj.imgBase64 ?? "";
                        }

                        // Get the image for export (high quality)
                        const imgExport = new Image();
                        imgExport.onload = async () => {
                            obj.imgExport = imgExport;

                            // In the admin panel use the high quality version of the image
                            if (this.ADMIN_PANEL) obj.img = imgExport;
                        };
                        imgExport.src = obj.imgBase64 ?? "";
                    }
                });
                badgeProps.userInformationObjects.forEach((obj) => {
                    if (obj.type == "image") {
                        const img = new Image();
                        img.src = obj.imgBase64 ?? "";
                        obj.img = img;
                    }
                });
                return badgeProps;
            }
            default:
                return input;
        }
    }

    /**
     * Resize function to eliminate aliasing issues when scaling down images.
     * Returns an image object.
     * @param img
     * @param targetWidth
     * @param targetHeight
     * @returns
     */
    // eslint-disable-next-line class-methods-use-this
    resizeImage(img: HTMLImageElement, targetWidth: number, targetHeight: number): Promise<HTMLImageElement> {
        const canvas = document.createElement("canvas");
        canvas.width = targetWidth || 1;
        canvas.height = targetHeight || 1;

        return new Promise((resolve) => {
            (pica as any)
                .resize(img, canvas, { alpha: true, unsharpAmount: 150, unsharpRadius: 0.5, unsharpThreshold: 0.5 })
                .then((result: HTMLCanvasElement) => (pica as any).toBlob(result, "image/png", 0.9))
                .then((blob: Blob) => {
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    const img = new Image();
                    img.onload = () => {
                        resolve(img);
                    };
                    img.src = URL.createObjectURL(blob);
                });
        });
    }

    /**
     * Set the text properties of the dynamic fields and the username badge objects according
     * to the passed in fieldMapping parameter
     *
     * @param badgeProps
     * @param fieldMapping
     * @param validationUrl
     * @param certificateId
     * @returns Promise<badgeProps>
     */
    async setCustomFieldsAndNameValues(
        badgeProps: VbDesigner.IBadgeProps,
        fieldMapping: Fieldmapping[],
        validationUrl?: string,
        certificateId?: string
    ): Promise<VbDesigner.IBadgeProps> {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (badgeProps && fieldMapping) {
                // Add mapping to customMappingFieldObjects
                if (Object.prototype.hasOwnProperty.call(badgeProps, "customMappingFieldObjects")) {
                    badgeProps.customMappingFieldObjects.forEach(async (customField) => {
                        const mappingForField = fieldMapping.filter((f) => f.id === customField.id)[0];
                        customField.text = mappingForField.value;
                        await this.updateTextDimensions(customField);
                    });
                }

                // If username was entered in fieldmapping, overwrite the value from the users social media account
                const username = fieldMapping.find((f) => f.id === "username");
                const usernameValue = username ? username.value : "";

                if (usernameValue) {
                    badgeProps.userInformationObjects[1].text = usernameValue;
                    badgeProps.userInformationObjects[1] = await this.updateTextDimensions(
                        badgeProps.userInformationObjects[1]
                    );
                }

                // Map the validation page for the recipient to the qr code
                const qrCodeObj = badgeProps.badgeObjects.find((obj) => obj.id === "qrcode");
                if (validationUrl && certificateId && qrCodeObj) {
                    const qrCode = new QRCode({
                        content: decodeURIComponent(validationUrl),
                        padding: 4,
                        width: qrCodeObj.width,
                        height: qrCodeObj.height,
                        color: "#000000",
                        background: "#ffffff",
                    }).svg();

                    const qrCodeImg = new Image();
                    qrCodeImg.src = `data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`;
                    qrCodeImg.crossOrigin = "anonymous";

                    await new Promise((_resolve) => {
                        qrCodeImg.onload = () => {
                            qrCodeObj.img = qrCodeImg;
                            qrCodeObj.imgExport = qrCodeImg;
                            qrCodeObj.imgBlobUrl = qrCodeImg.src;
                            _resolve(true);
                        };
                    });
                }
            }
            resolve(badgeProps);
        });
    }

    /**
     * Export dataURL of canvas with drawn badgeprops.
     * Takes in the badgeprops and the scale of the export.
     * @param badgeProps
     * @param scale
     * @param picaResize
     * @returns
     */
    exportBadge(
        _badgeProps: VbDesigner.IBadgeProps,
        {
            scale = 1,
            picaResize = false,
            exportType = "string",
            keepProfilePic = false,
        }: { scale?: number; picaResize?: boolean; exportType?: "string" | "blob"; keepProfilePic?: boolean } = {}
    ): Promise<string | Blob> {
        const badgeProps = cloneDeep(_badgeProps);

        // Create canvas to draw export on
        const canvas = document.createElement("canvas");

        // Set canvas dimensions
        canvas.width = this.CANVAS_WIDTH * scale;
        canvas.height = this.CANVAS_HEIGHT * scale;

        return new Promise((resolve) => {
            // Set background to white
            const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
            if (!ctx) throw Error("Canvas context not found");
            ctx.fillStyle = "transparent";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            const dummyCanvasState: VbDesigner.CanvasState = {
                isLoading: false,
                isDragging: false,
                isScaling: false,
                selectedElement: "",
                selectedType: "background",
                canvasScale: 1,
                canvasWidth: this.CANVAS_WIDTH,
                canvasHeight: this.CANVAS_HEIGHT,
                canvasCursor: "auto",
            };

            // Draw to canvas
            badgeProps.badgeObjects.map(async (obj: any) => {
                if (!obj.id.startsWith("watermark-"))
                    await this.drawObject(ctx, obj, dummyCanvasState, scale, picaResize);
            });
            for (let i = 0; i < badgeProps.userInformationObjects.length; i++) {
                if (
                    badgeProps.userInformationObjects[i].id === "user-profile-pic" &&
                    badgeProps.userInformationObjects[i].imgBlobUrl?.includes("user_profile_dummy") &&
                    !keepProfilePic
                )
                    // eslint-disable-next-line no-continue
                    continue;
                else this.drawObject(ctx, badgeProps.userInformationObjects[i], dummyCanvasState, scale, picaResize);
            }
            badgeProps.customInputObjects.map(async (obj: any) => {
                await this.drawObject(ctx, obj, dummyCanvasState, scale, picaResize);
            });
            if ("customMappingFieldObjects" in badgeProps) {
                badgeProps.customMappingFieldObjects.map(async (obj: any) => {
                    await this.drawObject(ctx, obj, dummyCanvasState, scale, picaResize);
                });
            }
            badgeProps.badgeObjects.map(async (obj: any) => {
                if (obj.id.startsWith("watermark-"))
                    await this.drawObject(ctx, obj, dummyCanvasState, scale, picaResize);
            });

            if (exportType === "string") resolve(canvas.toDataURL());
            else
                canvas.toBlob((blob) => {
                    if (blob === null) throw new Error("[BadgeEngine.exportBadge()] canvas.toBlob resulted in 'null'.");
                    resolve(blob);
                });
        });
    }

    exportBadgeBackground(badgeProps: VbDesigner.IBadgeProps, scale = 1): Promise<string> {
        // Create canvas to draw export on
        const canvas = document.createElement("canvas");

        // Set canvas dimensions
        canvas.width = this.CANVAS_WIDTH * scale;
        canvas.height = this.CANVAS_HEIGHT * scale;

        return new Promise((resolve) => {
            // Set background to white
            const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
            if (!ctx) throw "Canvas context not found";

            ctx.fillStyle = "transparent";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            const dummyCanvasState: VbDesigner.CanvasState = {
                isLoading: false,
                isDragging: false,
                isScaling: false,
                selectedElement: "",
                selectedType: "background",
                canvasScale: 1,
                canvasWidth: this.CANVAS_WIDTH,
                canvasHeight: this.CANVAS_HEIGHT,
                canvasCursor: "auto",
            };

            // Draw to canvas
            badgeProps.badgeObjects.map(async (obj: any) => {
                await this.drawObject(ctx, obj, dummyCanvasState, scale);
            });

            resolve(canvas.toDataURL());
        });
    }

    /**
     * Export a thumbnail with a given width and heigth.
     * Call this with await in an async function
     * @param badgeProps
     * @param width
     * @param height
     * @returns
     */
    exportThumbnail(badgeProps: VbDesigner.IBadgeProps, width: number, height: number): Promise<string> {
        const canvas = document.createElement("canvas");

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        ctx.fillStyle = "transparent";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const badgeImg = new Image();

            badgeImg.onload = () => {
                const badgeWidth = badgeImg.width * (canvas.height / badgeImg.height);
                ctx.drawImage(badgeImg, canvas.width / 2 - badgeWidth / 2, 0, badgeWidth, canvas.height);
                setTimeout(() => {
                    resolve(canvas.toDataURL());
                }, 200);
            };
            badgeImg.src = (await this.exportBadge(badgeProps)) as string;
        });
    }

    /**
     * Draw a line to the canvas with the given CanvasRenderingContext2D (ctx).
     * @param ctx
     * @param sx
     * @param sy
     * @param tx
     * @param ty
     * @param options
     */
    // eslint-disable-next-line class-methods-use-this
    drawLine = (
        ctx: CanvasRenderingContext2D,
        sx: number,
        sy: number,
        tx: number,
        ty: number,
        options: { lineWidth: number; strokeStyle: string }
    ) => {
        ctx.lineWidth = options.lineWidth;
        ctx.strokeStyle = options.strokeStyle;
        ctx.moveTo(sx, sy);
        ctx.lineTo(tx, ty);
        ctx.stroke();
    };

    /**
     * Draw a grid to help with alignment of elements on the canvas.
     * @param ctx
     * @param scale
     */
    drawGrid = (ctx: CanvasRenderingContext2D, scale: number) => {
        const canvasWidthScaled = this.CANVAS_WIDTH * scale;
        const canvasHeightScaled = this.CANVAS_HEIGHT * scale;
        ctx.drawImage(this.canvasGrid, 0, 0, canvasWidthScaled, canvasHeightScaled);
    };
}
