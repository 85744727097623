import CertificateValidatorContainer from "features/certificateValidator/components/CertificateValidatorContainer";

import ValidationLayout from "layouts/ValidationLayout";

const CertificateValidator = () => {
    return (
        <ValidationLayout>
            <CertificateValidatorContainer />
        </ValidationLayout>
    );
};

export default CertificateValidator;
