/* eslint-disable react/no-unstable-nested-components */
import { FC, PropsWithChildren } from "react";
import { closeSnackbar, MaterialDesignContent, SnackbarProvider } from "notistack";

import { WHITE } from "utils/Theme/theme";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, styled } from "@mui/material";

import useNotification from "./notification.helper";

const StyledContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent": {
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",
    },
}));

const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
    useNotification();

    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            Components={{
                success: StyledContent,
                error: StyledContent,
                warning: StyledContent,
                info: StyledContent,
                default: StyledContent,
            }}
            maxSnack={3}
            dense
            hideIconVariant
            preventDuplicate
            autoHideDuration={6000}
            action={(key) => (
                <IconButton
                    id="close-button"
                    sx={{
                        color: WHITE,
                    }}
                    onClick={() => closeSnackbar(key)}
                >
                    <CloseIcon />
                </IconButton>
            )}
        >
            {children}
        </SnackbarProvider>
    );
};

export default NotificationProvider;
