import { type TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { createLogger } from "redux-logger";

import { devToolsOptions } from "./devToolOptions/devToolsOptions";
import { rtkQueryErrorLogger } from "./devToolOptions/queryErrorHandling";
import { combinedMiddleware } from "./slices/createMiddleware";
import { rootReducer } from "./slices/rootReducer";

const configuredLogger = createLogger({
    collapsed: true,
    duration: true,
    diff: true,
});

const buildMiddleware =
    process.env.NODE_ENV !== "production"
        ? [configuredLogger, rtkQueryErrorLogger, ...combinedMiddleware]
        : combinedMiddleware;

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.ENVIRONMENT !== "production" ? devToolsOptions : false,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(buildMiddleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Infer type to the Redux Async Thunk API argument.
// https://redux-toolkit.js.org/usage/usage-with-typescript#typing-the-thunkapi-object
export type ThunkAPIConfig = {
    /** return type for `thunkApi.getState` */
    state: RootState;
    /** type for `thunkApi.dispatch` */
    dispatch: AppDispatch;
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const selectStore = (state: RootState) => state;
