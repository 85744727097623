import { useShareLinkedinCall } from "features/certificateDownload/api/shareApi";
import * as Yup from "yup";

import eventSelectorService from "../../../../redux/selector/events";
import { useAppSelector } from "../../../../redux/store";

export const useShareLinkedinModal = () => {
    const { shareLinkedin, setPendingState, loadingState, setErrorState } = useShareLinkedinCall();
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);

    const validationSchema = { linkedinPostDescription: Yup.string() };
    const initialValues = {
        linkedinPostDescription: eventDetails?.event_share_text || "",
        postTags:
            (eventDetails?.event_share_hashtags && `#${eventDetails?.event_share_hashtags.split(",").join(" #")}`) ||
            "",
    };

    const handleDialogSubmit = async (values: typeof initialValues) => {
        setPendingState();
        const linkedinPostDescription = values?.linkedinPostDescription;
        await shareLinkedin(linkedinPostDescription);
    };

    const handleCancel = () => setErrorState();

    return { initialValues, validationSchema, handleDialogSubmit, loadingState, handleCancel };
};
