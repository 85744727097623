import { useCallback, useMemo } from "react";

import i18n from "i18n";
import useTranslation from "i18n/hooks/useTranslation";

import getWalletButton from "./walletButton.helpers";
import { TLanguage, TWalletButtonType } from "./walletButton.types";

const useWalletButtons = () => {
    const t = useTranslation();
    const language = i18n.language;

    const walletButtonAlt = useMemo<Record<TWalletButtonType, string>>(
        () => ({
            apple: t("wallet.add_to_button.apple.alt"),
            google: t("wallet.add_to_button.google.alt"),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language]
    );

    const walletButton = useCallback(
        (type: TWalletButtonType) => getWalletButton(type, language as TLanguage),
        [language]
    );

    return { walletButtonAlt, walletButton };
};

export default useWalletButtons;
