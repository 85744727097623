import { useDebugValue, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CertificateValidatorApiRoutes } from "../../features/certificateValidator/api/routes";
import useTranslation from "../../i18n/hooks/useTranslation";
import { fetcher } from "../../redux/actions/fetch";

import type { TMessageFrom, TMessages, TNotFoundMessage, TNotFoundOptions } from "./notFound.types";

/**
 * Hook for "not-found"-page to redirect or change shown messages
 * Example: Canceled authentification with linkedin
 */
export const useHandleNotFound = () => {
    const t = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const storedLanguage = localStorage.getItem("i18nextLng");

    const [messageSelectorProxy, setMessageSelectorProxy] = useState<TMessageFrom | null>(null);

    const [notFoundText, setNotFoundText] = useState<TNotFoundMessage>({
        topic: t("not_found.message.default.topic"),
        message: t("not_found.message.default.message"),
        bottomMessage: t("not_found.message.default.bottomMessage"),
    });

    const [notFoundOptions, setNotFoundOptions] = useState<TNotFoundOptions>({});

    const messages: TMessages = {
        linkedin: {
            topic: t("not_found.message.error.linkedin_topic"),
            message: t("not_found.message.error.linkedin_message"),
            bottomMessage: null, // No bottom message on linked in
        },
    };

    const memorizedText = useMemo(() => notFoundText, [notFoundText]);

    const memorizedOptions = useMemo(() => notFoundOptions, [notFoundOptions]);

    const selectedLanguage = useMemo(() => storedLanguage, [storedLanguage]);

    const haveLogo = useMemo(() => notFoundOptions.logo && notFoundOptions.webpage, [notFoundOptions]);

    const { search } = location;

    const paramsFound = useMemo(() => search !== undefined, [search]);

    /**
     * Helper to generate additional options for not-found page
     * @param messageSelector Indication from where the redirect comes from
     * @param additional Additional information
     */
    const generateOptions = async (
        messageSelector: TMessageFrom | null,
        additional: { slugs: Record<"orga" | "certificate", string | null> }
    ) => {
        switch (messageSelector) {
            case "linkedin":
                if (additional.slugs.orga && additional.slugs.certificate) {
                    const fetchedEvent = await fetcher<ICertificate>(
                        CertificateValidatorApiRoutes.getEvent(additional.slugs.orga, additional.slugs.certificate)
                    );
                    setNotFoundOptions({
                        logo: fetchedEvent?.event_logo_url,
                        webpage: fetchedEvent?.event_certificate_course_url,
                    });
                }
                break;
            default:
                break;
        }
    };

    const handleCheckParams = () => {
        let errorFrom: TMessageFrom | null = null;
        let slugs: Record<"orga" | "certificate", string | null> = { orga: null, certificate: null };
        const redirect = false; // For future usecases
        const destructuredSearch = search.replaceAll("?", "").split("&");

        const searchObject: Record<string, string | Array<string>> = destructuredSearch.reduce((acc, cur) => {
            const splitted: Array<string> = cur.split("=");
            const key: string = splitted[0];
            let value: Array<string> | string = splitted[1];

            if (key === "state") value = value.split("%2C");

            if (value[0] === "linkedin") {
                errorFrom = "linkedin";
                setMessageSelectorProxy("linkedin");
                slugs = { orga: value[1], certificate: value[2] };
                value = `/${value[1]}/${value[2]}`;
            }

            return { ...acc, [key]: value };
        }, {});

        if (redirect) navigate(searchObject.state as string);
        else if (errorFrom) {
            generateOptions(errorFrom, { slugs });
            setNotFoundText(messages[errorFrom]);
        }
    };

    useEffect(() => {
        if (messageSelectorProxy) setNotFoundText(messages[messageSelectorProxy]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLanguage]);

    useDebugValue({ paramsFound, memorizedText });

    return { handleCheckParams, paramsFound, memorizedText, memorizedOptions, haveLogo };
};
