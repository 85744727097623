/* eslint-disable no-console */
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CertificateValidatorApiRoutes } from "../../features/certificateValidator/api/routes";
import eventActionService from "../../redux/actions/events";
import validationActionService from "../../redux/actions/validation";
import { useGetOrgaSlugs } from "../../redux/hooks/validation";
import { IGetCertificateArgs } from "../../redux/slices/types/certificateProps.query.types";
import { useAppDispatch } from "../../redux/store";

type TSlugsStores = "localStore" | "reduxStore";

const useBackArrow = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const storedSlugs = useGetOrgaSlugs();

    const fetchEvent = useCallback(
        (orga_slug: string, certificate_slug: string) => {
            dispatch(eventActionService.getEvent(CertificateValidatorApiRoutes.getEvent(orga_slug, certificate_slug)));
        },
        [dispatch]
    );

    const removeSlugsFromLocalStorage = useCallback(() => localStorage.removeItem("slugs"), []);

    const checkIfSlugsExist = useCallback(
        (slugs: IGetCertificateArgs | null | undefined) => {
            if (!slugs) return null;
            if (!slugs.orga_slug || !slugs.certificate_slug) return null;
            dispatch(validationActionService.setOrgaSlugs(slugs));
            return slugs;
        },
        [dispatch]
    );

    /**
     * Check if slugs are in redux-store or local storage to apply correct navigation.
     * If slugs was found, fetch event and navigate to verification route (https://app.virtualbadge.io/<orga-slug>/<certificate-slug>).
     * If there are no slugs redirect to base route.
     */
    const parseSlugs = useCallback(
        async (slugStores: Record<TSlugsStores, IGetCertificateArgs | null>) => {
            const { localStore, reduxStore } = slugStores;
            const reduxSlugsProxy = await checkIfSlugsExist(reduxStore);
            const localStoreSlugsProxy = await checkIfSlugsExist(localStore);
            const storeSlugs = reduxSlugsProxy || localStoreSlugsProxy;
            // TODO: After https://dev.azure.com/FutureNextBusiness/Virtual%20Badges/_git/virtual-badges-docker/pullrequest/2582 is merged, add search-params to apply correct 404-not-found page messages
            if (!storeSlugs) return "/";
            fetchEvent(storeSlugs.orga_slug as string, storeSlugs.certificate_slug as string);
            const route = `/${storeSlugs.orga_slug}/${storeSlugs.certificate_slug}`;
            return route;
        },
        [checkIfSlugsExist, fetchEvent]
    );

    const setSlugsToLocalStorage = useCallback((slugs: IGetCertificateArgs) => {
        if (slugs.orga_slug && slugs.certificate_slug)
            localStorage.setItem("slugs", `${slugs.orga_slug},${slugs.certificate_slug}`);
    }, []);

    const handleNavigate = useCallback(async () => {
        const localStoredSlugs = localStorage.getItem("slugs")?.split(",") || null;
        removeSlugsFromLocalStorage();
        const localSlugsProxy = localStoredSlugs
            ? {
                  orga_slug: localStoredSlugs[0],
                  certificate_slug: localStoredSlugs[1],
              }
            : null;
        const slugProxy: Record<TSlugsStores, IGetCertificateArgs | null> = {
            localStore: localSlugsProxy,
            reduxStore: storedSlugs,
        };
        // eslint-disable-next-line no-console
        await parseSlugs(slugProxy).then((routeRes) => navigate(routeRes));
    }, [navigate, parseSlugs, removeSlugsFromLocalStorage, storedSlugs]);

    useEffect(() => {
        if (storedSlugs && storedSlugs.orga_slug && storedSlugs.certificate_slug) setSlugsToLocalStorage(storedSlugs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return handleNavigate;
};

export default useBackArrow;
