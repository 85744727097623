import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const root = {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gap: 10,
    padding: "4px 16px",
    borderRadius: 8,
};

export const getStatusClass = (theme: IMuiTheme) => ({
    info: {
        color: theme.palette.info.main,
        backgroundColor: theme.palette.info.light,
    },
    error: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.light,
    },
    warning: {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.light,
    },
    success: {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.light,
    },
});

export const getStatusIcon = (variant: THelperVariant) => {
    switch (variant) {
        case "error":
            return <ErrorOutlineIcon />;
        case "warning":
            return <WarningAmberIcon />;
        case "info":
            return <HelpOutlineIcon />;
        case "success":
            return <CheckCircleOutlineIcon />;
        default:
            return <HelpOutlineIcon />;
    }
};
