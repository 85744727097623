import { useMemo } from "react";

import controlSelectorService from "../selector/control";
import { useGetCertificateDetailsQuery, useGetCertificatePropsQuery } from "../slices/query/certificateProps";
import { useAppSelector } from "../store";

import { useGetOrgaSlugs } from "./validation";

/** Helper hook to get the certificate details from the query */
export const useGetCertificateDetails = (pastedToken?: string) => {
    const token = useAppSelector(controlSelectorService.selectToken);
    const skip = useMemo(() => !pastedToken && !token, [pastedToken, token]);
    const query = useGetCertificateDetailsQuery({ token: pastedToken || token }, { skip });

    return query;
};

/** Helper hook to get the certificate props from the query */
export const useGetCertificateProps = (pastedSlugs?: {
    orga_slug: string | undefined;
    certificate_slug: string | undefined;
}) => {
    const slugs = useGetOrgaSlugs();
    const skip = useMemo(
        () =>
            (slugs.certificate_slug === undefined || slugs.orga_slug === undefined) &&
            (pastedSlugs?.certificate_slug === undefined || pastedSlugs?.orga_slug === undefined),
        [pastedSlugs, slugs]
    );
    const query = useGetCertificatePropsQuery(pastedSlugs || slugs, { skip });
    return query;
};
