import { CSSProperties } from "react";

import { EBadgeFormat } from "../types/enums";

export const pictureResizeToMaxSize = (maxSize: CSSProperties["width" | "height"], badgeFormat?: EBadgeFormat) => {
    switch (badgeFormat) {
        case EBadgeFormat.A4_LANDSCAPE:
        case EBadgeFormat.US_LETTER_LANDSCAPE:
            return {
                width: maxSize,
            };
        case EBadgeFormat.A4_PORTRAIT:
        case EBadgeFormat.US_LETTER_PORTRAIT:
        case EBadgeFormat.BADGE_1_TO_1:
        case EBadgeFormat.LEGACY_BADGE_3_TO_4:
        default:
            return {
                height: maxSize,
            };
    }
};
