/* eslint-disable no-console */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import type { AxiosError } from "axios";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import { enqueueSnackbar } from "notistack";

import type { IBadgesResponse } from "../../features/certificateDownload/api/queries.app";
import { updateProfilePic } from "../../features/certificateDownload/utils/Polotno/Polotno.helpers";
import { CertificateValidatorApiRoutes } from "../../features/certificateValidator/api/routes";
import { axios } from "../../lib/axios";
import { EAuthButton, EBadgeType, ESeverity } from "../../types/enums";
import checkPropsType from "../../utils/checkPropsType";
import { getHash } from "../../utils/fingerprint";
import { findRecipientImageInPolotnoProps } from "../../utils/misc";
import { removeDuplicates } from "../../utils/removeDuplicates";
import { certificatePropsApi } from "../slices/query/certificateProps";
import type { IGetCertificateArgs } from "../slices/types/certificateProps.query.types";
import type { TSocialMediaProfiles } from "../slices/types/control.types";
import type { ThunkAPIConfig } from "../store";

import type { TSentOtpToken, TValidateOtpToken, TValidateToken, TValidateTokenReturn } from "./types/validation.types";
import eventActionService from "./events";
import redirectActionService from "./redirections";
import sideActionsService from "./sideActions";

// Returns the indication which props are used. If booth props present return "polotno".
export const usedProps = (props: IBadgesResponse): EBadgeType.BADGE | EBadgeType.POLOTNO => {
    const { badgeprops, polotnoprops } = props;
    if (badgeprops && !polotnoprops) return EBadgeType.BADGE;
    if ((!badgeprops && polotnoprops) || (badgeprops && polotnoprops)) return EBadgeType.POLOTNO;
    throw new Error("Please check the response. No props type is present!");
};

/** Returns the props based by the props type indicator. */
export const getPropsFromPropsType = (
    props: IBadgesResponse
): VbDesigner.IBadgeProps | PolotnoDesigner.PolotnoBadgePropsData | null => {
    const { badgeprops, polotnoprops } = props;
    let newProps = null;
    if (badgeprops) newProps = props.badgeprops;
    if (polotnoprops) newProps = props.polotnoprops;
    if (!newProps) throw new Error("Please check the response. No props found in response.");
    return newProps;
};

type FontsElement = { fontWeight: string; fontFamily: string };

/**
 * Helper function to load an image as base64 data
 * Used in preloadImagesAndFonts
 * @param url
 * @returns
 */
const loadImageAsBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error();
    }
    const blob = await response.blob();
    return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);

        reader.readAsDataURL(blob);
    });
};

/**
 * Preloads images and fonts needed for Polotno operations, replacing image sources
 * with base64 data to ensure availability upon certificate generation. Throws errors
 * if any image or font fails to load.
 *
 * @param polotnoProps - Polotno properties containing images and font details.
 */
export const preloadImagesAndFonts = async (polotnoProps: PolotnoDesigner.PolotnoBadgePropsData): Promise<void> => {
    const elements = polotnoProps.pages[0].children || [];
    const background = polotnoProps.pages[0].background as string;

    const mappedFonts: Array<FontsElement> = elements
        .slice()
        .filter((element) => element.type === "text")
        .map((filteredElement) => ({
            fontWeight: filteredElement.fontWeight || "",
            fontFamily: filteredElement.fontFamily || "",
        }));

    const uniqueFontsMapping = removeDuplicates<FontsElement>(mappedFonts);

    // Preload fonts from attached polotnoProps
    const fontsToPreload = uniqueFontsMapping
        .map((font) =>
            font.fontFamily !== ""
                ? new Promise<void>(() => {
                      // Using 16px as a placeholder size to ensure the font is loaded.
                      // The actual size is not important for this operation.
                      document.fonts.load(`${font.fontWeight} 16px ${font.fontFamily}`);
                  })
                : null
        )
        .filter((item) => item === null);

    const imagesToPreload: Promise<void>[] = [];

    const mappedImages = elements.filter(
        (element) =>
            element.type === "image" ||
            (element.type === "svg" && !element.src?.startsWith("data:image/svg+xml;base64,"))
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const imageElement of mappedImages) {
        const promise = loadImageAsBase64(imageElement.src as string)
            .then((base64) => {
                // Replace the image source with the base64 data
                imageElement.src = base64;
            })
            .catch((error) => {
                // If loading fails, reject the promise
                throw new Error(`Failed to load image with ID ${imageElement.id}: ${error}`);
            });
        imagesToPreload.push(promise);
    }

    if (background && !background.startsWith("data:image/svg+xml;base64,")) {
        const backgroundToPreload = loadImageAsBase64(background)
            .then((base64) => {
                // Replace the background source with the base64 data
                // eslint-disable-next-line no-param-reassign
                polotnoProps.pages[0].background = base64;
            })
            .catch((error) => {
                // If loading fails, reject the promise
                throw new Error(`Failed to load background image: ${error}`);
            });

        // Add the background image promise to the array of promises to await
        imagesToPreload.push(backgroundToPreload);
    }

    await Promise.all([...imagesToPreload, ...fontsToPreload]);
};

const setToken = createAction<string>("validation/setToken");

/**
 * Send token to validate it and persist the response to set the token validation into the control state and the certificate into the certificate state.
 */
const validateToken = createAsyncThunk<TValidateTokenReturn, TValidateToken, ThunkAPIConfig>(
    "validation/validateToken",
    async (args: TValidateToken, thunkApi): Promise<TValidateTokenReturn> => {
        const state = thunkApi.getState();
        const dispatch = thunkApi.dispatch;
        const { orga_slug, certificate_slug } = state.control.slugs;
        const res = await axios
            .get<ITokenValidationResponse>(
                CertificateValidatorApiRoutes.validateToken(orga_slug || "", certificate_slug || "", args.token || "")
            )
            .then((response) => response.data)
            .catch((err: AxiosError) => {
                console.log(err.response);
                if (err.response?.status === 402)
                    dispatch(
                        redirectActionService.doRedirect({
                            reason: "insufficient_funds",
                        })
                    );
                else
                    dispatch(
                        redirectActionService.doRedirect({
                            reason: "no_data",
                            route: `/${orga_slug}/${certificate_slug}`,
                        })
                    );
                return null;
            });
        if (!res) {
            return {
                access: {
                    severity: ESeverity.ERROR,
                    message: "Unspecified error",
                    userEmail: "",
                },
                certificates: [],
            };
        }
        if (res.data.length === 0) {
            dispatch(
                redirectActionService.doRedirect({
                    reason: "no_data",
                    route: `/${orga_slug}/${certificate_slug}`,
                })
            );
            return {
                access: {
                    severity: ESeverity.ERROR,
                    message: "Token invalid",
                    userEmail: "",
                },
                certificates: [],
            };
        }

        dispatch(setToken(args.token));

        const propApi = certificatePropsApi.endpoints.getCertificateProps.select({
            orga_slug: orga_slug || "",
            certificate_slug: certificate_slug || "",
        })(state);

        const { data: props } = propApi;
        let bundledCertificatesWithImage: Data[] = [];

        const clonedProps = cloneDeep(props);

        if (clonedProps) {
            /**
             * When we generate the certificate image initially, we have to visually disable the profile picture. In order to allow for social media images & manual image upload we have
             * to replace the image with an "empty image" (opacity 0, size: 1px x 1px). We cannot use setting "visible: false" because then the option for manual image upload will not
             * be shown.
             */
            if (
                checkPropsType(getPropsFromPropsType(clonedProps), usedProps(clonedProps), "polotno") &&
                clonedProps.polotnoprops
            ) {
                await preloadImagesAndFonts(clonedProps.polotnoprops).catch((error) => {
                    enqueueSnackbar(i18next.t("common.alerts.messages.download_error") as string, {
                        variant: "error",
                    });
                    console.error(error, { orga_slug, certificate_slug });
                    throw error;
                });

                const profilePicture = await findRecipientImageInPolotnoProps(
                    clonedProps.polotnoprops as PolotnoDesigner.PolotnoBadgePropsData
                );

                if (profilePicture?.visible === true)
                    await updateProfilePic(
                        clonedProps.polotnoprops as PolotnoDesigner.PolotnoBadgePropsData,
                        profilePicture,
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC"
                    );
            }

            bundledCertificatesWithImage = res.data.map((currentCertificate) => {
                const certificate: Data = {
                    ...currentCertificate,
                    badge_is_baked: currentCertificate.stats_download_count > 0,
                    badge_props_type: usedProps(clonedProps),
                    badge_props: getPropsFromPropsType(clonedProps) || undefined,
                    badge_image_url: currentCertificate.badge_image_url || null,
                    pdf_blob_url: currentCertificate.pdf_blob_url || null,
                    profile_picture_attached: currentCertificate.stats_download_count > 0,
                };

                if (!certificate.badge_image_url)
                    thunkApi.dispatch(sideActionsService.setFoundNewCertificate(certificate));

                return certificate;
            });
        } else bundledCertificatesWithImage = res.data;

        const splittedResponse: TValidateTokenReturn = {
            access: {
                severity: res.severity,
                message: res.message,
                userEmail: res.data[0].recipient_email,
            },
            certificates: bundledCertificatesWithImage,
        };
        thunkApi.dispatch(eventActionService.getEventDetails());
        return splittedResponse;
    }
);

/** Post the backend to send the OTP token to user email address */
const sendOtpToken = createAsyncThunk<EAuthButton, TSentOtpToken>(
    "validation/sendOtpToken",
    async (args: TSentOtpToken): Promise<EAuthButton.EMAIL> => {
        axios
            .post(CertificateValidatorApiRoutes.sendconfirmationCode, {
                type: "participant",
                token: args.config.token,
            })
            .then((res) => {
                args.successCallback(res.data.email);
            })
            .catch(() => {
                args.failedCallback();
            });
        return EAuthButton.EMAIL;
    }
);

/** Validate the OTP token which seted by the user. */
const validateOtpToken = createAsyncThunk<void, TValidateOtpToken, ThunkAPIConfig>(
    "validation/validateOtpToken",
    async (args: TValidateOtpToken, thunkApi): Promise<void> => {
        await axios
            .put(CertificateValidatorApiRoutes.sendconfirmationCode, args.config)
            .then(async () => {
                const email = thunkApi.getState().control.userEmail;
                // hash mail and store in local storage
                const hashedMail = await getHash(email);
                localStorage.setItem("email_access_token", hashedMail);
                args.successCallback();
            })
            .catch(() => {
                args.failedCallback();
            });
    }
);

const setSocialMediaProfile = createAction<TSocialMediaProfiles>("validation/setSocialMediaProfile");

const setOrgaSlugs = createAction<IGetCertificateArgs>("validation/setOrgaSlugs");

const validationActionService = {
    validateToken,
    setToken,
    sendOtpToken,
    validateOtpToken,
    setSocialMediaProfile,
    setOrgaSlugs,
};

export default validationActionService;
