import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

import useTranslation from "i18n/hooks/useTranslation";

import Typography from "components/shared/Typography";

import { HIDE_COOKIES_MODAL_ROUTES } from "./CookiesBanner.constants";
import { acceptCookiesAction, declineCookieAction } from "./CookiesBanner.helpers";
import * as styles from "./CookiesBanner.styles";
import CookieTabs from "./CookiesTabs";

export default function CookieBanner() {
    const t = useTranslation();

    if (HIDE_COOKIES_MODAL_ROUTES.includes(window.location.pathname)) {
        return null;
    }

    return (
        <CookieConsent
            location="none"
            cookieName="cookieconsent"
            cookieValue="essential"
            expires={30}
            overlay
            overlayStyle={styles.consentOverlay}
            style={styles.consentMainStyles as React.CSSProperties}
            buttonText={t("common.cookie_banner.button_area.buttonAcceptText")}
            buttonWrapperClasses="cookie-buttons-right"
            buttonStyle={styles.consentButton}
            enableDeclineButton
            declineButtonText={t("common.cookie_banner.button_area.buttonDeclineText")}
            declineButtonStyle={styles.consentDeclineButton}
            onAccept={acceptCookiesAction}
            onDecline={declineCookieAction}
        >
            <Typography variant="h3" style={styles.bannerHeader as React.CSSProperties}>
                {t("common.cookie_banner.text_area.header")}
            </Typography>
            <Typography>{t("common.cookie_banner.text_area.banner_text_p1")} </Typography>
            <Typography>
                {t("common.cookie_banner.text_area.banner_text_p2")}{" "}
                <Link target="_blank" to={{ pathname: "/privacy", hash: "cookies" }} style={styles.bannerLink}>
                    {t("common.cookie_banner.text_area.link_text")}
                </Link>
            </Typography>
            <CookieTabs />
        </CookieConsent>
    );
}
