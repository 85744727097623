import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { selectStore } from "../store";

const selectNotificationStore = createDraftSafeSelector(selectStore, (store) => store.notification);

const selectCurrentNotification = createDraftSafeSelector(
    selectNotificationStore,
    (notification) => notification.notification
);

const notificationSelectorService = {
    selectNotificationStore,
    selectCurrentNotification,
};

export default notificationSelectorService;
