import { theme } from "utils/Theme/theme";

import { Box, styled } from "@mui/material";

const FooterContainer = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: theme.spacing(6, 0, 0, 0),
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
        gap: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
        marginTop: "80px",
        paddingTop: "1rem",
        justifyContent: "center",
        flexDirection: "column",
    },
}));

export default FooterContainer;
