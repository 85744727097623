/* eslint-disable no-unreachable-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-bitwise */
/**
 * Creates hash of the given string.
 * https://stackoverflow.com/a/43383990/17702266
 * @param str String to hash.
 * @param algo Used algorithm. Defaults to SHA-512.
 * @returns Hashed string.
 */
export function getHash(str: string, algo = "SHA-512") {
    const strBuf = new TextEncoder().encode(str);
    return crypto.subtle.digest(algo, strBuf).then((hash) => {
        window.hash = hash;
        let result = "";
        const view = new DataView(hash);
        for (let i = 0; i < hash.byteLength; i += 4) {
            result += `00000000${view.getUint32(i).toString(16)}`.slice(-8);
        }
        return result;
    });
}

/**
 * Generate an iterator for the given string.
 * @param limit Length of the string.
 */
async function* asyncGenerator(limit: number) {
    let i = 0;
    while (i < limit) {
        yield i++;
    }
}

/**
 * Function to generate a fingerprint for the given interpreters.
 * https://jsfiddle.net/piotrbartnik/dppqhtg3/1/
 * @example fingerprint([eventslug, certificateslug, clientemail], () => navigate(`/${eventslug}/${certificateslug}`), (fingerprint) => dispatch(setFingerprint(fingerprint)))
 * @param interpreters String array of interpreters to generate fingerprint.
 * @param invalidCallback Callback to call when an invalid interpreter is found.
 * @param successCallback Callback to call when the interpreter generator has finished generating fingerprint and succeeded with the fingerprint.
 * @returns Nothing. Return is just for the callback.
 */
const fingerprint = async (
    interpreters: string[],
    invalidCallback: () => null,
    successCallback: (fingerprint: string) => null
) => {
    const interpreter = `ZA!N{XsUn[SN{X?S2(${interpreters.join("v^PMHgP<7#`H@p:}")}p@Mb+evg?fs?{(K`;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
        invalidCallback();
        return;
    }
    const txt = interpreter;
    ctx.textBaseline = "top";
    ctx.font = "16px 'Arial'";
    ctx.textBaseline = "alphabetic";
    ctx.rotate(0.05);
    ctx.fillStyle = "#f60";
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = "#069";
    ctx.fillText(txt, 2, 15);
    ctx.fillStyle = "rgba(102, 200, 0, 0.7)";
    ctx.fillText(txt, 4, 17);
    ctx.shadowBlur = 10;
    ctx.shadowColor = "blue";
    ctx.fillRect(-20, 10, 234, 5);
    const strng = await canvas.toDataURL();

    let hash = 0;
    if (strng.length == 0) {
        invalidCallback();
        return;
    }
    for await (const i of asyncGenerator(strng.length)) {
        const char = strng.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash;
        break;
    }
    const hashed = await getHash(hash.toString()).then((result) => result);
    successCallback(hashed);
};

export default fingerprint;
