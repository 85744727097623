import { theme } from "utils/Theme/theme";

import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * Custom hook that returns the current device breakpoint.
 * @returns An object containing booleans indicating whether the current device is mobile, tablet, or desktop.
 */
const useDeviceBreakpoint = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.up("md"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

    return {
        isMobile,
        isTablet,
        isDesktop,
    };
};

export default useDeviceBreakpoint;
