import { ICertificatePropsResult } from "features/certificateDownload/api/queries.app";
import { cloneDeep } from "lodash";
import type { StoreType } from "polotno/model/store";
import QRCode from "qrcode-svg";

/**
 * Function to escape special characters in a string
 * @param {string} string
 * @returns {string} escaped string
 * @example
 * escapeRegExp("Hello. World!"); // "Hello\. World\!"
 */
export const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

/**
 * Function to create RegExp for searching text
 * @param {string} searchingText
 * @returns {RegExp} RegExp for searching text
 * @example
 * createRegExpForText("Hello. World!"); // /{{Hello\. World\!}}/g
 */
export const createRegExpForText = (searchingText: string): RegExp => {
    const escapedText = escapeRegExp(searchingText);
    // If the text is a number, we want to match only whole words.
    if (/^\d+$/.test(searchingText)) {
        return new RegExp(`{{\\b${escapedText}\\b}}`, "g");
    }
    return new RegExp(`{{${escapedText}}}`, "g");
};

/**
 * Function to create base64 encoded QR code
 * @param {string} link
 * @param {number} width
 * @param {number} height
 *
 * @returns {string} base64 encoded QR code as svg
 */
export const createQRCode = (link: string, width = 102, height = 102): string => {
    const qrCode = new QRCode({
        content: link,
        padding: 0,
        width,
        height,
        color: "#000000",
        background: "#ffffff",
        ecl: "M",
    });

    function b64EncodeUnicode(str: string): string {
        return btoa(
            encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(Number(`0x${p1}`)))
        );
    }

    const svgString = qrCode.svg();
    const base64 = b64EncodeUnicode(svgString);

    return `data:image/svg+xml;base64,${base64}`;
};

/**
 * Generates a random id for Polotno elements.
 * @returns {string} a random string of 10 characters.
 * @example
 * generateId(); // "aBcDeFgHiJ"
 */
export const generateId = (): string => {
    const possibleCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let id = "";
    for (let i = 0; i < 10; i++) {
        id += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
    }
    return id;
};

/**
 * Export a thumbnail with a given width and heigth.
 * Call this with await in an async function
 * @param dataURL
 * @param width
 * @param height
 * @returns
 */
export const exportThumbnailWithPolotno = async (dataURL: string, width: number, height: number): Promise<string> => {
    const canvas = document.createElement("canvas");

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        const badgeImg = new Image();

        badgeImg.onload = () => {
            const badgeWidth = badgeImg.width * (canvas.height / badgeImg.height);
            ctx.drawImage(badgeImg, canvas.width / 2 - badgeWidth / 2, 0, badgeWidth, canvas.height);
            setTimeout(() => {
                resolve(canvas.toDataURL());
            }, 200);
        };
        badgeImg.src = dataURL;
    });
};

/**
 * Converts an ArrayBuffer to a Base64-encoded string.
 * @param {ArrayBuffer} buffer - The ArrayBuffer to be converted.
 * @returns {string} The Base64-encoded string representation of the input ArrayBuffer.
 */
export function arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

/**
 * Converts a Base64-encoded string to an ArrayBuffer.
 * @param {string} base64 - The Base64-encoded string to be converted.
 *@returns {ArrayBuffer} The ArrayBuffer representation of the input Base64-encoded string.
 */
export function base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}
/**
 * Get the profile picture element from the Polotno object.
 * @param polotnoProps
 * @returns
 */
export const getCustomElement = (
    polotnoProps: PolotnoDesigner.PolotnoBadgePropsData,
    customType: PolotnoDesigner.CustomFieldType
): PolotnoDesigner.PolotnoObject | undefined => {
    let polotnoElement;
    polotnoProps.pages.forEach((page) => {
        page.children.forEach((child) => {
            if (child.custom && child.custom.type === customType) {
                polotnoElement = child;
            }
        });
    });
    return polotnoElement;
};

/**
 * Update the profile picture element in the Polotno object.
 * @param polotnoProps
 * @param profilePicElement
 * @param src
 * @returns
 */
/* eslint-disable no-param-reassign */
export const updateProfilePic = (
    polotnoProps: PolotnoDesigner.PolotnoBadgePropsData,
    profilePicElement: PolotnoDesigner.PolotnoObject | undefined,
    src: string
) => {
    if (!profilePicElement) return;
    // We have to delete the element if it has the svg type
    if (profilePicElement.type === "svg") {
        const imageProps = {
            id: generateId(),
            type: "image",
            src,
            width: profilePicElement.width,
            height: profilePicElement.height,
            x: profilePicElement.x,
            y: profilePicElement.y,
            blurEnabled: profilePicElement.blurEnabled,
            blurRadius: profilePicElement.blurRadius,
            brightnessEnabled: profilePicElement.brightnessEnabled,
            brightness: profilePicElement.brightness,
            shadowEnabled: profilePicElement.shadowEnabled,
            shadowColor: profilePicElement.shadowColor,
            shadowBlur: profilePicElement.shadowBlur,
            shadowOpacity: profilePicElement.shadowOpacity,
            cornerRadius: profilePicElement.width! / 2, // Make the image round
            visible: true,
            custom: {
                type: "recipient_image",
            },
        };

        // Remove svg profilePicElement from polotnoProps
        polotnoProps.pages[0].children = polotnoProps.pages[0].children.filter((el) => el.id !== profilePicElement?.id);

        // Add image profilePicElement
        polotnoProps.pages[0].children.push(imageProps);
        profilePicElement = getCustomElement(polotnoProps, "recipient_image");
    } else {
        profilePicElement.src = src;
        profilePicElement.cornerRadius = profilePicElement.width! / 2; // Make the image round
    }
};

/**
 * This function is used to determine the properties of the Polotno object based on the payload received.
 * The payload contains information about a certificate's recipient, like the LinkedIn or Facebook profile.
 * It modifies the profile picture and the recipient's name on the certificate accordingly.
 *
 * @param {ICertificatePropsResult} payload - The payload object which contains profile data.
 * @param {PolotnoDesigner.PolotnoBadgePropsData} polotnoPropsData - The initial Polotno object properties.
 *
 * @returns {PolotnoDesigner.PolotnoBadgePropsData} - The updated Polotno object properties.
 *
 * @remarks
 * This function will update the following properties if the recipient has a visible profile picture:
 * - recipient's name
 * - profile picture
 *
 * It uses the highest resolution image available for the profile picture.
 */
export const setProfilePictureInPolotnoProps = (
    payload: ICertificatePropsResult,
    polotnoPropsData: PolotnoDesigner.PolotnoBadgePropsData
) => {
    const polotnoProps = cloneDeep(polotnoPropsData);

    const profilePicElement = getCustomElement(polotnoProps, "recipient_image");

    if (!profilePicElement || !profilePicElement?.visible) {
        return polotnoProps;
    }

    if (payload?.linkedin) {
        // Get linkedin profile information and add them to the badge props.
        const profile = payload.linkedin;

        let linkedinPic;
        if (profile.profilePicture) {
            const linkedinPicElements = profile.profilePicture["displayImage~"].elements;
            // Get image with highest resolution
            linkedinPic = linkedinPicElements[linkedinPicElements.length - 1].identifiers[0].identifier;
            // Update linkedin picture ->
            updateProfilePic(polotnoProps, profilePicElement, linkedinPic);
        }
    } else if (payload.facebook) {
        // Get profile information and add them to the badge props
        const profile = payload.facebook;
        const facebookPic = profile.picture;

        // Load image
        updateProfilePic(polotnoProps, profilePicElement, facebookPic);
    }

    return polotnoProps;
};

/**
 * Get text width from canvas
 * @param {PolotnoDesigner.PolotnoObject} element - element
 * @param {number} documentWidth - The template's width
 * @returns {{width: number, fontSize: number, x: number}} - text width, appropriate font size, updated x, y, and container width
 */
export const getRecipientNameDimensionsFromCanvas = (element: PolotnoDesigner.PolotnoObject, documentWidth: number) => {
    const tempCanvas = document.createElement("canvas");
    const ctx = tempCanvas.getContext("2d");

    let { width, x, fontSize } = element;

    if (width === undefined || x === undefined || fontSize === undefined) {
        throw new Error("Width, x, and fontSize are required");
    }

    const initialX = x; // Save the initial value of x

    if (!ctx || !element.text) {
        return { width, fontSize, x };
    }

    // !IMPORTANT: Font must be loaded before calculating text width (we do it in the validation.ts)
    const calculateTextWidth = (text: string, _fontSize: number, letterSpacing: number) => {
        ctx.font = `${element.fontWeight} ${_fontSize}px ${element.fontFamily}`;

        return Math.ceil(ctx.measureText(text).width + (letterSpacing ?? 0) * (text.length - 1));
    };

    let textWidth = calculateTextWidth(element.text, fontSize, element.letterSpacing ?? 0);

    // If text width is less than width of the container we don't need to do anything
    if (textWidth <= width) {
        return { width, fontSize, x };
    }

    let maxAvailableWidth = 0;
    const leftSpace = parseFloat(x.toFixed(2));
    const rightSpace = parseFloat((documentWidth - (x + width)).toFixed(2));

    switch (element.align) {
        case "left":
            maxAvailableWidth = documentWidth - x;
            break;
        case "right":
            maxAvailableWidth = x + width;
            break;
        case "center":
        case "justify":
            maxAvailableWidth = Math.min(leftSpace, rightSpace) * 2 + width;
            break;
        default:
            break;
    }

    // If text width is less than max available width we only need to change x
    // and increase width of the container
    if (textWidth <= maxAvailableWidth) {
        if (element.align === "right") {
            x = initialX + width - textWidth;
        } else if (element.align === "center" || element.align === "justify") {
            x = leftSpace <= rightSpace ? (maxAvailableWidth - textWidth) / 2 : initialX - (textWidth - width) / 2;
        }
        return { width: textWidth, fontSize, x };
    }

    // Decrease font size until text width is less than max available width
    // Using binary search for optimization
    let start = 0;
    let end = fontSize;

    while (start <= end) {
        const mid = Math.floor((start + end) / 2);
        textWidth = calculateTextWidth(element.text, mid, element.letterSpacing ?? 0);

        if (textWidth <= maxAvailableWidth) {
            start = mid + 1;
            fontSize = mid;
            width = textWidth;

            if (element.align === "right") {
                x = maxAvailableWidth - textWidth;
            } else if (element.align === "center" || element.align === "justify") {
                x = leftSpace <= rightSpace ? (maxAvailableWidth - textWidth) / 2 : documentWidth - maxAvailableWidth;
            }
        } else {
            end = mid - 1;
        }
    }

    return { width, fontSize, x };
};

export interface ISecondPageMeasurements {
    x: number;
    idContentY: number;
    validationContentY: number;
    fontSize: number;
}
/**
 * Get measures for pdf-lib to draw id number and validation url
 * @param {StoreType} store
 * @returns {ISecondPageMeasurements} measures for pdf-lib
 */
export const getIdNumberAndValidationUrlMeasurements = (store: StoreType, isValidationPageEnabled: boolean) => {
    if (!store.pages[1]) throw new Error("Second page is not found");
    const getElementByName = (name: string) => store.pages[1].children.find((item) => item.name === name);

    const issueDateTitle = getElementByName("issueDateTitle");
    const issueDateContent = getElementByName("issueDateContent");
    const idTitle = getElementByName("idTitle");
    const validationTitle = getElementByName("validationTitle");

    const distanceBetweenTitleAndContent = issueDateContent.y - issueDateTitle.y;

    // We have to add 50% more margin to the distance between title and content
    // Because of difference between pdf-lib and polotno
    const extraMargin = distanceBetweenTitleAndContent * 0.5;

    const idContentY: number = isValidationPageEnabled ? idTitle.y + distanceBetweenTitleAndContent + extraMargin : 0;
    const validationContentY: number = isValidationPageEnabled
        ? validationTitle.y + distanceBetweenTitleAndContent + extraMargin
        : 0;
    const fontSize: number = issueDateTitle.fontSize;
    const x: number = issueDateTitle.x;

    return { x, idContentY, validationContentY, fontSize };
};

type BadgeDimensions = { width: number; height: number };

// Legacy formats that came from the old designer. They are used only for the old designs which were not be changed.
// Exception: BADGE_1_TO_1 is used for the new designs as well. It's the format for the badges.
// LEGACY_BADGE_3_TO_4 is used for some old events in the local environment.
export const REDUCED_FORMATS: { [key: string]: BadgeDimensions } = {
    A4_LANDSCAPE: { width: 504.87, height: 357 },
    A4_PORTRAIT: { width: 357, height: 504.87 },
    US_LETTER_LANDSCAPE: { width: 462, height: 357 },
    US_LETTER_PORTRAIT: { width: 357, height: 462 },
    LEGACY_BADGE_3_TO_4: { width: 357, height: 476 },
};
// Full formats for the certificates. They are used for the full size of the designs. All old certificates will be converted to these formats in the beginning of the design process and then they will be saved in the new format. DPI = 72
export const DEFAULT_FORMATS: { [key: string]: BadgeDimensions } = {
    BADGE_1_TO_1: { width: 357, height: 357 },
    A4_LANDSCAPE: { width: 842.4, height: 597.6 },
    A4_PORTRAIT: { width: 597.6, height: 842.4 },
    US_LETTER_LANDSCAPE: { width: 792, height: 612 },
    US_LETTER_PORTRAIT: { width: 612, height: 792 },
    LEGACY_BADGE_3_TO_4: { width: 597.6, height: 796.8 },
};

/**
 * Get badge dimensions for a given format (default or reduced)
 *
 * @param {VbDesigner.BadgeFormat} formatName - one of the available formats
 * @param sizeType - "default" or "reduced" (Optional)
 * @returns {BadgeDimensions} the dimensions of the badge
 */
export const getBadgeFormatSize = (
    formatName: VbDesigner.BadgeFormat,
    sizeType: "default" | "reduced" = "default"
): BadgeDimensions => {
    const format = DEFAULT_FORMATS[formatName];
    if (!format) {
        return DEFAULT_FORMATS.A4_PORTRAIT;
    }

    if (sizeType === "reduced") {
        return REDUCED_FORMATS[formatName] || format;
    }
    return format;
};

/**
 * Check if the current badge format is 'full' or 'reduced'
 * @returns {"default" | "reduced"} type of the badge format
 * @throws {Error} if no format type is found for the current badge
 */
export const checkBadgeFormat = (polotnoProps: PolotnoDesigner.PolotnoBadgePropsData): "default" | "reduced" => {
    // Assuming there is a way to get current badge width and height
    const currentWidth = polotnoProps.width;
    const currentHeight = polotnoProps.height;

    // Check if the dimensions match any entry in the DEFAULT_FORMATS
    const isFull = Object.values(DEFAULT_FORMATS).some(
        (format) => format.width === currentWidth && format.height === currentHeight
    );

    if (isFull) {
        return "default";
    }

    // Check if the dimensions match any entry in the REDUCED_FORMATS
    const isReduced = Object.values(REDUCED_FORMATS).some(
        (format) => format.width === currentWidth && format.height === currentHeight
    );

    if (isReduced) {
        return "reduced";
    }

    throw new Error("No format type found for the current badge dimensions");
};
