import { CSSProperties, FC } from "react";

import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { IconButton } from "@mui/material";

import { theme } from "../../utils/Theme/theme";

type ReloadIconProps = {
    title?: string;
    fontSize?: CSSProperties["fontSize"];
};

const ReloadIcon: FC<ReloadIconProps> = ({ title, fontSize = undefined }) => {
    const handleReload = () => {
        window.location.reload();
    };

    return (
        <IconButton title={title} onClick={handleReload}>
            <ReplayRoundedIcon
                style={{
                    transform: "rotate(225deg) rotateX(180deg)",
                    cursor: "pointer",
                    color: theme.palette.grey[700],
                    ...(fontSize ? { fontSize } : {}),
                }}
            />
        </IconButton>
    );
};

export default ReloadIcon;
