import React from "react";

import useTranslation from "i18n/hooks/useTranslation";
import { socialMediaButtons } from "utils/commonStyles";

import EmailIcon from "@mui/icons-material/Email";
import { Box } from "@mui/material";

import Button from "components/shared/Button";

import * as styles from "./AddEmailButton.styles";

const AddEmailButton: React.FC<IAddEmailButton> = ({ ...props }) => {
    const t = useTranslation();
    const buttonContainerStyle = React.useMemo(() => socialMediaButtons(true), []);

    return (
        <Box sx={buttonContainerStyle}>
            <Button
                variant="contained"
                startIcon={<EmailIcon sx={{ height: 24, width: 24 }} />}
                sx={styles.emailButton}
                {...props}
            >
                {t("certificate.authForm.form.button_email.text")}
            </Button>
        </Box>
    );
};

export default AddEmailButton;
