/**
 * Returns a boolean based on location if user is currently on badge route.
 *
 * It will only check if the path have more than 2 path segments and if more than check if the third segment is 'badge' (case-sensitive).
 * @example pathname -> /46asda/djasdl returns false
 * @example pathname -> /46asda/djasdl/badge returns true
 * @example pathname -> /46asda/badge-badge-budge returns false
 * @example pathname -> /46asda/badge-badge-budge/badge returns true
 * @example pathname -> /46asda/badge-badge-budge/badges returns false
 * @example pathname -> /46asda/badge-badge-budge/baDGe returns false
 * @example pathname -> /46asda/badge-badge-budge/46546 returns false
 * @example pathname -> /46asda/badge-badge-budge/46546/badge returns false
 *
 * @return {boolean} Returns if user is on route badge
 */
const isOnBadgeRoute = (): boolean => {
    const location = window.location.pathname; // /:orga-slug:/:cert-slug:/badge

    // Destructur location path name to array
    const locationMap = location.split("/").filter((el) => el !== ""); // [":orga-slug:", ":cert-slug:", "badge"]

    // If user location is /badge - route the locationMap should have 3 items
    if (locationMap.length > 2) return locationMap[2] === "badge";
    return false;
};

export default isOnBadgeRoute;
