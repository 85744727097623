import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { EAuthButton } from "../../types/enums";
import type { ThunkAPIConfig } from "../store";

import type { TLoadingStateAction, TSocialMediaStateAction } from "./types/sideActions.types";

const setVerifyMethod = createAction<EAuthButton>("setVerifyMethod");

const setBadgeParseLoadingState = createAction<boolean>("setBadgeParseLoadingState");

const goBackToDashboard = createAction("goBackToDashboard");

const setLoadingState = createAction<TLoadingStateAction>("setLoadingState");

/** Set the last selected certificate when the user comes from a share redirection */
const fromSharedRedirection = createAsyncThunk<number, string, ThunkAPIConfig>(
    "fromSharedRedirection",
    async (cert_id, thunkAPI) => {
        const { certificates } = thunkAPI.getState();
        let preselectedCertificate = certificates.data.map((cert) => cert.certificate_id).indexOf(cert_id);
        if (preselectedCertificate === -1) {
            preselectedCertificate = 0;
        }
        return preselectedCertificate;
    }
);

const shareToSocialMedia = createAction<TSocialMediaStateAction>("shareToSocialMedia");

const setFoundNewCertificate = createAction<Data>("badge/setFoundNewCertificate");

const removeFoundNewCertificates = createAction<Array<string>>("badge/removeFoundNewCertificates");

const setNewCertificatesInQueue = createAction<number>("setNewCertificatesInQueue");

const setNewCertificatesFinished = createAction<number>("setCertificatesFinished");

const setNewCertificateLoadingState = createAction<"combine" | "build" | "bake" | null>(
    "setNewCertificateLoadingState"
);

const setNewCertificateLoadingLoopTime = createAction<{ type: "start" | "end"; ms: number }>(
    "setNewCertificateLoadingLoopTime"
);

const setDownloadProgress = createAction<number>("setDownloadProgress");

const setDownloadProgressTotal = createAction<number>("setDownloadProgressTotal");

const resetDownloadProgress = createAction("resetDownloadProgress");

const sideActionsService = {
    setDownloadProgress,
    setDownloadProgressTotal,
    resetDownloadProgress,
    setVerifyMethod,
    setBadgeParseLoadingState,
    goBackToDashboard,
    setLoadingState,
    fromSharedRedirection,
    shareToSocialMedia,
    setFoundNewCertificate,
    removeFoundNewCertificates,
    setNewCertificatesInQueue,
    setNewCertificatesFinished,
    setNewCertificateLoadingState,
    setNewCertificateLoadingLoopTime,
};

export default sideActionsService;
