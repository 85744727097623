import type { NavigateFunction } from "react-router-dom";
import type { IFacebookProfile, ILinkedinProfile } from "features/certificateValidator/types";
import { axios } from "lib/axios";

/**
 * Retrieve linkedin profile info and redirects back to badge page
 * @param authCode
 * @param orgaSlugValue
 * @param eventSlugValue
 * @param tokenValue
 * @param setLinkedinProfile
 * @param navigate
 * @param pushSnack
 * @param t i18n translate function
 * @param openShareModal query for badge page, if equal to `share_modal`
 * the share to linkedin modal should be open on page load
 */
export const getLinkedInAccessToken = (
    authCode: string,
    orgaSlugValue: string,
    eventSlugValue: string,
    certificate_id: string,
    tokenValue: string,
    setLinkedinProfile: (profile: ILinkedinProfile) => void,
    navigate: NavigateFunction,
    pushSnack: ({ title, body, type }: { title: string; body: string; type: TAlertSevirity }) => void,
    t: (key: string) => string,
    openShareModal: string | undefined
) => {
    axios
        .get(`/api/public/linkedin_access_token?code=${authCode}&orga_slug=${orgaSlugValue}`)
        .then((res1) => {
            // Get profile data
            axios
                .get(
                    `/api/public/linkedin_profile?access_token=${res1.data.access_token}&virtualbadge_token=${tokenValue}`
                )
                .then((res2) => {
                    setLinkedinProfile(res2.data);
                    // Log auth type and check if authenticated person is the same as from the initial authetication
                    axios
                        .post(
                            `/api/public/participant?orga_slug=${orgaSlugValue}&event_slug=${eventSlugValue}&certificate_id=${certificate_id}&token=${tokenValue}&stats_auth_type=linkedin`,
                            {
                                linkedin_profile_data: {
                                    id: res2.data.id,
                                    first_name: res2.data.localizedFirstName,
                                    last_name: res2.data.localizedLastName,
                                },
                            }
                        )
                        .then(() => {
                            // If Recipient passes check, go on
                            localStorage.setItem("linkedin_access_token", res1.data.access_token);
                            navigate(
                                `/${orgaSlugValue}/${eventSlugValue}/badge?comes_from_social_media=true`.concat(
                                    openShareModal === "share_modal" ? "&share_modal=linkedin" : ""
                                )
                            );
                        })
                        .catch((err) => {
                            console.error("ERROR 3", err);
                            // If a different account/device is used to authenticate with the same token, prevent access to badge page
                            navigate(`/${orgaSlugValue}/${eventSlugValue}/`);
                            if (err.response.data.detail === "token-stale-linkedin")
                                pushSnack({
                                    title: t("certificate_download.alerts.token_stale_title"),
                                    body: t("certificate_download.alerts.token_stale_message"),
                                    type: "error",
                                });
                            else
                                pushSnack({
                                    title: t("common.alerts.messages.unexpected_error_title"),
                                    body: t("common.alerts.messages.unexpected_error_message"),
                                    type: "error",
                                });
                        });
                })
                .catch((err) => console.error("ERROR 2", err));
        })
        .catch((err) => {
            console.error("ERROR", err);
            navigate(`/${orgaSlugValue}/${eventSlugValue}/`);
            pushSnack({
                title: t("common.alerts.messages.unexpected_error_title"),
                body: t("common.alerts.messages.unexpected_error_message"),
                type: "error",
            });
        });
};

/**
 * Get facebook social profile info and redirects to badge page
 * @param authCode
 * @param orgaSlugValue
 * @param eventSlugValue
 * @param tokenValue
 * @param setFacebookProfile
 * @param navigate
 * @param pushSnack
 * @param t
 */
export const getFacebookAccessToken = (
    authCode: string,
    orgaSlugValue: string,
    eventSlugValue: string,
    certificate_id: string,
    tokenValue: string,
    setFacebookProfile: (profile: IFacebookProfile) => void,
    navigate: (to: string) => void,
    pushSnack: ({ title, body, type }: { title: string; body: string; type: TAlertSevirity }) => void,
    t: (key: string) => string
) => {
    axios
        .get(`/api/public/facebook_access_token?code=${authCode}&orga_slug=${orgaSlugValue}`)
        .then((tokenResponse) => {
            localStorage.setItem("facebook_access_token", tokenResponse.data.access_token);

            // Get profile picure
            axios
                .get(`https://graph.facebook.com/v8.0/me?access_token=${tokenResponse.data.access_token}`)
                .then((accountResponse) => {
                    setFacebookProfile(accountResponse.data);
                    axios
                        .get(
                            `https://graph.facebook.com/v8.0/me/picture?width=800&height=800&type=square&redirect=false&access_token=${tokenResponse.data.access_token}`
                        )
                        .then(() => {
                            // Log auth type and check if authenticated person is the same as from the initial authetication
                            axios
                                .post(
                                    `${process.env.API_URL}/api/public/participant?orga_slug=${orgaSlugValue}&event_slug=${eventSlugValue}&certificate_id=${certificate_id}&token=${tokenValue}&stats_auth_type=facebook`,
                                    {
                                        facebook_profile_data: {
                                            id: accountResponse.data.id,
                                            name: accountResponse.data.name,
                                        },
                                    }
                                )
                                .then(() => {
                                    // If Recipient passes check, go on
                                    navigate(`/${orgaSlugValue}/${eventSlugValue}/badge`);
                                })
                                .catch((err) => {
                                    // If a different account/device is used to authenticate with the same token, prevent access to badge page
                                    navigate(`/${orgaSlugValue}/${eventSlugValue}/`);
                                    if (err.response.data.detail === "token-stale-facebook")
                                        pushSnack({
                                            title: t("certificate_download.alerts.token_stale_title"),
                                            body: t("certificate_download.alerts.token_stale_message"),
                                            type: "error",
                                        });
                                    else
                                        pushSnack({
                                            title: t("common.alerts.messages.unexpected_error_title"),
                                            body: t("common.alerts.messages.unexpected_error_message"),
                                            type: "error",
                                        });
                                });
                        });
                });
        })
        .catch(() => {
            navigate(`/${orgaSlugValue}/${eventSlugValue}/`);
            pushSnack({
                title: t("common.alerts.messages.unexpected_error_title"),
                body: t("common.alerts.messages.unexpected_error_message"),
                type: "error",
            });
        });
};

/**
 * Retrieve twitter token and redirects to badge page
 * @param oauthToken
 * @param oauthVerifier
 * @param navigate
 * @param pushSnack
 * @param t
 */
export const getTwitterAccessToken = async (
    oauthToken: string,
    oauthVerifier: string,
    navigate: NavigateFunction,
    pushSnack: ({ title, body, type }: { title: string; body: string; type: TAlertSevirity }) => void,
    t: (key: string) => string
) => {
    const redirectionPath = localStorage.getItem("twitter_redirect_path");
    localStorage.removeItem("twitter_redirect_path");

    if (!redirectionPath) return;

    const body = {
        oauth_token: oauthToken,
        oauth_verifier: oauthVerifier,
        orga_slug: redirectionPath?.split("/")[1],
    };

    try {
        const res = await axios.post("/api/public/twitter_access_token", body);

        localStorage.setItem("twitter_oauth_token", res.data.twitter_oauth_token);
        localStorage.setItem("twitter_oauth_token_secret", res.data.twitter_oauth_token_secret);

        navigate(redirectionPath.concat("?share_modal=twitter"));
    } catch (error: any) {
        pushSnack({
            title: t("common.alerts.messages.unexpected_error_title"),
            body: error.response.statusText,
            type: "error",
        });
    }
};
