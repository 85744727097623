import { TEXT_SECONDARY, theme } from "utils/Theme/theme";

export const infosText = {
    minWidth: 160,
    [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1 / 2),
    },
};

export const stepsText = {
    minWidth: 100,
    [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1 / 2),
    },
};

export const infoRow = {
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
    },
};

export const termsOfUseText = { "& span.MuiTypography-root": { color: TEXT_SECONDARY }, maxWidth: "706px" };
