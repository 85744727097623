import { theme } from "utils/Theme/theme";

export const footerContainer = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(2),
    margin: theme.spacing(5, 0, 0, 0),
};

export const footerText = {
    color: theme.palette.grey[500],
};

export const link = {
    ...footerText,
    marginTop: theme.spacing(1),
    cursor: "pointer",
};

export const footerLogoContainer = {
    margin: theme.spacing(0),
    alignItems: "flex-start",
};
