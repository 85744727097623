import type { PropsWithChildren } from "react";

import useGetAccessToken from "./ValidationWrapper.hooks";

const ValidationWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    const { code } = useGetAccessToken();
    if (code) return null;
    return <>{children}</>;
};

export default ValidationWrapper;
