import { WHITE } from "utils/Theme/theme";

export const appBar = {
    backgroundColor: WHITE,
    padding: { xs: "0px", sm: "0px 5px ", md: "0px 60px" },
    mt: { xs: "0px", sm: "7px", md: "15px" },
};

export const ToolbarWrapper = { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" };

export const logoStyles = { maxWidth: { xs: "150px", md: "300px" }, maxHeight: 40, cursor: "pointer" };
