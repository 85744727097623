import { Helmet } from "react-helmet";

import { useBrand } from "hooks/BrandManager/BrandManager.hooks";

const SEO = () => {
    const BrandManagerRef = useBrand();
    const { pageTitle, favicon } = BrandManagerRef.getHtmlHead();

    return (
        <Helmet title={pageTitle}>
            <link rel="icon" type="image/png" href={`/${favicon}?v2`} sizes="16x16" />
            {/* 
            //* pdfkit and blob-stream are file system libraries and are not available in the browser and we had issues when trying to use node polyfills in vite so we need to load them this way
            //? they are handled this way temporary but the logic of pdf generation will be moved to the backend in the future
            */}
            {/* v0.10.0 */}
            <script async src="/scripts/pdfkit.standalone.js" />
            {/* v0.1.3 */}
            <script async src="/scripts/blob-stream.js" />
        </Helmet>
    );
};

export default SEO;
