import { format, parseISO } from "date-fns";
import { arEG, de, enGB, enUS, es, fr, nl, pt, ptBR } from "date-fns/locale";
import i18next from "i18next";

// Map the browser locale to a date-fns locale object
export const LOCALE_MAP: { [key: string]: Locale | undefined } = {
    "en-US": enUS,
    "en-GB": enGB,
    de,
    "de-DE": de,
    "de-AT": de,
    "de-CH": de,
    "de-BE": de,
    "de-LI": de,
    "de-LU": de,
    fr,
    "fr-FR": fr,
    es,
    "es-ES": es,
    "es-AR": es,
    "es-BO": es,
    "es-BZ": es,
    "es-CL": es,
    "es-CO": es,
    "es-CR": es,
    "es-CU": es,
    "es-DO": es,
    "es-EC": es,
    "es-GT": es,
    "es-HN": es,
    "es-MX": es,
    "es-NI": es,
    "es-PA": es,
    "es-PE": es,
    "es-PR": es,
    "es-PY": es,
    "es-SV": es,
    "es-UY": es,
    "es-VE": es,
    pt,
    "pt-PT": pt,
    "pt-BR": ptBR,
    ar: arEG,
    "ar-EG": arEG,
    nl,
    "nl-NL": nl,
    "nl-BE": nl,
    // Add other supported locales here
};

const monthNames: Record<string, string[]> = {
    en: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    de: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ],
};

/**
 * Function to format date according to selected format.
 *
 * @param {string} date - date to format
 * @param {string} selectedFormat - selected format
 * @param {string} locale - locale
 * @returns {string} formatted date
 * @example
 * formatDate("2021-01-01", "DD.MM.YYYY"); // "01.01.2021"
 * formatDate("2021-01-01", "DD Month YYYY"); // "01 January 2021"
 */
export const formatDate = (date: string, selectedFormat: string, locale = "en"): string => {
    if (date === null || date === undefined || date === "" || !date.includes("-")) return "";
    const [year, month, day] = date.split("-");

    // If locale like en-US then remove inclusiv and all after "-" from the string. Otherwise stay as it is.
    let cleanedLocale = locale.includes("-") ? locale.split("-")[0] : locale;

    if (cleanedLocale !== "en" && cleanedLocale !== "de") cleanedLocale = "en"; // Fallback to en if not en or de

    const formatMap = {
        DD: day,
        MM: month,
        YYYY: year,
        Month: monthNames[cleanedLocale][parseInt(month, 10) - 1],
    };

    let formattedDate = selectedFormat;
    (Object.keys(formatMap) as (keyof typeof formatMap)[]).forEach((key) => {
        formattedDate = formattedDate.replace(key, formatMap[key]);
    });

    return formattedDate;
};

/**
 * Takes in a date or date string and tries to format it to the locale respective to the language set in the localstorage.
 * Defaults to "en".
 * @param date (Date | string)
 * @returns formatted date string (e.g. 17 May 2022). If an error occurs return "-"
 */
export const getFormattedDateStringForPdf = (date: Date | string, language: "en" | "de" = "en") => {
    try {
        const lang = language || i18next.language || localStorage.getItem("language") || "en";

        const locale = () => {
            switch (lang) {
                case "de":
                    return de;
                case "en":
                default:
                    return enUS;
            }
        };

        return format(new Date(date), "d LLLL y", {
            locale: locale(),
        });
    } catch (e) {
        return "-";
    }
};

/**
 * Converts a stringified date to a date and returns the month and the year in an array
 *
 * @param {string} date Example: 2022-10-20T00:00:00Z
 * @returns [month: number, year: number]. Example: [10, 2022]
 */
export const getMonthYearFromStringifiedDate = (date: string) => {
    const newDate = parseISO(date);
    return [newDate.getMonth() + 1, newDate.getFullYear()];
};

/**
 * Return the correct date format based on the user's locale.
 *
 * @param {string} date - The input date string in the "yyyy-MM-dd" format.
 * @returns {string} - The date string formatted according to the user's locale:
 * "MM/dd/yyyy" for the US locale ("en-US").
 * "dd.MM.yyyy" for Germany ("DE"), Switzerland ("CH"), Austria ("AT"), and Sweden ("SE").
 * "dd-MM-yyyy" for Netherlands ("NL").
 * "dd/MM/yyyy" for the UK ("GB") and Australia ("AU").
 * "dd/MM/yyyy" for all other locales.
 */
export const getUserLocaleDate = (date?: string): string => {
    if (date === null || date === undefined || date === "" || !date.includes("-")) return "";
    const [year, month, day] = date.split("-");

    const locale = Intl.NumberFormat().resolvedOptions().locale;
    let countryCode;
    if (locale.includes("-")) {
        countryCode = locale.split("-")[1];
    } else {
        countryCode = locale;
    }
    countryCode = countryCode.toLowerCase();

    switch (countryCode) {
        case "us":
            return `${month}/${day}/${year}`;
        case "de":
        case "ch":
        case "at":
        case "se":
            return `${day}.${month}.${year}`;
        case "nl":
            return `${day}-${month}-${year}`;
        case "gb":
        case "au":
            return `${day}/${month}/${year}`;
        default:
            return `${day}/${month}/${year}`;
    }
};
