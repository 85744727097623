const A4PortraitPreview: React.FC<{ img: string; disabled: boolean }> = ({ img, disabled }) => (
    <svg width="100%" height="100%" viewBox="0 0 440 609" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1171 15.984H0.00119399L0.00117826 0L15.1171 15.984ZM0 15.9845H15.1159L15.1159 592.861L6.53267e-05 608.845L6.53267e-05 592.861L0 592.861L0 15.9845Z"
            fill="url(#paint0_linear_4074_1877)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1159 15.9845L15.1159 0.000516435L0 0.000499806L15.1159 15.9845ZM15.1171 0L15.1171 15.984L424.162 15.984V15.9845L424.163 15.984H424.164V15.9831L439.278 0.000499806L424.164 0.000499806V0L15.1171 0Z"
            fill="url(#paint1_linear_4074_1877)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M424.162 592.861H439.278L439.278 608.845L424.162 592.861ZM439.278 592.86H424.162L424.162 15.9838L439.278 0.000183105V15.9841L439.278 15.9838L439.278 592.86Z"
            fill="url(#paint2_linear_4074_1877)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M424.162 592.86V608.844L439.278 608.844L424.162 592.86ZM424.162 608.845V592.861L15.1158 592.861L0 608.844H15.1159L15.1158 608.845L424.162 608.845Z"
            fill="url(#paint3_linear_4074_1877)"
        />
        <image
            xlinkHref={img}
            x="0"
            y="15"
            width="100%"
            height="calc(100% - 30px)"
            style={{ filter: disabled ? "grayscale(1) contrast(0.2) brightness(1.9)" : undefined }}
        />
        <defs>
            <linearGradient
                id="paint0_linear_4074_1877"
                x1="0"
                y1="126.419"
                x2="15.1171"
                y2="126.419"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_4074_1877"
                x1="119.553"
                y1="0"
                x2="119.553"
                y2="15.9844"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_4074_1877"
                x1="439.278"
                y1="482.426"
                x2="424.162"
                y2="482.426"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_4074_1877"
                x1="319.725"
                y1="608.845"
                x2="319.725"
                y2="592.86"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
        </defs>
    </svg>
);
export default A4PortraitPreview;
