import { useState } from "react";
import i18n from "i18next";

import useUpdateBadges from "hooks/useUpdateBadges";
import useAvailableLng from "i18n/hooks/useAvailableLng";
import { useLanguage } from "i18n/hooks/useLanguage";

import { Box } from "@mui/material";

import { MuiSelect } from "components/shared/Select";

const LanguageSelector = () => {
    const usedLang = useLanguage();
    const languagesList = useAvailableLng();
    const { triggerUpdateBadges } = useUpdateBadges();

    const [selectedLanguage, setSelectedLanguage] = useState(usedLang);

    const changeLanguageHandler = async (newLang: string) => {
        const prevLang = selectedLanguage;
        await i18n.changeLanguage(newLang, () => {
            localStorage.setItem("i18nextLng", newLang);
            setSelectedLanguage(newLang);
            if (prevLang === "de" || newLang === "de") {
                triggerUpdateBadges();
            }
        });
    };

    return (
        <Box sx={{ maxWidth: 250 }}>
            <MuiSelect
                options={languagesList}
                name="language-selector-select"
                value={selectedLanguage}
                onChange={(event) => changeLanguageHandler(event.target.value as string)}
                sx={{ width: { xs: "100%", sm: "170px" } }}
            />
        </Box>
    );
};

export default LanguageSelector;
