const Twitter = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 174 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9635_1124)">
                <path
                    d="M136.418 0.0946655H162.922L104.728 63.6662L172.717 149.902H119.363L77.5894 97.4973L29.7662 149.902H3.26179L64.9134 81.9084L-0.195312 0.0946655H54.4845L92.2245 47.9668L136.418 0.0946655ZM127.141 134.977H141.834L46.7636 14.4674H30.9762L127.141 134.977Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_9635_1124">
                    <rect x="0.4375" y="0.0946655" width="172.855" height="149.808" rx="8" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Twitter;
