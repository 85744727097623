const UsLetterLandscapePreview: React.FC<{ img: string; disabled: boolean }> = ({ img, disabled }) => (
    <svg width="100%" height="100%" viewBox="0 0 648 509" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9949 491.483V491.485L630.355 491.485L647.349 508.999H630.354L630.355 509L16.9948 509V508.998L0 508.998L16.9949 491.483Z"
            fill="url(#paint0_linear_4076_3223)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9949 491.485H1.7681e-05L0 509L16.9949 491.485ZM9.13335e-05 491.483H16.995L16.995 17.5134H16.9932L0 0L0 17.5151H9.13335e-05L9.13335e-05 491.483Z"
            fill="url(#paint1_linear_4076_3223)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M630.355 17.5159V0.000821872L647.35 0.000803649L630.355 17.5159ZM630.354 0V17.5151L16.9949 17.5151V17.5161L16.9935 17.5151L0 0.000998199L16.9935 0.000998199V0L630.354 0Z"
            fill="url(#paint2_linear_4076_3223)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M630.354 17.5151L647.348 17.5151L647.348 0L630.354 17.5151ZM647.35 17.5159L630.355 17.5159L630.355 491.485H630.354L630.355 491.486L647.348 509V491.486H647.35L647.35 17.5159Z"
            fill="url(#paint3_linear_4076_3223)"
        />
        <image
            xlinkHref={img}
            x="0"
            y="15"
            width="100%"
            height="calc(100% - 30px)"
            style={{ filter: disabled ? "grayscale(1) contrast(0.2) brightness(1.9)" : undefined }}
        />
        <defs>
            <linearGradient
                id="paint0_linear_4076_3223"
                x1="134.414"
                y1="509"
                x2="134.414"
                y2="491.483"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_4076_3223"
                x1="0"
                y1="370.471"
                x2="16.995"
                y2="370.471"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_4076_3223"
                x1="512.936"
                y1="0"
                x2="512.936"
                y2="17.5161"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_4076_3223"
                x1="647.35"
                y1="138.529"
                x2="630.354"
                y2="138.529"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
        </defs>
    </svg>
);
export default UsLetterLandscapePreview;
