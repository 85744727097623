import React from "react";

import { IconButton as MuiIconButton } from "@mui/material";

export default function IconButton({ onClick, children, id, ...rest }: IIconButtonProps) {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!onClick) return;
        onClick(e);
        document.getElementById(id)?.blur();
    };
    return (
        <MuiIconButton onClick={handleClick} id={id} {...rest}>
            {children}
        </MuiIconButton>
    );
}
