import type { FC } from "react";
import styled from "@emotion/styled";

import { Typography } from "@mui/material";

import StyledInfoBox from "../../../components/shared/InfoBox/StyledInfoBox";
import LinearProgressWithLabel from "../../../components/shared/LinearProgressWithLabel/LinearProgressWithLabel";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { staticChunkingAmount } from "../../../redux/actions/badge";
import controlSelectorService from "../../../redux/selector/control";
import eventSelectorService from "../../../redux/selector/events";
import { useAppSelector } from "../../../redux/store";
import { EVariantTranslation } from "../../../types/enums";
import { getTranslatedCertificateOrBadge } from "../../../utils/misc";

const StyledCertificateLoading = styled.div(() => ({
    width: "100%",
    overflow: "hidden",
    marginTop: "15px",
}));

const CertificateOverviewLoading: FC = () => {
    const t = useTranslation();
    const { certificatesTotal, certificatesFinished, loopEnd, loopStart } = useAppSelector(
        controlSelectorService.selectNewCertificatesLoadingState
    );
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);

    const calculateEstimation = (start: number, end: number) => {
        const loopTime = end - start;
        const inSeconds = loopTime / 1000 / staticChunkingAmount;
        if (inSeconds < 0) return t("certificate_overview.loading_state.take_a_few_minutes"); // This can take a few minuts.
        const restCertificates = certificatesTotal - certificatesFinished;
        const estimation = Math.round((inSeconds * restCertificates) / 60);
        return t(`certificate_overview.loading_state.around_${estimation > 1 ? "minutes" : "minute"}`, {
            estimation: estimation > 1 ? estimation : 1,
        }); // Estimatied time: lesser than {{estimation}} minute. - Estimated time: about {{estimation}} minutes.
    };

    const loadingText = t("certificate_overview.loading_state.loading_text", {
        certificatesFinished,
        certificatesTotal,
        calculationEstimation: calculateEstimation(loopStart, loopEnd),
    }); // `{{certificatesFinished}} of {{certificatesTotal}} new certificates finished. {{calculationEstimation}}`;

    return (
        <StyledCertificateLoading>
            <StyledInfoBox variant="yellow" style={{ marginBottom: "37px" }}>
                <Typography>
                    {
                        // We are creating your new certificates. Please do not close this browser tab.
                        t("certificate_overview.loading_state.static_loading_text", {
                            certificateType: getTranslatedCertificateOrBadge(
                                eventDetails?.event_type,
                                EVariantTranslation.LOWERCASE_PLURAL
                            ),
                        } as any)
                    }
                </Typography>
                <Typography>{loadingText}</Typography>
            </StyledInfoBox>
            <LinearProgressWithLabel value={(100 / certificatesTotal) * certificatesFinished} />
        </StyledCertificateLoading>
    );
};

export default CertificateOverviewLoading;
