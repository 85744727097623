import { socialMediaColors, socialMediaHoverColors } from "utils/Theme/theme";

export const certificateImage = {
    width: "40%",
    marginLeft: "30%",
    borderRadius: 3,
    boxShadow: "rgba(0, 0, 0, 0.25) 2px 2px 16px 4px",
};

export const shareButton = {
    padding: "5px 16px",
    backgroundColor: socialMediaColors.twitter,
    ":hover": {
        backgroundColor: socialMediaHoverColors.twitter,
    },
};
