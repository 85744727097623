import { FC } from "react";
import { Link } from "react-router-dom";

import { Box, Skeleton } from "@mui/material";

import { TNotFoundAdditionalOptions } from "pages/NotFound/notFound.types";

import * as navBarStyles from "../../../../../components/NavBar/NavBar.styles";

const HandleBrandLogo: FC<{ branding: Partial<Record<TNotFoundAdditionalOptions, string>> }> = ({ branding }) =>
    !branding.logo ? (
        <Skeleton variant="rectangular" width={200} height={50} />
    ) : (
        <Link to={branding.webpage || window.location.href} target="_blank" rel="noopener noreferrer">
            <Box component="img" src={branding.logo} alt="logo" sx={navBarStyles.logoStyles} />
        </Link>
    );

export default HandleBrandLogo;
