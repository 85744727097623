import { useCallback } from "react";
import badgeActionService from "redux/actions/badge";
import { useGetOrgaSlugs } from "redux/hooks/validation";
import certificatesSelectorService from "redux/selector/certificates";
import { useAppDispatch, useAppSelector } from "redux/store";

/**
 * Triggers the update of the polotno generated certificate and badges when `triggerUpdateBadges` is called.
 *
 * It works only for single certificates.
 *
 * For example, in the `LanguageSelector` component, the `triggerUpdateBadges` is called when the language is changed.
 */
const useUpdateBadges = () => {
    const dispatch = useAppDispatch();

    const { data } = useAppSelector(certificatesSelectorService.selectCertificates);
    const isSingleCertificate = useAppSelector(certificatesSelectorService.isSingleCertificate);
    const isPolotnoProps = useAppSelector(certificatesSelectorService.isPolotnoProps);
    const slugs = useGetOrgaSlugs();

    const triggerUpdateBadges = useCallback(() => {
        if (isPolotnoProps && isSingleCertificate) {
            dispatch(
                badgeActionService.attachFieldMappingToBadge({
                    slugs,
                    data,
                    forceBaking: true,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, slugs]);

    return { triggerUpdateBadges };
};

export default useUpdateBadges;
