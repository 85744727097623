/**
 * Removes all access tokens from local storage that are not used by any active user.
 * @argument {"linkedin" | "facebook" | "email" | "all"} type Access token to remove. type "all" is used to remove all access tokens
 */
const cleanupLocalStorageFromUnusedAccessTokens = (type: "linkedin" | "facebook" | "email" | "all") => {
    if (type !== "linkedin") localStorage.removeItem("linkedin_access_token");
    if (type !== "facebook") localStorage.removeItem("facebook_access_token");
    if (type !== "email") localStorage.removeItem("email_access_token");
    if (type === "all") {
        localStorage.removeItem("linkedin_access_token");
        localStorage.removeItem("facebook_access_token");
        localStorage.removeItem("email_access_token");
    }
};

export default cleanupLocalStorageFromUnusedAccessTokens;
