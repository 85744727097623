import { FC } from "react";

import useTranslation from "i18n/hooks/useTranslation";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, ButtonProps } from "@mui/material";

type TOmittedButtonProps = Omit<ButtonProps, "onClick">;

type Props = TOmittedButtonProps & {
    onClick: () => void;
    customLabel?: string;
};

const ContinueButton: FC<Props> = ({ onClick, customLabel, ...props }) => {
    const t = useTranslation();
    return (
        <Button
            {...props}
            variant="contained"
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
            endIcon={<ArrowForwardIcon />}
        >
            {customLabel || t("common.continue")}
        </Button>
    );
};

export default ContinueButton;
