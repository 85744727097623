import { ReactElement } from "react";

import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";

import Helper from "components/shared/Helper";

export default function SelectField(props: ISelectProps): ReactElement {
    const { options, name, value, onChange, disabled, label, children, touched, errorText, ...rest } = props;
    return (
        <>
            <Select
                id={name}
                value={value}
                onChange={onChange}
                fullWidth
                variant="outlined"
                size="small"
                disabled={disabled || false}
                label={label}
                {...rest}
            >
                {children ||
                    options?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
            </Select>
            {touched && errorText && errorText.length > 1 ? <Helper variant="warning">{errorText}</Helper> : null}
        </>
    );
}
