import { type FC, useState } from "react";

import useTranslation from "i18n/hooks/useTranslation";
import { socialMediaColors } from "utils/Theme/theme";

import { Grid, InputAdornment, Stack } from "@mui/material";

import Button from "components/shared/Button";
import TextField from "components/shared/TextField/TextField";

import controlSelectorService from "../../../../redux/selector/control";
import { useAppSelector } from "../../../../redux/store";

import * as styles from "./EmailVerification.styles";

const EmailVerification: FC<IEmailVerificationProps> = ({
    onAuthWithSocialMedia,
    onVerifyCode,
    onEmailSent,
    isSocialMediaEnabled,
    isSingleCertificate,
    disabled,
    disableResend,
    ...props
}) => {
    const t = useTranslation();
    const [emailCode, setEmailCode] = useState("");

    const { otpTokenSent } = useAppSelector(controlSelectorService.selectUserFlow);

    return (
        <>
            <Grid container gap={2}>
                <Grid item>
                    <div
                        title={
                            disableResend
                                ? t("certificate.authForm.form.email_verification.resend_in_one_minute")
                                : undefined
                        }
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                ...styles.emailButton,
                                ...styles.linkToSocialMediaButton,
                                ...styles.resendCodeButton,
                                backgroundColor: otpTokenSent ? "inherit" : socialMediaColors.email,
                            }}
                            onClick={onEmailSent}
                            disabled={disabled || disableResend}
                            {...props}
                        >
                            {otpTokenSent
                                ? t("certificate.authForm.form.email_verification.resend")
                                : t("certificate.authForm.form.email_verification.get_verification_code")}
                        </Button>
                    </div>
                </Grid>
                <Grid item>
                    <Stack direction="row" sx={styles.textFieldInput} width="100% !important">
                        <TextField
                            fullWidth
                            value={emailCode}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmailCode(event?.target.value)}
                            placeholder={t("certificate.authForm.form.email_verification.enter_code_text")}
                            sx={styles.emailCodeVerify}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" sx={styles.emailCodeInput}>
                                        <Button
                                            variant="contained"
                                            onClick={() => onVerifyCode(emailCode)}
                                            sx={styles.codeSubmitButton}
                                            disabled={emailCode === ""}
                                        >
                                            {t("certificate.authForm.form.email_verification.verify_button.verify")}
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                            disabled={disabled}
                        />
                    </Stack>
                </Grid>
            </Grid>
            {isSocialMediaEnabled && isSingleCertificate && (
                <Button
                    variant="text"
                    sx={styles.linkToSocialMediaButton}
                    onClick={onAuthWithSocialMedia}
                    disabled={disabled}
                >
                    {t("certificate.authForm.form.verify_with_social_network")}
                </Button>
            )}
        </>
    );
};

export default EmailVerification;
