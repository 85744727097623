import { useCallback, useEffect, useState } from "react";

import { CertificateValidatorApiRoutes } from "../../features/certificateValidator/api/routes";
import eventActionService from "../actions/events";
import eventSelectorService from "../selector/events";
import { useAppDispatch, useAppSelector } from "../store";

import { useGetOrgaSlugs } from "./validation";

/**
 * Hook to get an event.
 * If the event is not initial fetched yet, it will be fetched.

 * @param {Object} CertificateValidatorApiRoutes - An object containing the API routes for the certificate validator.
 * @param {Function} useBrand - A hook to get the brand information.
 * @param {Function} useAppDispatch - A hook to dispatch actions to the store.
 * @param {Function} useAppSelector - A hook to get the state from the store.
 * @param {boolean} eventIsNotInitialFetched - A selector to determine if the event has been fetched before.
 * @param {{ orga_slug: string; certificate_slug: string }} useGetOrgaSlugs - A hook to get the organization and certificate slugs.
 * @returns {Function} A function that dispatches the action to get the event.
 */
export const useGetEvent = () => {
    const dispatch = useAppDispatch();
    const eventIsNotInitialFetched = useAppSelector(eventSelectorService.eventNotInitialFetched);
    const { orga_slug, certificate_slug } = useGetOrgaSlugs();

    const [doEventFetchOnMount, setDoEventFetchOnMount] = useState(true);

    const dispatchGetEvent = useCallback(
        () =>
            dispatch(eventActionService.getEvent(CertificateValidatorApiRoutes.getEvent(orga_slug, certificate_slug))),
        [certificate_slug, dispatch, orga_slug]
    );

    useEffect(() => {
        if (doEventFetchOnMount && eventIsNotInitialFetched) dispatchGetEvent();
        setDoEventFetchOnMount(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (eventIsNotInitialFetched) {
            dispatchGetEvent();
        }
    }, [dispatchGetEvent, eventIsNotInitialFetched]);

    return (callback: () => void) => callback();
};
