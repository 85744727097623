import type { IApplicationInsights } from "@microsoft/applicationinsights-web";
// reference: https://github.com/microsoft/ApplicationInsights-JS#readme

/**
 * track an exception to Application Insights.
 *   Reference: https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackexception
 *
 * @param error the Error
 * @param properties custom key/value properties to display with the error message
 * @param flush pass true to send all data in the buffer immediately
 */
export const trackException = (
    error: Error,
    properties?: { [key: string]: string | Record<string, string> | undefined },
    flush = false
) => {
    if (process.env.ENVIRONMENT !== "local") {
        // Add the frontend as custom property and add the [RV] prefix to the error.message
        const customProperties = { frontend: "recipient-view" };
        const appInsights: IApplicationInsights | undefined = window?.appInsights;
        if (!appInsights) return;
        if (appInsights) {
            appInsights.trackException({
                exception: { ...error, message: `[RV] ${error.message}` },
                properties: properties ? { ...properties, ...customProperties } : customProperties,
                severityLevel: 3,
            });
            // Flush() on TelemetryClient pushes all the data it currently has in a buffer to the App Insights service.
            if (flush) appInsights.flush();
        }
    }
};
