import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IFacebookProfile, ILinkedinProfile } from "features/certificateValidator/types";

import usePushSnack from "hooks/SnackbarManager/usePushSnack";
import useTranslation from "i18n/hooks/useTranslation";

import validationActionService from "../../redux/actions/validation";
import controlSelectorService from "../../redux/selector/control";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { EAuthType } from "../../types/enums";

import { getFacebookAccessToken, getLinkedInAccessToken, getTwitterAccessToken } from "./ValidationWrapper.helpers";

const useGetAccessToken = () => {
    const pushSnack = usePushSnack();
    const t = useTranslation();
    const dispatch = useAppDispatch();

    const { search } = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(search);

    const reduxStoredToken = useAppSelector(controlSelectorService.selectToken);
    const socialMediaVerificationToken = localStorage.getItem("social_media_verification_token");

    // The temporary placed token from social-media have high priority.
    const token = socialMediaVerificationToken || reduxStoredToken;

    // Facebook and Linkedin specific params
    const code = query.get("code");
    const state = query.get("state");
    const error = query.get("error");

    // Twitter specific params
    const oauth_token = query.get("oauth_token");
    const oauth_verifier = query.get("oauth_verifier");

    const certificate_id = localStorage.getItem("last_viewed_certificate");

    const authError = !!error;

    // unused "_badge" is needed for correct destructure of state
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [stateType, orga_slug, event_slug, _badge, openShareModal] = state?.split(",") || [];

    const setSocialMediaProfile = (type: "facebook" | "linkedin") => (data: IFacebookProfile | ILinkedinProfile) =>
        dispatch(validationActionService.setSocialMediaProfile({ [type]: data }));

    useEffect(() => {
        // If we are from a verification via social media, set the token to redux-store and remove temporary local-storage-item
        if (socialMediaVerificationToken) {
            dispatch(validationActionService.setToken(token));
            localStorage.removeItem("social_media_verification_token");
        }
        if (!authError && code && state && orga_slug && event_slug && token && certificate_id) {
            switch (stateType) {
                case EAuthType.LINKEDIN:
                    getLinkedInAccessToken(
                        code,
                        orga_slug,
                        event_slug,
                        certificate_id,
                        token,
                        setSocialMediaProfile("linkedin"),
                        navigate,
                        pushSnack,
                        t,
                        openShareModal
                    );
                    break;
                case EAuthType.FACEBOOK:
                    getFacebookAccessToken(
                        code,
                        orga_slug,
                        event_slug,
                        certificate_id,
                        token,
                        setSocialMediaProfile("facebook"),
                        navigate,
                        pushSnack,
                        t
                    );
                    break;
                default:
                    break;
            }
        } else if (oauth_token && oauth_verifier) {
            getTwitterAccessToken(oauth_token, oauth_verifier, navigate, pushSnack, t);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { code };
};
export default useGetAccessToken;
