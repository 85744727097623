/* eslint-disable no-param-reassign */
import { cloneDeep } from "lodash";
import type { NodeType } from "polotno/model/node-model";

import i18n from "i18n";
import { formatDate } from "utils/dates.helpers";

import { createQRCode, createRegExpForText, getRecipientNameDimensionsFromCanvas } from "./Polotno.helpers";

/**
 *
 * @param {NodeType[]} elements array of elements
 * @returns {NodeType[]} dynamic fields from the elements array
 */
export const getDynamicTextElements = (elements: NodeType[]): NodeType[] =>
    (elements || []).filter(
        (element: PolotnoDesigner.PolotnoObject) => element.custom && element.custom.type === "dynamic_field"
    );

/**
 * Set dynamic fields for Polotno page
 * @param {Fieldmapping[]} fieldMapping - participant data
 * @param {PolotnoDesigner.PolotnoBadgePropsData} polotnoBadgeProps - polotno badge props
 * @param {string} validationUrl - (optional)
 * @param {string} certificateId - (optional)
 * @param {string} issueDate - (optional)
 * @param {string} expirationDate - (optional)
 *
 * @returns {PolotnoDesigner.PolotnoBadgePropsData} polotnoBadgeProps with dynamic fields
 */
export const setDynamicFields = async (
    polotnoBadgeProps: PolotnoDesigner.PolotnoBadgePropsData,
    fieldMapping: Fieldmapping[],
    validationUrl: string,
    certificateId: string,
    issueDate: string,
    expirationDate?: string
): Promise<PolotnoDesigner.PolotnoBadgePropsData> => {
    // Make a deep copy so we don't work on the reference
    const polotnoProps = cloneDeep(polotnoBadgeProps);

    const currentLanguage = i18n.language === "de" ? "de" : "en";

    const polotnoElements: PolotnoDesigner.PolotnoObject[] = polotnoProps.pages[0].children ?? [];

    const findDynamicElement = (type: PolotnoDesigner.CustomFieldType) =>
        polotnoElements.find((item: PolotnoDesigner.PolotnoObject) => item.custom && item.custom.type === type);

    // Get custom elements
    const recipientNameElement = findDynamicElement("recipient_name");
    const certificateIdElement = findDynamicElement("identification_number");
    const issueDateElement = findDynamicElement("issue_date");
    const expirationDateElement = findDynamicElement("expiration_date");
    const qrCodeElement = findDynamicElement("qr_code");

    // Get all dynamic elements from polotno page
    const dynamicElements = getDynamicTextElements(polotnoElements);

    // Get recipient name for replacing
    const recipientNameFromParticipant = fieldMapping.find((item) => item.id === "username");

    // Get values for dynamic elements from participant data except username
    const dynamicFieldsFromParticipant = fieldMapping.filter((item) => item.id !== "username");

    // Replace dynamic fields with values from participant data
    dynamicElements.forEach((el: PolotnoDesigner.PolotnoObject) => {
        const dynamicFieldFromParticipant = dynamicFieldsFromParticipant.find((item) => item.name === el.custom?.value);
        if (dynamicFieldFromParticipant) {
            const searchingText = el.custom?.value as string;
            const regex = createRegExpForText(searchingText);

            polotnoElements.forEach((item: PolotnoDesigner.PolotnoObject) => {
                if (item.type === "text" && item.text && item.text.includes(`{{${searchingText}}}`)) {
                    item.text = item.text.replace(regex, dynamicFieldFromParticipant.value);
                }
            });
        }
    });

    const setRecipientName = () => {
        const searchingName = recipientNameElement?.custom?.value as string;
        const recipientName = recipientNameFromParticipant?.value as string;
        const NameRegex = createRegExpForText(searchingName);

        polotnoElements.forEach((item: PolotnoDesigner.PolotnoObject) => {
            if (item.type === "text" && item.selectable !== false && item.text?.includes(`{{${searchingName}}}`)) {
                item.text = item.text.replace(NameRegex, recipientName);
            }
            // Change elements dimensions if static text contains only recipient name
            if (item.text === recipientName) {
                const { width, fontSize, x } = getRecipientNameDimensionsFromCanvas(
                    item as PolotnoDesigner.PolotnoObject,
                    polotnoProps.width as number
                );
                item.fontSize = fontSize;
                item.width = width;
                item.x = x;
            }
        });
    };

    const setIdNumber = () => {
        const searchingIdNumber = certificateIdElement?.custom?.value as string;
        const idNumberRegex = createRegExpForText(searchingIdNumber);
        polotnoElements.forEach((item: PolotnoDesigner.PolotnoObject) => {
            if (item.type === "text" && item.selectable !== false && item.text?.includes(`{{${searchingIdNumber}}}`)) {
                item.text = item.text.replace(idNumberRegex, certificateId);
            }
        });
    };

    const setDates = () => {
        const searchingIssueDate = issueDateElement?.custom?.value as string;
        const searchingExpirationDate = expirationDateElement?.custom?.value as string;
        const issueDateRegex = createRegExpForText(searchingIssueDate);
        const expirationDateRegex = createRegExpForText(searchingExpirationDate);

        // To make it possible to replace the date again, we have to store the original value in the custom data
        // It's necessary for cases when a user wants to change the language of the certificate several times
        const setOriginalText = (item: PolotnoDesigner.PolotnoObject, originalText: string) => {
            if (item.custom) {
                item.custom.originalValue = originalText;
            } else {
                item.custom = { originalValue: originalText } as PolotnoDesigner.DynamicFieldWithPlaceholder;
            }
        };
        const getOriginalText = (item: PolotnoDesigner.PolotnoObject) => {
            return item.custom?.originalValue;
        };

        polotnoElements.forEach((item: PolotnoDesigner.PolotnoObject) => {
            if (
                item.type === "text" &&
                item.selectable !== false &&
                item.text &&
                (item.text.includes(`{{${searchingIssueDate}}}`) ||
                    getOriginalText(item)?.includes(`{{${searchingIssueDate}}}`))
            ) {
                // Before replacing the date, we have to store the original value in the custom data
                const originalText = getOriginalText(item);
                if (!originalText) {
                    setOriginalText(item, item.text);
                } else {
                    item.text = originalText;
                }

                const dateFormatToReplace = (issueDateElement?.custom as PolotnoDesigner.DynamicFieldWithPlaceholder)
                    .placeholder;
                const issueDateToReplace = formatDate(issueDate, dateFormatToReplace as string, currentLanguage);
                item.text = item.text.replace(issueDateRegex, issueDateToReplace);
            }
            if (
                item.type === "text" &&
                item.text &&
                item.selectable !== false &&
                (item.text.includes(`{{${searchingExpirationDate}}}`) ||
                    getOriginalText(item)?.includes(`{{${searchingExpirationDate}}}`)) &&
                expirationDate
            ) {
                // Before replacing the date, we have to store the original value in the custom data
                const originalText = getOriginalText(item);
                if (!originalText) {
                    setOriginalText(item, item.text);
                } else {
                    item.text = originalText;
                }

                const dateFormatToReplace = (
                    expirationDateElement?.custom as PolotnoDesigner.DynamicFieldWithPlaceholder
                ).placeholder;
                const expirationDateToReplace = formatDate(
                    expirationDate,
                    dateFormatToReplace as string,
                    currentLanguage
                );
                item.text = item.text.replace(expirationDateRegex, expirationDateToReplace);
            }
        });
    };

    setRecipientName();
    setIdNumber();
    setDates();

    if (qrCodeElement && validationUrl) {
        const certificatePageLink = validationUrl;
        const { width, height } = qrCodeElement;
        qrCodeElement.src = createQRCode(certificatePageLink, width, height);
    }

    return polotnoProps as PolotnoDesigner.PolotnoBadgePropsData;
};
