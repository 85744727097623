import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { addDays } from "date-fns";

import { trackException } from "utils/azureApplicationInsights";

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

/**
 * Handles accepting tracking cookies
 */
export function acceptCookiesAction() {
    const expiry = addDays(new Date(), 30);
    document.cookie = `cookieconsent=functional; expires=${expiry}; path=/`;

    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.APPLICATIONINSIGHTS_INSTRUMENTATION_KEY,
            /* ...Other Configuration Options... */
        },
    });
    window.appInsights = appInsights;
    if (process.env.ENVIRONMENT !== "local") {
        appInsights.loadAppInsights();
        appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

        // Capture global JavaScript errors.
        window.addEventListener("error", (event) => {
            trackException(new Error(event.message), {
                source: event.filename,
                stack: event.error?.stack || null,
            });
        });

        // Capture unhandled promise rejections.
        window.addEventListener("unhandledrejection", (event) => {
            trackException(new Error(event.reason), {
                stack: event.reason?.stack || null,
            });
        });
    }
}

/**
 * Handles the rejection of tracking cookies (Essential only)
 */
export function declineCookieAction() {
    const expiry = addDays(new Date(), 30);
    document.cookie = `cookieconsent=essential; expires=${expiry}; path=/`;
}
