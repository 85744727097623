import type { FC } from "react";
import styled from "@emotion/styled";
import { cloneDeep } from "lodash";

import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { styled as mui_styled } from "@mui/material/styles";

import ReloadIcon from "../../../components/ReloadIcon/ReloadIcon";
import Button from "../../../components/shared/Button";
import useTranslation from "../../../i18n/hooks/useTranslation";
import certificatesActionService from "../../../redux/actions/certificates";
import useLoadingThunk from "../../../redux/hooks/loading";
import { useGetCertificateDetails, useGetCertificateProps } from "../../../redux/hooks/queryHooks";
import certificatesSelectorService from "../../../redux/selector/certificates";
import controlSelectorService from "../../../redux/selector/control";
import eventSelectorService from "../../../redux/selector/events";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { EVariantTranslation } from "../../../types/enums";
import backendPdfDownloadHelper from "../../../utils/backendPdfDownloadHelper";
import { getTranslatedCertificateOrBadge } from "../../../utils/misc";
import { theme } from "../../../utils/Theme/theme";
import { useCertificateDownload } from "../CertificateDownload.hooks";

import CertificateOverviewLoading from "./CertificateOverviewLoading";

const InformationContainer = mui_styled("div")(() => ({
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
    alignItems: "center",
    marginTop: "26px",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: { flexFlow: "column nowrap", alignItems: "flex-start" },
    [theme.breakpoints.up("lg")]: { flexFlow: "row nowrap" },
    [theme.breakpoints.down("md")]: {
        alignItems: "center",
    },
    "&.with-download-buttons": {
        marginBottom: "35px",
    },
}));

const DownloadButtonContainer = mui_styled("div")(() => ({
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        width: "350px",
    },
    [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
    },
    [theme.breakpoints.down("lg")]: { marginTop: "38px" },
}));

const CertificateCounter = styled.p`
    color: #000;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    margin: 4.5px;
    max-width: 513px;
`;
const SubHeader = styled.p`
    color: #000;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 4.5px;
    max-width: 513px;
`;

const CertificateOverviewDownload: FC = () => {
    const dispatch = useAppDispatch();
    const t = useTranslation();
    const { handleBulkDownloadCertificate } = useCertificateDownload();
    const certificateDetails = useGetCertificateDetails();
    const certificateProps = useGetCertificateProps();
    const { getFlowLoading, isFulfilled } = useLoadingThunk();

    const isDownloading =
        getFlowLoading("download") ||
        !isFulfilled("certificates") ||
        !isFulfilled("events") ||
        !certificateDetails.isSuccess ||
        !certificateProps.isSuccess;

    const certificates = useAppSelector(certificatesSelectorService.selectCertificates);
    const downloadControl = useAppSelector(controlSelectorService.selectDownload);
    const currentDownloadFormat = useAppSelector(controlSelectorService.selectDownloadFormat);
    const isEventCertificate = useAppSelector(eventSelectorService.eventIsCertificate);
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);

    const currentNewCertificates = useAppSelector(controlSelectorService.selectNewFoundCertificates);
    const { loadingState } = useAppSelector(controlSelectorService.selectNewCertificatesLoadingState);

    const amountToDownload =
        downloadControl.amountOfDownloadingCertificates === 0
            ? t("certificate_overview.sub_header.all")
            : downloadControl.amountOfDownloadingCertificates.toString();

    const handleDownload = (type: "pdf" | "png") => {
        let downloadingCertificates = [];
        if (downloadControl.amountOfDownloadingCertificates === 0)
            downloadingCertificates = cloneDeep(certificates.data);
        else
            downloadingCertificates = cloneDeep(certificates.data).filter((cert) =>
                downloadControl.certificatesToDownload.includes(cert.certificate_id)
            );
        handleBulkDownloadCertificate(type, downloadingCertificates);
    };

    const downloadButtonText = (tString: string, fileType: string) =>
        t(tString, {
            amountToDownload,
            fileType: `${fileType}${amountToDownload !== "1" ? "s" : ""}`,
        } as any);

    const handleUnmarkAllCertificates = () => {
        dispatch(certificatesActionService.removeAllCertificateToDownload());
    };

    const badgesAreLoaded = loadingState === null;
    const buttonLoadingTitle = t(
        "certificate_overview.sub_header.button.download_all.certificates_currently_in_baking_progress",
        {
            certificateType: getTranslatedCertificateOrBadge(
                eventDetails?.event_type,
                EVariantTranslation.LOWERCASE_PLURAL
            ),
        }
    );

    // We only show the png download button if the certificate is not encrypted
    // This is the case for all badges and for certificates that are not digitally signed.
    const showPngDownload = !eventDetails.event_pdf_encryption;

    const checkAllRecipients = backendPdfDownloadHelper.checkAllRecipients(
        eventDetails.event_pdf_type,
        certificates.data
    );

    const isBackandAndMinimumOneDoNotHavePdfUrl =
        checkAllRecipients.isBackend && checkAllRecipients.minimumOneRecipientDoNotHasPdfUrl;

    const isBrowserWithoutCertificatesInProgress = checkAllRecipients.isBrowser && currentNewCertificates.length > 0;

    return (
        <>
            <InformationContainer className={badgesAreLoaded ? "with-download-buttons" : ""}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", flexWrap: "nowrap" }}>
                    <div
                        style={{
                            display: "flex",
                            flexFlow: "row",
                            alignItems: "center",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                        }}
                    >
                        <CertificateCounter>
                            {t("certificate_overview.sub_header.numbers_of_certificates", {
                                certificateType: getTranslatedCertificateOrBadge(
                                    eventDetails?.event_type,
                                    EVariantTranslation.LOWERCASE_PLURAL
                                ),
                            })}
                            :{" "}
                            {Number(amountToDownload) > 0
                                ? `${amountToDownload}/${certificates.data.length}`
                                : certificates.data.length}
                        </CertificateCounter>
                        {Number(amountToDownload) > 0 && (
                            <div
                                onClick={handleUnmarkAllCertificates}
                                role="button"
                                style={{
                                    cursor: "pointer",
                                    color: "rgb(34, 61, 113)",
                                    paddingLeft: "16px",
                                    fontWeight: "bold",
                                }}
                            >
                                {t("certificate_overview.sub_header.unmark_all", {
                                    certificateType: getTranslatedCertificateOrBadge(
                                        eventDetails?.event_type,
                                        EVariantTranslation.LOWERCASE_PLURAL
                                    ),
                                })}
                            </div>
                        )}
                    </div>
                    {badgesAreLoaded && (
                        <SubHeader>
                            {t("certificate_overview.sub_header.sub_header_text", {
                                certificateType: getTranslatedCertificateOrBadge(
                                    eventDetails?.event_type,
                                    EVariantTranslation.LOWERCASE_SINGULAR
                                ),
                                certificatesType: getTranslatedCertificateOrBadge(
                                    eventDetails?.event_type,
                                    EVariantTranslation.LOWERCASE_PLURAL
                                ),
                            })}
                        </SubHeader>
                    )}
                </div>
                {badgesAreLoaded && (
                    <DownloadButtonContainer>
                        <>
                            {isBackandAndMinimumOneDoNotHavePdfUrl && (
                                <ReloadIcon
                                    title={t("certificate_overview.sub_header.backend_pdf_in_creation.click_to_reload")}
                                />
                            )}
                            {isEventCertificate && (
                                <Button
                                    title={
                                        downloadControl.amountOfDownloadingCertificates === 0
                                            ? buttonLoadingTitle
                                            : undefined
                                    }
                                    sx={{
                                        [theme.breakpoints.down("sm")]: { margin: "0px", minWidth: "320px" },
                                        [theme.breakpoints.up("sm")]: { margin: "0px 20px", minWidth: "205px" },
                                    }}
                                    variant="contained"
                                    onClick={() => handleDownload("pdf")}
                                    startIcon={
                                        isDownloading && currentDownloadFormat === "pdf" ? (
                                            <CircularProgress size={16} />
                                        ) : (
                                            <DownloadForOfflineOutlinedIcon />
                                        )
                                    }
                                    disabled={isDownloading || isBrowserWithoutCertificatesInProgress}
                                >
                                    {downloadButtonText("certificate_download.sub_header.download_pdf", "PDF")}
                                </Button>
                            )}
                            {showPngDownload && (
                                <Button
                                    title={
                                        downloadControl.amountOfDownloadingCertificates === 0
                                            ? buttonLoadingTitle
                                            : undefined
                                    }
                                    sx={{
                                        [theme.breakpoints.down("sm")]: {
                                            margin: "0px",
                                            marginTop: "18px",
                                            minWidth: "320px",
                                        },
                                        [theme.breakpoints.up("sm")]: {
                                            margin: "0px 20px",
                                            marginRight: "4.5px",
                                            minWidth: "205px",
                                        },
                                    }}
                                    variant="contained"
                                    onClick={() => handleDownload("png")}
                                    startIcon={
                                        isDownloading && currentDownloadFormat === "png" ? (
                                            <CircularProgress size={16} />
                                        ) : (
                                            <DownloadForOfflineOutlinedIcon />
                                        )
                                    }
                                    disabled={isDownloading || isBrowserWithoutCertificatesInProgress}
                                >
                                    {downloadButtonText("certificate_download.sub_header.download_png", "PNG")}
                                </Button>
                            )}
                        </>
                    </DownloadButtonContainer>
                )}
            </InformationContainer>
            {!badgesAreLoaded && <CertificateOverviewLoading />}
        </>
    );
};

export default CertificateOverviewDownload;
