import { createTheme } from "@mui/material/styles";
import { ThemeOptions } from "@mui/material/styles/createTheme";

/**
 * Mui-Theme Documentations:
 * Theming: https://mui.com/material-ui/customization/theming/
 * Palette: https://mui.com/material-ui/customization/palette/
 * Components: https://mui.com/material-ui/customization/theme-components/
 * Spacing: https://mui.com/material-ui/customization/spacing/
 * Breakpoints: https://mui.com/material-ui/customization/breakpoints/
 * Transitions: https://mui.com/material-ui/customization/transitions/
 * typography: https://mui.com/material-ui/customization/typography/
 */

export const WHITE = "#ffffff";
export const LIGHT_WHITE = "#f3f3f3";
export const BLACK = "#000000";
export const LIGHTER_BLUE = "#FAFCFF";
export const LIGHT_BLUE = "#5797ff";
export const MAIN_BLUE = "#223D71";
export const DARK_GREY = "#DDD";
export const GREY_MAIN = "#C8C8C8";
export const LIGHT_GREY = "#E5E5E5";

// text colors
export const TEXT_PRIMARY = "#000000";
export const TEXT_SECONDARY = "#585858";
export const TEXT_DISABLED = "#747474";

// social media colors
export const socialMediaColors = {
    facebook: "#4267B2",
    linkedin: "#0288D1",
    email: "#FFD200",
    twitter: "#000000",
    instagram: "#E1306C",
    whatsapp: "#25D366",
};

// social media hover colors
export const socialMediaHoverColors = {
    facebook: "#34518d",
    linkedin: "#0272b0",
    email: "#d6b000",
    twitter: "#444444",
    instagram: "#B81A53",
    whatsapp: "#1EA04B",
};

export const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            light: "#325ba8",
            main: MAIN_BLUE,
            dark: "#1B305A",
        },
        secondary: {
            main: GREY_MAIN,
        },
        success: {
            main: "#66BB6A",
        },
        warning: {
            main: "#FFA726",
        },
        info: {
            main: "#29B6F6",
        },
        error: {
            main: "#F44336",
        },
        divider: "#E9E9E9",
        background: {
            default: WHITE,
        },
        text: {
            primary: TEXT_PRIMARY,
            secondary: TEXT_SECONDARY,
            disabled: TEXT_DISABLED,
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: "Open Sans",
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontWeight: 500,
            lineHeight: 1.2,
            fontSize: "2.5rem",
        },
        h2: {
            fontWeight: 500,
            lineHeight: 1.2,
            fontSize: "2rem",
        },
        h3: {
            fontWeight: 700,
            lineHeight: 1.2,
            fontSize: 32,
        },
        h5: {
            fontSize: 16,
            fontWeight: 700,
        },
        h6: {
            fontSize: 14,
            fontWeight: 700,
        },
        body1: {
            fontSize: 16,
            fontWeight: 400,
        },
        body2: {
            fontSize: 14,
            fontWeight: 400,
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 4,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    minHeight: "100vh",
                    minWidth: "100vw",
                    margin: 0,
                    "& #root": {
                        height: "100vh",
                        width: "100vw",
                        overflowY: "auto",
                    },
                },
                a: {
                    textDecoration: "none",
                    color: LIGHT_BLUE,
                },
            },
        },

        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: "1800px !important",
                },
            },
        },

        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: "1rem",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    backgroundColor: MAIN_BLUE,
                    padding: "8px !important",
                    alignItems: "center",
                },
                message: {
                    color: WHITE,
                    padding: "0 !important",
                },
                icon: {
                    padding: "0 !important",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: 16,
                    width: "fit-content !important",
                    boxShadow: "none",
                    "&:hover": {
                        boxShadow: "none",
                    },
                    "&:focus": {
                        boxShadow: "none",
                    },
                },
                startIcon: {
                    marginLeft: 0,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    marginLeft: "0px",
                    height: "42px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRadius: "4px",
                },
                input: {
                    fontSize: 16,
                    backgroundColor: "#FFFFFF00",
                    padding: "7px 16px 7px 12px",
                },
                notchedOutline: {
                    border: "2px solid transparent",
                    bottom: -1,
                    transition:
                        "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "&:hover": {
                        borderRadius: "4px",
                        border: "2px solid #325ba8",
                    },
                    "&:focus": {
                        borderRadius: "4px",
                        border: "2px solid #1B305A",
                    },
                    "&.Mui-disabled": {
                        border: "2px solid transparent",
                    },
                },
                multiline: {
                    padding: 0,
                    height: "auto",
                    margin: "auto",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                outlined: {
                    backgroundColor: "white",
                    padding: "0px 5px",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: GREY_MAIN,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: "100%",
                },
            },
        },
    },
};

export const theme = createTheme(themeOptions);
