import i18nInstance from "../index";

/**
 * Hook to determine the currently used language.
 *
 * This hook first checks if a language has been set in the i18n instance, then checks local storage for a set language.
 * If no language is set in either of those places, it defaults to "en".
 *
 * For languages that use a region code (such as "en-GB" or "pt-BR"), this hook returns only the first two characters
 * for all languages except Portuguese. For Portuguese, it checks if the variant is
 * "pt-BR" and returns that, or defaults to "pt" for any other variant.
 *
 * @returns {string} The currently used language code.
 */
export const useLanguage = (): string => {
    let currentLanguage =
        i18nInstance.language || (typeof window !== "undefined" && window.localStorage.i18nextLng) || "en";

    if (currentLanguage.startsWith("pt")) {
        currentLanguage = currentLanguage === "pt-BR" ? "pt-BR" : "pt";
    } else {
        currentLanguage = currentLanguage.substr(0, 2);
    }

    return currentLanguage;
};
