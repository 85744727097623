export const root = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
};
export const nav = {
    minHeight: 64,
};
export const container = {
    flex: 1,
};

export const wrapper = {
    height: "100%",
};
