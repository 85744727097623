import { CircularProgress } from "@mui/material";

const Spinner = () => {
    return (
        <div>
            <CircularProgress />
        </div>
    );
};

export default Spinner;
