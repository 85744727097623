import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidatorSubmitForm } from "features/certificateValidator/components/AuthForm/authForm.hooks";

import usePushSnack from "hooks/SnackbarManager";
import useTranslation from "i18n/hooks/useTranslation";
import {
    checkPolotnoProfilePicture,
    checkProfilePicture,
    disableProfilePicInPolotnoProps,
    findRecipientImageInBadgeProps,
    findRecipientImageInPolotnoProps,
    getTranslatedCertificateOrBadge,
} from "utils/misc";

import { Box, styled } from "@mui/material";

import AddFacebookButton from "components/AddFacebookButton";
import AddInstagramButton from "components/AddInstagramButton";
import AddLinkedInButton from "components/AddLinkedInButton";
import AddTwitterButton from "components/AddTwitterButton";
import AddWhatsappButton from "components/AddWhatsappButton";
import Typography from "components/shared/Typography";

import badgeActionService from "../../../../redux/actions/badge";
import { useGetCurrentSelectedCertificate } from "../../../../redux/hooks/badge";
import { useShareToSocialMedia } from "../../../../redux/hooks/socialMediaShare";
import certificatesSelectorService from "../../../../redux/selector/certificates";
import controlSelectorService from "../../../../redux/selector/control";
import eventSelectorService from "../../../../redux/selector/events";
import { useAppSelector } from "../../../../redux/store";
import { ECertificateShareButtons, ESocialMediaPlatforms, EVariantTranslation } from "../../../../types/enums";
import checkPropsType from "../../../../utils/checkPropsType";
import ShareInstagramModal from "../ShareInstagramModal";
import ShareLinkedinModal from "../ShareLinkedinModal";
import ShareTwitterModal from "../ShareTwitterModal";

import { useShareCertificate } from "./ShareCertificate.hooks";

// Empty div is necessary for direction, else it would break the view.
// Without this div the outer padding would not applied.
const ShareCertificateContainer = styled("div")(() => ({}));

const ShareCertificate = () => {
    const t = useTranslation();
    const pushSnack = usePushSnack();
    const [search] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useAppSelector(controlSelectorService.selectToken);
    const selectedCertificateHasProfilePictureAttached = useAppSelector(
        certificatesSelectorService.selectedCertificateHasProfilePictureAttached
    );
    const [bypassProfilePicture, setByPassProfilePicture] = useState<boolean>(
        selectedCertificateHasProfilePictureAttached || false
    );

    const { getShareLoadingState } = useShareToSocialMedia();

    const { handleAddToLinkedIn, handleShareToSocialMedia } = useShareCertificate();
    const { submitForm } = useValidatorSubmitForm();

    const [openLinkedinModal, setOpenLinkedinModal] = useState(
        search.get("share_modal")?.toLocaleLowerCase() === "linkedin"
    );
    const [openTwitterModal, setOpenTwitterModal] = useState(
        search.get("share_modal")?.toLocaleLowerCase() === "twitter"
    );

    const [openInstagramModal, setOpenInstagramModal] = useState(false);

    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);
    const certificate = useGetCurrentSelectedCertificate();

    const { event_share_buttons } = eventDetails || {};

    const handleOpenShareToLinkedinModal = useCallback(() => {
        const linkedin_access_token = localStorage.getItem("linkedin_access_token");

        if (linkedin_access_token) {
            setOpenLinkedinModal(true);
        } else {
            submitForm("linkedin", null, ["share_modal"]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenShareToTwitterModal = useCallback(() => {
        const twitter_oauth_token = localStorage.getItem("twitter_oauth_token");
        const twitter_oauth_token_secret = localStorage.getItem("twitter_oauth_token_secret");

        if (twitter_oauth_token && twitter_oauth_token_secret) {
            setOpenTwitterModal(true);
        } else {
            const path = window.location.pathname;
            localStorage.setItem("twitter_redirect_path", path);
            submitForm("twitter", null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedCertificateHasProfilePictureAttached !== bypassProfilePicture)
            setByPassProfilePicture(selectedCertificateHasProfilePictureAttached);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCertificateHasProfilePictureAttached]);

    useEffect(() => {
        navigate({ search: "" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shareWrapper = (shareType: ESocialMediaPlatforms) => {
        // Find profile picture and check if it's the dummy
        let showBypassProfilePictureAlert = false;
        if (checkPropsType(certificate?.badge_props, certificate?.badge_props_type, "badge")) {
            const profilePic = findRecipientImageInBadgeProps(certificate.badge_props as VbDesigner.IBadgeProps);
            showBypassProfilePictureAlert = checkProfilePicture(profilePic, bypassProfilePicture);
        }
        if (checkPropsType(certificate?.badge_props, certificate?.badge_props_type, "polotno")) {
            const profilePic = findRecipientImageInPolotnoProps(
                certificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData
            );
            showBypassProfilePictureAlert = checkPolotnoProfilePicture(profilePic, bypassProfilePicture);

            // Disable profile picture in Polotno props if no picture selected
            if (
                profilePic?.visible &&
                (profilePic?.type === "svg" || profilePic?.src?.includes("user_profile_dummy")) &&
                bypassProfilePicture
            ) {
                dispatch(
                    badgeActionService.attachParsedBadgePropsToCertificate({
                        ...certificate,
                        badge_props: disableProfilePicInPolotnoProps(
                            certificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData
                        ),
                    })
                );
            }
        }

        if (showBypassProfilePictureAlert) {
            pushSnack({
                title: t("common.alerts.messages.by_pass_profile_picture.title"),
                body: t("common.alerts.messages.by_pass_profile_picture.body"),
                type: "warning",
                autoHideDuration: 100000,
            });
            dispatch(badgeActionService.bypassProfilePicture(certificate.certificate_id));
            return;
        }

        // Adding the current stored token to the local storage for social media verification redirection;
        localStorage.setItem("social_media_verification_token", token);

        switch (shareType) {
            case ESocialMediaPlatforms.FACEBOOK:
                handleShareToSocialMedia(ESocialMediaPlatforms.FACEBOOK);
                break;
            case ESocialMediaPlatforms.TWITTER:
                handleOpenShareToTwitterModal();
                break;
            case ESocialMediaPlatforms.LINKEDIN:
                handleOpenShareToLinkedinModal();
                break;
            case ESocialMediaPlatforms.WHATSAPP:
                handleShareToSocialMedia(ESocialMediaPlatforms.WHATSAPP);
                break;
            case ESocialMediaPlatforms.INSTAGRAM:
                setOpenInstagramModal(true);
                break;
            default:
                break;
        }
    };

    return (
        <ShareCertificateContainer>
            {eventDetails?.event_social_media_mode_enabled &&
                event_share_buttons &&
                event_share_buttons.filter((item) => item !== "linkedin-certificate").length > 0 && (
                    <>
                        <Typography variant="h5" sx={{ mb: 1 }}>
                            {t("certificate_download.share_certificate.share_certificate", {
                                certificateType: getTranslatedCertificateOrBadge(
                                    eventDetails?.event_type,
                                    EVariantTranslation.LOWERCASE_SINGULAR
                                ),
                            } as any)}
                        </Typography>
                        <Box display="flex" gap={2}>
                            {event_share_buttons.includes(ECertificateShareButtons.LinkedinShare) && (
                                <AddLinkedInButton
                                    showLabel={false}
                                    onClick={() => shareWrapper(ESocialMediaPlatforms.LINKEDIN)}
                                    disabled={getShareLoadingState(ESocialMediaPlatforms.LINKEDIN).pending}
                                />
                            )}
                            {event_share_buttons.includes(ECertificateShareButtons.FacebookShare) && (
                                <AddFacebookButton
                                    showLabel={false}
                                    disabled={getShareLoadingState(ESocialMediaPlatforms.FACEBOOK).pending}
                                    onClick={() => shareWrapper(ESocialMediaPlatforms.FACEBOOK)}
                                />
                            )}
                            {event_share_buttons.includes(ECertificateShareButtons.TwitterShare) && (
                                <AddTwitterButton
                                    showLabel={false}
                                    disabled={getShareLoadingState(ESocialMediaPlatforms.TWITTER).pending}
                                    onClick={() => shareWrapper(ESocialMediaPlatforms.TWITTER)}
                                />
                            )}
                            {event_share_buttons.includes(ECertificateShareButtons.WhatsappShare) && (
                                <AddWhatsappButton
                                    disabled={getShareLoadingState(ESocialMediaPlatforms.WHATSAPP).pending}
                                    onClick={() => shareWrapper(ESocialMediaPlatforms.WHATSAPP)}
                                />
                            )}
                            {event_share_buttons.includes(ECertificateShareButtons.InstagramShare) && (
                                <AddInstagramButton
                                    disabled={getShareLoadingState(ESocialMediaPlatforms.INSTAGRAM).pending}
                                    onClick={() => shareWrapper(ESocialMediaPlatforms.INSTAGRAM)}
                                />
                            )}
                        </Box>
                    </>
                )}
            {eventDetails?.event_share_buttons?.includes("linkedin-certificate") && (
                <>
                    <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
                        {t("certificate_download.share_certificate.add_on_linkedin", {
                            certificateType: getTranslatedCertificateOrBadge(
                                eventDetails?.event_type,
                                EVariantTranslation.LOWERCASE_SINGULAR
                            ),
                        } as any)}
                    </Typography>
                    <Box maxWidth={260}>
                        <AddLinkedInButton
                            label={t("certificate_download.share_certificate.add_to_linkedIn")}
                            onClick={handleAddToLinkedIn}
                        />
                    </Box>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 0 }}>
                        {t("certificate_download.share_certificate.add_on_linkedin_description", {
                            certificateType: getTranslatedCertificateOrBadge(
                                eventDetails?.event_type,
                                EVariantTranslation.UPPERCAE_PLURAL
                            ),
                        } as any)}
                    </Typography>
                </>
            )}
            {openLinkedinModal && (
                <ShareLinkedinModal
                    dialogOpen={openLinkedinModal}
                    handleDialogClose={() => setOpenLinkedinModal(false)}
                />
            )}
            {openTwitterModal && (
                <ShareTwitterModal dialogOpen={openTwitterModal} handleDialogClose={() => setOpenTwitterModal(false)} />
            )}
            {openInstagramModal && (
                <ShareInstagramModal
                    dialogOpen={openInstagramModal}
                    handleDialogClose={() => setOpenInstagramModal(false)}
                />
            )}
        </ShareCertificateContainer>
    );
};

export default ShareCertificate;
